<swiper #swiper slidesPerView="auto" [spaceBetween]="spaceBetween" [navigation]="true" [pagination]="false"
  [loop]="false" (activeIndexChange)="onSwiperActiveIndexChange()">
  <ng-container *ngFor="let video of videoUrls; let idx = index; trackBy: trackByFn">
    <ng-template swiperSlide>
      <div class="carousel-item cursor-pointer" (click)="!isDragging && openModal(video)">
        <div class="story">
          <!-- Camada externa com o gradiente -->
          <div class="gradient-border">
            <!-- Contêiner interno com a borda branca -->
            <div class="white-border">
              <video [poster]="video.thumbnail" class="item-video">
                <source [src]="video.src" type="video/mp4">
                Seu navegador não suporta a tag de vídeo.
              </video>
            </div>
            
          </div>
          <div class="py-1 px-2 text-base card border-none text-center shadow-none font-semibold">{{video.title}}</div>

        </div>
      </div>
    </ng-template>
  </ng-container>
</swiper>




<!-- Modal Carousel -->
<app-video-modal [videoUrls]="selectedVideoUrls" [widget_id]="widget_id" [show]="isModalOpen" [height]="height"
  [arraySlicksFull]="arraySlicksFull" [selectedVideo]="selectedVideoUrls[0]" [totalAssets]="totalAssets"
  (close)="isModalOpen = false"></app-video-modal>