<div class="s-s-l-zIndexVideo" [ngClass]="{ 'hidden-player': !isPlayerVisible }">
  <div class="modal-header">
    <button class="close-btn-header m-0 border-none ss-g-custom-bg ss-g-btns-menu-sup ss-g-btns-menu-right"
      (click)="closeModal()">X</button>
  </div>
  <div class="modal-content">
    <div [style.height.px]="height">
      <!-- Contêiner para o componente dinâmico -->
      <ng-template #videoPlayerContainer></ng-template>
    </div>
  </div>
</div>