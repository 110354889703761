import { Component, OnInit, OnDestroy, Inject, LOCALE_ID } from '@angular/core';
import { MenuItem, ConfirmationService, MessageService } from 'primeng/api';
import { WidgetsService } from 'src/app/services/widgets.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { formatDate } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
export interface Widget {
  id: number;
  uid: string;
  type: number;
  name: string;
  smart: number | null;
  store_id: number;
  tags: string;
  title: string;
  limit: number | null;
  assets_count: number | null;
  template: string | null;
  url_display: string | null;
  created_at: string;
  updated_at: string;
  feed_type: string;
}
@Component({
  selector: 'app-widgets',
  templateUrl: './widgets.component.html',
  styleUrls: ['./widgets.component.scss']
})
export class WidgetsComponent implements OnInit, OnDestroy {

  displaySidebar: boolean = false;
  loading: boolean = false;
  totalRecords: number = 0;
  widgets: Widget[] = [];
  allWidgets: Widget[] = [];
  itemsButtons: MenuItem[] = [];
  searchTerm: string = '';
  selectedWidget: any;
  private destroy$ = new Subject<void>();
  displayAguarde: boolean = false;
  totalWidgets: number = 0;
  constructor(
    private widgetService: WidgetsService,
    @Inject(LOCALE_ID) private locale: string,
    private messageService: MessageService,
    private router: Router,
    private confirmationService: ConfirmationService
  ) {

  }
  filteredWidgets: Widget[] = [];
  feedTypeOptions = [
    { label: 'Manual', value: 'manual' },
    { label: 'Tags', value: 'tags' }
  ];
  selectedFeedType: string | null = null; 
  loadWidgets(): void {
    // Exemplo: Simulação de dados para `widgets`
    this.widgets = [
      { id: 1, uid: '1', type: 1, name: 'Widget 1', smart: null, store_id: 1, tags: '', title: 'Widget 1', limit: null, assets_count: null, template: null, url_display: null, created_at: '2023-11-13', updated_at: '2023-11-13', feed_type: 'manual' },
      { id: 2, uid: '2', type: 1, name: 'Widget 2', smart: null, store_id: 1, tags: '', title: 'Widget 2', limit: null, assets_count: null, template: null, url_display: null, created_at: '2023-11-13', updated_at: '2023-11-13', feed_type: 'tags' }
    ];
    // Inicializa o array filtrado com todos os dados
    this.filteredWidgets = [...this.widgets];
  }


  applyFeedTypeFilter(): void {
    if (this.selectedFeedType) {
      this.filteredWidgets = this.widgets.filter(widget => widget.feed_type === this.selectedFeedType);
    } else {
      // Se nenhum filtro estiver selecionado, mostre todos os widgets
      this.filteredWidgets = [...this.widgets];
    }
  }
  selectedDate: Date | null = null;
  clearFeedTypeFilter(): void {
    this.selectedFeedType = null;
    this.applyFeedTypeFilter();
  }
  selectedDateRange: Date[] | null = null;
  clearDateFilter(): void {
    this.selectedDateRange = null;
    this.filteredWidgets = [...this.widgets];
  }
  onDateFilterChange(dateRange: Date[]): void {
    this.selectedDateRange = dateRange;

    if (!this.selectedDateRange || this.selectedDateRange.length !== 2) {
        // Se o intervalo não estiver completo, não aplique o filtro
        this.filteredWidgets = [...this.widgets];
        return;
    }

    const [startDate, endDate] = this.selectedDateRange;

    // Ajuste o `endDate` para incluir o final do dia
    const adjustedEndDate = new Date(endDate);
    adjustedEndDate.setHours(23, 59, 59, 999);  // Define o final do dia

    // Aplique o filtro para incluir widgets cuja data `created_at` esteja dentro do intervalo
    this.filteredWidgets = this.widgets.filter(widget => {
        const widgetDate = new Date(widget.created_at);
        return widgetDate >= startDate && widgetDate <= adjustedEndDate;
    });
}

  ngOnInit(): void {
    this.loadWidgets();
    //this.displayAguarde = true;
    this.loading = true;
    this.widgetService.getWidgets().pipe(takeUntil(this.destroy$)).subscribe({
      next: (res: Widget[]) => {
        this.allWidgets = res;
        this.widgets = res;
        this.filteredWidgets = [...this.widgets];
        this.totalRecords = res.length; // Atualiza o total de registros
        this.loading = false;

        // Calcula a soma de todos os assets_count de cada widget
        this.totalWidgets = res.reduce((acc, widget) => acc + (widget.assets_count || 0), 0);

      },
      error: (err) => {
        this.loading = false;
      }
    });
  }
  getActionMenu(widget: any) {
    return [
      //{ label: 'Ver widget', icon: 'pi pi-eye', command: () => this.editWidgets(widget.uid) },
      { label: 'Editar', icon: 'pi pi-file-edit', command: () => this.editWidgets(widget.uid) },
      { label: 'Embed HTML', icon: 'pi pi-file-edit', command: () => this.editWidgets(widget.uid, true) },
      { label: 'Deletar', icon: 'pi pi-trash', command: () => this.deleteSlick(widget.id) },
    ];
  }
  editWidgets(widget_id: any, selectEmbedTab: boolean = false) {
    // Navegue para a rota com um parâmetro adicional indicando para selecionar a aba "Embedding"
    this.router.navigate(['/edit_widgets', widget_id], { queryParams: { embed: selectEmbedTab } });
  }
  deleteSlick(widget_id: any) {
    this.confirmationService.confirm({
      message: 'Você realmente deseja remover este widget?',
      header: 'Confirmação de Exclusão',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.displayAguarde = true;
        this.confirmDeletion(widget_id);
      },
      reject: () => {
      }
    });
  }
  confirmDeletion(widget_id: any) {
    console.log('deletar', widget_id);
    this.widgetService.deleteWidgets(widget_id).pipe(takeUntil(this.destroy$)).subscribe({
      next: (res: Widget[]) => {
        this.widgets = this.widgets.filter(widget => widget.id !== widget_id);
        this.filteredWidgets = this.filteredWidgets.filter(widget => widget.id !== widget_id);
        this.totalRecords = this.widgets.length; // Atualiza o total de registros
        this.displayAguarde = false;
        this.messageService.add({
          severity: 'success',
          summary: 'Widget Removido',
          detail: 'O Widget foi removido com sucesso!'
        });
      },
      error: (error) => {
        let message = '';
        if (error.status === 400 || error.status === 403 || error.status === 401 || error.status === 404) {
          message = error.error.message;
        } else if (error.status === 500) {
          message = "Ops... Algo de errado aconteceu. Tente novamente mais tarde.";
        }

        if (message) {
          this.showErrorDialog(message);
        }
      }
    });
  }
  showErrorDialog(message: string) {
    this.messageService.add({
      severity: 'error',
      summary: 'Erro',
      detail: message,
      icon: 'pi pi-times'
    });
  }
  onFilterSlicks(event: any) {
    const searchValue = event.target.value.toLowerCase();

    // Filtrar widgets com base no nome ou na data formatada
    this.widgets = this.allWidgets.filter((widget: Widget) => {
      // Filtrar por name (caso não seja nulo ou indefinido)
      const matchesName = widget.name ? widget.name.toLowerCase().includes(searchValue) : false;

      // Filtrar por created_at
      const formattedDate = this.formatDate(widget.created_at);
      const matchesDate = formattedDate.includes(searchValue);

      // Retorna true se alguma das condições acima for satisfeita
      return matchesName || matchesDate;
    });

    this.totalRecords = this.widgets.length; // Atualizar a contagem de registros filtrados
  }
  formatDate(dateString: string): string {
    if (!dateString) return '';
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('pt-BR').format(date);
  }
  openSidebar() {
    //
    this.router.navigate(['/create_widget']);
    //this.displaySidebar = true;
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
