//import { NotfoundComponent } from 'src/app/demo/components/notfound/notfound.component';
import { NgModule, isDevMode, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppLayoutModule } from './layout/app.layout.module';

import { IgxBottomNavModule, IgxIconModule } from 'igniteui-angular';
import { CommonModule } from '@angular/common';
import { FocusService } from './services/focus.service';
import { VendasComponent } from './components/vendas/vendas.component';
import { LivesComponent } from './components/lives/lives.component';
import { ShortsComponent } from './components/shorts/shorts.component';
import { WidgetsComponent } from './components/widgets/widgets.component';
import { InventarioComponent } from './components/inventario/inventario.component';
import { LeadsComponent } from './components/leads/leads.component';
import { ConfigurationsComponent } from './components/configurations/configurations.component';
import { IntegrationsComponent } from './components/integrations/integrations.component';
import { HelpComponent } from './components/help/help.component';
import { ContactComponent } from './components/contact/contact.component';
import { ProductComponent } from './components/product/product.component';
import { CollectionComponent } from './components/collection/collection.component';
import { CategoryComponent } from './components/category/category.component';
import { PromotionComponent } from './components/promotion/promotion.component';
import { StoreComponent } from './components/store/store.component';
import { PlayerComponent } from './components/player/player.component';
import { TagsComponent } from './components/tags/tags.component';
import { ExtratoComponent } from './components/extrato/extrato.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { SanitizeUrlPipe } from './components/edit-widget/sanitize-url.pipe'; // ajuste o caminho conforme necessário
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { SliderModule } from 'primeng/slider';
import { ProgressBarModule } from 'primeng/progressbar';
import { MultiSelectModule } from 'primeng/multiselect'
import { CalendarModule } from 'primeng/calendar';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ButtonModule } from 'primeng/button';
import { InputMaskModule } from 'primeng/inputmask';
import { SkeletonModule } from 'primeng/skeleton';
import { MenuModule } from 'primeng/menu';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DialogModule } from 'primeng/dialog';
import { SidebarModule } from 'primeng/sidebar';
import { StepsModule } from 'primeng/steps';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TabMenuModule } from 'primeng/tabmenu';
import { FileUploadModule } from 'primeng/fileupload';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { RippleModule } from 'primeng/ripple';
import { ChipModule } from 'primeng/chip';
import { EditorModule } from 'primeng/editor';
import { DataViewModule } from 'primeng/dataview';
import { PickListModule } from 'primeng/picklist';
import { OrderListModule } from 'primeng/orderlist';
import { RatingModule } from 'primeng/rating';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ChipsModule } from 'primeng/chips';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SpeedDialModule } from 'primeng/speeddial';
import { MegaMenuModule } from 'primeng/megamenu';
import { BadgeModule } from 'primeng/badge';
import { CarouselModule } from 'primeng/carousel';
import { ColorPickerModule } from 'primeng/colorpicker';
import { AccordionModule } from 'primeng/accordion';
import { SwiperModule } from 'swiper/angular';
import { ListboxModule } from 'primeng/listbox';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { PanelMenuModule } from 'primeng/panelmenu';
import { DividerModule } from 'primeng/divider';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { DockModule } from 'primeng/dock';
//import { DialogModule } from 'primeng/dialog';

import { UserCardComponent } from './components/slick-detail/user-card/user-card.component';
import { ChatAppModule } from './components/chat/chat.app.module';
import { SafeUrlPipe } from './components/p-video-player/safe-url.pipe';
import { BreadcrumbsService } from './services/breadcrumbs.service'; // Atualize o caminho conforme necessário
import { RouterModule } from '@angular/router'; // Importe o RouterModule se ainda não estiver importado
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { Amplify } from 'aws-amplify';
import awsExports from '../aws-exports';
import { SlickDetailComponent } from './components/slick-detail/slick-detail.component';
//import { FormatDurationPipe } from 'src/app/shared/format-duration.pipe'; 
import { MobileTooltipComponent } from './mobile-tooltip/mobile-tooltip.component';
import { PStoreComponent } from './components/p-store/p-store.component';
//import { PPlayerComponent } from './components/p-player/p-player.component';
import { PLayersComponent } from './components/p-layers/p-layers.component';
import { PEventsComponent } from './components/p-events/p-events.component';
import { PAccessComponent } from './components/p-access/p-access.component';
import { PDdirectComponent } from './components/p-ddirect/p-ddirect.component';
import { PDirectComponent } from './components/p-direct/p-direct.component';
//import { PVideoPlayerComponent } from './components/p-video-player/p-video-player.component';
import { PAnalyticsComponent } from './components/p-analytics/p-analytics.component';
import { PPdpComponent } from './components/p-pdp/p-pdp.component';
import { PPlayerPixelsComponent } from './components/p-player-pixels/p-player-pixels.component';
import { PEmbedComponent } from './components/p-embed/p-embed.component';
import { PMetaStoreComponent } from './components/p-meta-store/p-meta-store.component';
import { AlertsComponent } from './ctas/alerts/alerts.component';
import { LinksComponent } from './ctas/links/links.component';
import { WhatsappComponent } from './ctas/whatsapp/whatsapp.component';
import { CustomComponent } from './ctas/custom/custom.component';
import { FilterByTypePipe } from './components/p-video-player/filter-by-type.pipe';
import { FilterByTimelapsePipe } from './components/p-video-player/filterByTimelapse.pipe';
import { LazyLoadPosterDirective } from './components/p-video-player/lazy-load-poster.directive';
import { ServiceWorkerModule } from '@angular/service-worker';

Amplify.configure(awsExports);
//import { environment } from '../environments/environment';
import { ProfileComponent } from './components/profile/profile.component';
import { TmpComponent } from './components/tmp/tmp.component';
import { EmailResetComponent } from './components/email-reset/email-reset.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { UnregisterSwComponent } from './services/unregister-sw/unregister-sw.component';
import { CtasComponent } from './components/p-video-player/components/ctas/ctas.component';
import { SharedModule } from './shared/shared.module';
import { EditWidgetComponent } from './components/edit-widget/edit-widget.component';
import { WidgetTemplateComponent } from './components/widget-template/widget-template.component';
//import { StoriesComponent } from './components/stories/stories.component';
//import { IframeComponent } from './components/iframe/iframe.component';
//import { ModalCarouselComponent } from './components/modal-carousel/modal-carousel.component';
//import { CarouselComponent } from './components/carousel/carousel.component';
//import { GridComponent } from './components/grid/grid.component';
//import { PopupComponent } from './components/popup/popup.component';
//import { InlineComponent } from './components/inline/inline.component';
@NgModule({
    declarations: [
        SanitizeUrlPipe,
        LazyLoadPosterDirective,
        FilterByTimelapsePipe,
        FilterByTypePipe,
        SafeUrlPipe,
        UserCardComponent,
        AppComponent,
        VendasComponent,
        LivesComponent,
        ShortsComponent,
        WidgetsComponent,
        InventarioComponent,
        LeadsComponent,
        ConfigurationsComponent,
        IntegrationsComponent,
        HelpComponent,
        ContactComponent,
        ProductComponent,
        CollectionComponent,
        CategoryComponent,
        PromotionComponent,
        StoreComponent,
        PlayerComponent,
        TagsComponent,
        ExtratoComponent,
        SlickDetailComponent,
        MobileTooltipComponent,
        PStoreComponent,
        //PPlayerComponent,
        PLayersComponent,
        PEventsComponent,
        PAccessComponent,
        PDdirectComponent,
        PDirectComponent,
        //PVideoPlayerComponent,
        PAnalyticsComponent,
        PPdpComponent,
        PPlayerPixelsComponent,
        PEmbedComponent,
        PMetaStoreComponent,
        AlertsComponent,
        LinksComponent,
        WhatsappComponent,
        CustomComponent,
        ProfileComponent,
        TmpComponent,
        EmailResetComponent,
        //ManutantionModeComponent,
        VideoPlayerComponent,
        UnregisterSwComponent,
        CtasComponent,
        EditWidgetComponent,
        //StoriesComponent,
        //IframeComponent,
        //ModalCarouselComponent,
        
        //CarouselComponent,
        //GridComponent,
        //PopupComponent,
        //InlineComponent
    ],
    imports: [
        DividerModule,
        WidgetTemplateComponent,
        InputMaskModule,
        NgxMaskDirective,
        SharedModule,
        DockModule,
        //MeterGroupModule,
        ToggleButtonModule,
       // NotfoundModule,
        PanelMenuModule,
        AppRoutingModule,
        SwiperModule,
        ListboxModule,
        AppLayoutModule,
        ChatAppModule,
        ChipsModule,
        CommonModule,
        ChipModule,
        EditorModule,
        MegaMenuModule,
        SelectButtonModule,
        DropdownModule,
        BrowserModule,
        AccordionModule,
        PickListModule,
        RatingModule,
        OrderListModule,
        BrowserAnimationsModule,
        TableModule,
        ButtonModule,
        SpeedDialModule,
        OverlayPanelModule,
        DataViewModule,
        InputTextModule,
        SliderModule,
        ProgressBarModule,
        MultiSelectModule,
        FormsModule,
        CalendarModule,
        SplitButtonModule,
        
        SkeletonModule,
        MenuModule,
        TagModule,
        ToastModule,
        ConfirmDialogModule,
        ProgressSpinnerModule,
        DialogModule,
        SidebarModule,
        StepsModule,
        RadioButtonModule,
        InputNumberModule,
        InputSwitchModule,
        AutoCompleteModule,
        FileUploadModule,
        DynamicDialogModule,
        TabMenuModule,
        RippleModule,
        IgxBottomNavModule,
        IgxIconModule,
        //TooltipModule,
        CarouselModule,
        BadgeModule,
        ColorPickerModule,
        ReactiveFormsModule,
        RouterModule,
        BreadcrumbModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: !isDevMode(),
            registrationStrategy: 'registerImmediately'
        }),
    ],
    providers: [MessageService, ConfirmationService, BreadcrumbsService, provideNgxMask(),
        { provide: LocationStrategy, useClass: PathLocationStrategy }, FocusService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [LazyLoadPosterDirective],
    bootstrap: [AppComponent]
})
export class AppModule { }
