import { Component, CUSTOM_ELEMENTS_SCHEMA, OnChanges, inject, effect, Input, OnInit, ViewEncapsulation, OnDestroy, QueryList, ViewChildren, ViewChild, HostListener, ElementRef, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef, NgZone, Renderer2 } from '@angular/core';

import { LiveShopService } from 'src/app/services/liveshop.service';
import { ViewportService } from 'src/app/services/viewport.service';
import { ManangeLayoutService } from 'src/app/services/manange-layout.service';
import { startWith, takeUntil } from 'rxjs/operators';
import { MenuItem } from 'primeng/api';
import { CloudinaryImage } from '@cloudinary/url-gen/assets/CloudinaryImage';
import { CloudinaryVideo } from '@cloudinary/url-gen/assets/CloudinaryVideo';
import { scale } from '@cloudinary/url-gen/actions/resize';
import { format, quality } from '@cloudinary/url-gen/actions/delivery';
import { auto } from '@cloudinary/url-gen/qualifiers/format'
//import videojs from 'video.js';
//import 'videojs-event-tracking';
//import 'videojs-sprite-thumbnails';
declare var videojs: any;
//declare var videojsMarkers: any;
// declare var videojsSpriteThumbnails: any;
// declare module 'videojs-sprite-thumbnails';
import { Item } from 'src/app/models/liveshop';
import { UserInfoService } from 'src/app/services/user-info.service';
import { BehaviorSubject, Subject, Subscription, Observable, fromEvent, combineLatest, map, of } from 'rxjs';
import { LogService } from 'src/app/services/log.service';
import { v4 as uuidv4 } from 'uuid';
import { ChatWebSocketService } from 'src/app/services/chat-web-socket.service';
import { PMetaStoreService } from 'src/app/services/p-meta-store.service';
import { SwiperComponent } from 'swiper/angular';
import { StateManagementService } from 'src/app/services/state-management.service';
import { VideoMuteService } from 'src/app/services/video-mute.service';
import { MessageService } from 'primeng/api';
import { SVirtualIndexCalculationService } from './services/s-virtual-index-calculation.service';
import { SVideoPlayerService } from './services/s-video-player.service';
import { SChangeSourceService } from './services/s-change-source.service';
import { SUpdateVerificationService } from './services/s-update-verification.service';
import { SSwiperEventsService } from './services/s-swiper-events.service';
import { SUpdateStyleService } from './services/s-update-style.service';
import { SSetCtasService } from './services/s-set-ctas.service';
import { SMetadataLocalstorageService } from './services/s-metadata-localstorage.service';
import { AlertsTab, AssetsResponse, AccordionTab, WatchedInterval, WhatsAppTab, Links, Pixel, Slide } from './models';
import { FormBuilder, FormGroup } from '@angular/forms';
import SwiperCore, { Navigation, Pagination, Mousewheel, Swiper, Virtual, SwiperOptions } from 'swiper';
import { ToastModule } from 'primeng/toast';

// imp. standlone
import { CommonModule } from '@angular/common';
//import { ChatAppModule } from '../components/chat/chat.app.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
//import { MessageService } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { SidebarModule } from 'primeng/sidebar';
import { SwiperModule } from 'swiper/angular';
import { BadgeModule } from 'primeng/badge';
import { MenuModule } from 'primeng/menu';
import { SharedModule } from '../../shared/shared.module';


import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { SliderModule } from 'primeng/slider';
import { ProgressBarModule } from 'primeng/progressbar';
import { MultiSelectModule } from 'primeng/multiselect'
import { CalendarModule } from 'primeng/calendar';
import { SplitButtonModule } from 'primeng/splitbutton';
import { InputMaskModule } from 'primeng/inputmask';
import { SkeletonModule } from 'primeng/skeleton';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { StepsModule } from 'primeng/steps';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TabMenuModule } from 'primeng/tabmenu';
import { FileUploadModule } from 'primeng/fileupload';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { RippleModule } from 'primeng/ripple';
import { ChipModule } from 'primeng/chip';
import { EditorModule } from 'primeng/editor';
import { DataViewModule } from 'primeng/dataview';
import { PickListModule } from 'primeng/picklist';
import { OrderListModule } from 'primeng/orderlist';
import { RatingModule } from 'primeng/rating';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ChipsModule } from 'primeng/chips';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SpeedDialModule } from 'primeng/speeddial';
import { MegaMenuModule } from 'primeng/megamenu';
import { CarouselModule } from 'primeng/carousel';
import { ColorPickerModule } from 'primeng/colorpicker';
import { AccordionModule } from 'primeng/accordion';
import { ListboxModule } from 'primeng/listbox';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { PanelMenuModule } from 'primeng/panelmenu';
import { DividerModule } from 'primeng/divider';

SwiperCore.use([Navigation, Pagination, Mousewheel, Virtual]);

@Component({
  selector: 'app-p-video-player',
  templateUrl: './p-video-player.component.html',
  styleUrls: ['./p-video-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SharedModule,
    CommonModule,
    DialogModule,
    ButtonModule,
    BadgeModule,
    SwiperModule,
    ToastModule,
    SidebarModule,
    FormsModule,
    ReactiveFormsModule,
    MenuModule,
    TableModule,
    TagModule,
    DropdownModule,
    InputTextModule,
    SliderModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  encapsulation: ViewEncapsulation.Emulated,
})
export class PVideoPlayerComponent implements OnInit, OnDestroy {
  viewportService = inject(ViewportService);
  videoPlayers: any[] = [];
  currentVideoIndex: number = 0;
  @Input() store_id?: number;
  @Output() playersReady = new EventEmitter<void>();
  seed: number = 0;
  private swiperInstance: SwiperCore | null = null;
  public slides: Slide[] = []; // Adicione isso no seu componente
  public filteredAlertsBySlide$: BehaviorSubject<AlertsTab[][]> = new BehaviorSubject<AlertsTab[][]>([]);
  chatAutorization: boolean = false;
  @Input() slicks: Item[] = [];
  @Input() appendToContainer?: HTMLElement;
  InicialSlicks: Item[] = [];
  allSlicks: Item[] = [];
  nextSlick?: string;

  @ViewChildren('targetElement') targetElements!: QueryList<ElementRef>;
  swiperConfig: SwiperOptions = {
    direction: 'vertical',
    slidesPerView: 1,
    speed: 150,
    freeMode: {
      enabled: false,
    },
    longSwipesRatio: 0.4,
    touchRatio: 2,
    watchOverflow: false,
    resistanceRatio: 0.1,
    effect: 'coverflow',
    watchSlidesProgress: true,
    loop: true,
    lazy: {
      loadPrevNext: true, // Carrega o slide anterior e o próximo
      loadPrevNextAmount: 1, // Pode ajustar para carregar mais slides além dos visíveis
    },
    mousewheel: {
      releaseOnEdges: false,
      forceToAxis: true,
      sensitivity: 1, // Ajuste a sensibilidade para garantir a mudança com um movimento do scroll
    },
    navigation: false,
    pagination: false, // Desativa a paginação em bolinhas
    on: {
      transitionStart: async (swiper) => {

        this.swiperEvents.onTransitionStart(swiper, this.directionMove, (value: boolean) => {
          this.isTransitioning = value; // Atualiza a variável global `isTransitioning`
        });
      },
      transitionEnd: (swiper) => this.onTransitionEnd(swiper),
      slideChange: async (swiper) => {

        this.isFirstMove = true;
        //this.dynamicOpacity = { opacity: 0 }
        this.swiperEvents.slideChange(swiper, swiper.realIndex, this.lastRealIndex, this.virtualIndex, this.loaded_slides,
          (updatedValues) => {
            this.lastRealIndex = updatedValues.lastRealIndex;
          },
          () => {
            // this.removeHoverListeners();
            // this.removeProgressBarListeners();
            // this.removeSpriteListeners();
            this.destroy$.next();
            this.destroy$.complete();
            document.removeEventListener('mousemove', this.boundOnDragging);
            document.removeEventListener('mouseup', this.boundOnDragEnd);
            document.removeEventListener('touchmove', this.boundOnDragging);
            document.removeEventListener('touchend', this.boundOnDragEnd);
          },
          () => this.pauseActiveVisibleSlide() // Pausar o slide ativo visível
        );


        this.realActiveIndex = swiper.realIndex; // Obtém o índice real usando o método getActiveSlideIndex()
        const previousRealIndex = this.lastRealIndex;

        // Lógica para detectar direção considerando duplicados
        if (this.realActiveIndex === 0 && previousRealIndex === 2) {
          // Movimento de 2 (real) para 0 (duplicado) deve ser considerado como 'prev'
          this.directionMove = 'next';
        } else if (this.realActiveIndex === 2 && previousRealIndex === 0) {
          // Movimento de 0 (real) para 2 (duplicado) deve ser considerado como 'next'
          this.directionMove = 'prev';
        } else {
          if (this.realActiveIndex > previousRealIndex) {
            this.directionMove = 'next';
          } else if (this.realActiveIndex < previousRealIndex) {
            this.directionMove = 'prev';
          }
        }
        this.previousVirtualIndex = this.virtualIndex;
        // Se os índices real e anterior não forem ambos 0 ou ambos 2, atualize o virtualIndex
        if (!((this.lastRealIndex == 0 && this.realActiveIndex == 0) || (this.lastRealIndex == 2 && this.realActiveIndex == 2))) {
          this.virtualIndex = await this.getVirtualActiveIndex();
          this.lastRealIndex = await swiper.realIndex;
        }


      },
      sliderMove: (swiper) => {
        if (!this.isTransitioning) {
          this.targetElements.forEach((elementRef) => {
            const element = elementRef.nativeElement;

            // Obter os valores atuais de --element-opacity e --element-visibility
            const currentOpacity = getComputedStyle(element).getPropertyValue('--element-opacity');
            const currentVisibility = getComputedStyle(element).getPropertyValue('--element-visibility');

            // Verificar se as propriedades já estão configuradas
            if (currentOpacity !== '0' || currentVisibility !== 'hidden') {
              // Aplicar apenas se os valores forem diferentes
              element.style.setProperty('--element-opacity', '0');
              element.style.setProperty('--element-visibility', 'hidden');
            }
          });
        }

        this.swiperEvents.onSliderMove(swiper, this.virtualIndex, this.slicks.length, false,
          (updatedValues) => {
            this.progressBarOpacity = updatedValues.progressBarOpacity;
            this.blockOpacity = updatedValues.blockOpacity;
            this.currentVideoIndex = updatedValues.currentVideoIndex;
          }
        );
      },
      slideChangeTransitionStart: (swiper: Swiper) => {
        this.isTransitioning = true;
      },
      slideChangeTransitionEnd: (swiper: Swiper) => {

        this.swiperEvents.slideChangeTransitionEnd((updatedValues) => {
          this.progressBarOpacity = updatedValues.progressBarOpacity;
          this.blockOpacity = updatedValues.blockOpacity;
        });
        this.swiperEvents.slideChangeTransitionStart(swiper, this.directionMove, this.slickInPlay, this.virtualIndex, () => this.checkPlayer());
      },
      touchStart: (swiper, event) => {
        if (this.directionMove != undefined && this.isTransitioning) {
          //swiper.allowSlideNext = false; swiper.allowTouchMove = false; swiper.allowSlidePrev = false;
        }
      }
    }
  };
  @Output() videosLoaded = new EventEmitter<boolean>();
  dynamicWidth: number = 0;
  @ViewChild('vc2', { static: true }) videoContainer!: ElementRef;
  constructor( // ElementRef
    private assetService: LiveShopService,
    public fb: FormBuilder,
    public virtulIndexService: SVirtualIndexCalculationService,
    public updateVerificationService: SUpdateVerificationService,
    public swiperEvents: SSwiperEventsService,
    public metadataLocalStorageService: SMetadataLocalstorageService,
    public styleService: SUpdateStyleService,
    public setCtasService: SSetCtasService,
    public videoPlayerService: SVideoPlayerService,
    public changeSourceService: SChangeSourceService,
    private messageService: MessageService,
    private manangeLayoutService: ManangeLayoutService,
    public stateManagementService: StateManagementService,
    private cdr: ChangeDetectorRef,
    private chatWebSocketService: ChatWebSocketService,
    private pMetaStore: PMetaStoreService,
    private userInfoService: UserInfoService,
    private logService: LogService,
    private zone: NgZone,
    private elRef: ElementRef,
    public videoMuteService: VideoMuteService,
    private renderer: Renderer2
  ) {
    this.issueForm = this.fb.group({});
    if (typeof Worker !== 'undefined') {
      // Criar uma instância do Web Worker usando a URL do arquivo criado
      this.theaterModeWorker = new Worker(new URL('./workers/theater-mode.worker.ts', import.meta.url), { type: 'module' });
    }
    //if(this.widget_id != null){
    // effect(() => {
    //   let templateType = this.viewportService.getTemplateType()();
    //   if (templateType == 'inline') {
    //     this.dynamicWidth = this.viewportService.getDivWidthSignal()();
    //     this.height = this.viewportService.getDivHeightSignal()();
    //     this.onResize();
    //   }
    //   if (templateType) {
    //     this.playersSetupCompleted = true;
    //   }
    // });
    //}

  }

  @Output() allPlayersReady = new EventEmitter<void>();
  async setupSrcs(slicks: any) {
    // Inscrever-se ao Subject para esperar os slicks serem preenchidos
    if (this.playersSetupCompleted) {
      for (let i = 0; i < 3; i++) {
        const player = this.getPlayerFromArray(i, false);
        let videoSrc = slicks[i]?.media?.src;
        const videoPoster = slicks[i]?.media?.thumbnail;

        if (videoSrc) {
          videoSrc = this.removeUrlValidator(videoSrc); // Remover o validador, se existir
        }
        if (player && videoSrc) {
          player.src({ src: videoSrc });
          // if (videoPoster) {
          //   player.poster(videoPoster);
          // }
        }

        if (i === 0) {
          await this.playActiveVideo();
          this.allPlayersReady.emit();

        }

      }
      this.sourceOk = true;

    }
  }
  removeUrlValidator(url: string): string {
    return url.includes('?') ? url.split('?')[0] : url;
  }
  ngOnInit() {

    this.slicksSubject.pipe(takeUntil(this.destroyNovo$)).subscribe(async (slicks) => {
      this.setupSrcs(slicks);
    });

    this.loadInitialSlides();
    this.updateGridMinHeight();
  }
  updateGridMinHeight(): void {
    const minHeight = 260; // Altura mínima fixa em pixels
    // Atualiza a variável CSS globalmente
    document.documentElement.style.setProperty('--min-height', `${minHeight}px`);
  }
  private observer: IntersectionObserver | null = null;
  async ngAfterViewInit() {

    // 
    this.session_id = this.generateUID();
    this.issueForm = this.fb.group({
      "session_id": this.session_id
    });
    this.assetService.createSessionId(this.issueForm.value).pipe(takeUntil(this.destroy$)).subscribe({
      next: (res) => {
        localStorage.setItem('session_id', res.session_id);
        this.events('session_id', res.session_id, 0);
      }
    });

    //window.requestAnimationFrame(() => {
    if (this.widget_id == null) {
      if (this.videoElements) {
        this.videoElements.changes.pipe(takeUntil(this.destroy$)).subscribe(() => {
          // Remover as divs duplicadas das posições duplicadas
          this.removeDuplicateDivs();
          // Primeiro, configurar apenas o player da posição zero real
          const firstElement = this.videoElements?.first;
          if (firstElement) {
            this.initializeIntersectionObserver();
            const slideElement = firstElement.nativeElement.closest('.swiper-slide');
            const slideIndex = parseInt(slideElement.getAttribute('data-swiper-slide-index'), 10);
            const isDuplicate = slideElement.classList.contains('swiper-slide-duplicate');
            // Verificar se a posição zero é real (não duplicada) e configurar o player
            if (slideIndex === 0 && !isDuplicate) {
              this.setupFirstPlayer({ element: firstElement, index: slideIndex }).then(() => {
                //
                // Obter o player associado ao elemento de vídeo usando arrayPlayers
                const player = this.getPlayerFromArray(0, false);
                if (player) {
                  player.on('ready', async () => {
                    if (!this.firstVideo) {
                      await this.videosLoaded.emit(true);
                      this.setupAfterReady();
                    }
                  });
                }
              });

            }
          }
          setTimeout(() => {
            this.setupEventListeners();
          }, 0);

          //this.addHoverListeners();
        });
      }


      // if (!this.isShowLottie) {
      //   if (this.isMobile) {
      //     this.srcLottie = '../../../assets/mobilescroll.json';
      //   } else {
      //     this.srcLottie = '../../../assets/mousescroll.json';
      //   }

      //   this.showLottiePlayer = true;
      //   let stCS = setTimeout(() => {
      //     this.showLottiePlayer = false;
      //     this.isShowLottie = true;
      //     clearTimeout(stCS);
      //   }, 4000);
      // }
      this.logService.getLogs();
      this.videoPlayerService.setPlayers(this.arrayPlayers);
      this.muteSubscription = this.videoMuteService.isMuted$.pipe(takeUntil(this.muteState$)).subscribe(isMuted => {
        this.setMuteState(isMuted);
      });
      setTimeout(() => {
        this.setLogoStore();
      }, 0);
      setTimeout(() => {
        this.loadCustomerCrypto();
        this.listPixels();
      }, 0);
    }

  }
  initializeIntersectionObserver(): void {
    // Obter os slides diretamente
    const slides = this.swiperElement?.swiperRef?.el?.querySelectorAll('.swiper-slide');

    if (!slides || slides.length === 0) {
      console.error('Nenhum slide encontrado para observar.');
      return;
    }

    // Configuração do IntersectionObserver com ajustes
    const options = {
      root: null, // Observar em relação ao viewport
      rootMargin: '10px', // Adiciona margem para tolerância
      threshold: [0.75, 1.0], // Dispara em diferentes níveis de visibilidade
    };

    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        // Dispara se o slide estiver visível (parcialmente ou totalmente)
        if (entry.isIntersecting) {
          this.setupCtas();
        }
      });
    }, options);

    // Observar cada slide (excluindo duplicados)
    slides.forEach((slide: Element) => {
      if (!slide.classList.contains('swiper-slide-duplicate')) {
        this.observer?.observe(slide);
      }
    });
  }


  // Método para remover as divs duplicadas das posições duplicadas
  private removeDuplicateDivs(): void {
    const duplicateSlides = document.querySelectorAll('.swiper-slide-duplicate');
    duplicateSlides.forEach(slide => {
      const gridContainer = slide.querySelector('.gridContainer');
      if (gridContainer) {
        gridContainer.remove();
      }
    });
  }
  private loadInitialSlides(): void {
    let limit = 3;
    let templateType = this.viewportService.getTemplateType()();
    if (this.widget_id != null && templateType != 'iframe') {

    } else {
      this.seed = Math.floor(Math.random() * 1000000) + 1;
      this.assetService.getAssetsPublic(limit, 'shorts', this.page, this.seed, this.slick_id).pipe(takeUntil(this.destroy$)).subscribe({
        next: (res: AssetsResponse) => {
          this.shouMuteImg = true; //InicialSlicks
          this.totalAssets = res.totalAssets;
          let newSlicks = res.assets.flatMap(asset => asset.items);

          if (newSlicks.length === 0) {
            this.zeroVideos = true;
          }

          if (newSlicks.length < 3) {
            const originalLength = newSlicks.length;
            for (let i = originalLength; i < 3; i++) {
              // Duplicar o último item disponível até alcançar 3 itens
              newSlicks.push({ ...newSlicks[newSlicks.length - 1] });
            }
          }

          this.slicks = newSlicks;

          newSlicks = newSlicks.slice(0, 3);
          this.InicialSlicks = newSlicks;

          this.pageLoading = false;
          //this.isFinishTranstion = this.slicks.map((_, index) => index === 0 ? false : true);
          this.loaded_slides = 3;
          this.onResize();
          this.slicksSubject.next(newSlicks);
        },
        error: (err) => { }
      });
    }

  }
  initializeSlicks(slicks: Item[], totalAssets: number, pageToVideos: number): void {

    const totalNewItems = slicks.length;
    pageToVideos = Math.ceil(totalNewItems / 3);
    this.setupSrcs(this.slicks);
    this.onResize();
    this.videoMuteService.setMuteState(true);
    this.shouMuteImg = true; // Inicialização de slicks
    this.totalAssets = totalAssets;
    this.page = pageToVideos;
    this.playersSetupCompleted = true;

    // Garante que `slicks` tenha no mínimo 3 itens, duplicando o último item se necessário
    if (slicks.length < 3) {
      const originalLength = slicks.length;
      for (let i = originalLength; i < 3; i++) {
        slicks.push({ ...slicks[slicks.length - 1] });
      }
    }

    this.slicks = slicks;
    this.InicialSlicks = slicks.slice(0, 3);
    this.pageLoading = false;
    //this.isFinishTranstion = this.slicks.map((_, index) => index === 0 ? false : true);
    this.loaded_slides = this.slicks.length;
    if (this.loaded_slides < 3) {
      this.loaded_slides = 3;
    }

    // Força o Swiper a ir para o primeiro slide real
    if (this.swiperElement && this.swiperElement.swiperRef) {
      this.swiperElement.swiperRef.slideToLoop(0, 0); // Vai para a posição real zero
    }
  }

  public initializeAfterView(): void {
    this.InicialSlicks = this.slicks.slice(0, 3);

    // Verificar se `videoElements` já está presente
    if (this.videoElements && this.videoElements.length > 0) {
      this.initializeVideoElements();
    }

    // Configurar o observer para verificar a presença de `videoElements` apenas uma vez
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (this.videoElements && this.videoElements.length > 0) {
          this.initializeVideoElements();
          observer.disconnect(); // Desconectar o observer imediatamente
          break; // Sair do loop após encontrar os elementos
        }
      }
    });

    // Observa o container do Swiper para mudanças
    const targetNode = this.videoElements?.first ? this.videoElements.first.nativeElement.closest('.swiper-container') : document.body;
    if (targetNode) {
      observer.observe(targetNode, { childList: true, subtree: true });
    }

    // Restante da lógica de inicialização
    this.logService.getLogs();
    this.videoPlayerService.setPlayers(this.arrayPlayers);
    this.muteSubscription = this.videoMuteService.isMuted$.pipe(takeUntil(this.destroy$)).subscribe(isMuted => {
      this.setMuteState(isMuted);
    });
    setTimeout(() => {
      this.setLogoStore();
    }, 0);
    setTimeout(() => {
      this.loadCustomerCrypto();
      this.listPixels();
    }, 0);
  }

  // Função separada para inicializar os `videoElements` e configurar o player
  private initializeVideoElements(): void {
    const firstElement = this.videoElements?.first;
    if (firstElement) {
      // Remover as divs duplicadas das posições duplicadas
      this.removeDuplicateDivs();
      // Verificar se o primeiro player já foi inicializado para evitar duplicação
      const slideElement = firstElement.nativeElement.closest('.swiper-slide');
      const slideIndex = parseInt(slideElement.getAttribute('data-swiper-slide-index'), 10);
      const isDuplicate = slideElement.classList.contains('swiper-slide-duplicate');

      // Apenas inicializar o player na posição zero real e se ainda não foi inicializado
      if (slideIndex === 0 && !isDuplicate && !this.firstVideo) {
        this.initializeIntersectionObserver();
        this.setupFirstPlayer({ element: firstElement, index: slideIndex }).then(() => {
          const player = this.getPlayerFromArray(0, false);
          if (player) {
            player.on('ready', async () => {
              if (!this.firstVideo) {
                await this.videosLoaded.emit(true);
                this.setupAfterReady();
              }
            });
            player.on('play', () => {
              player.muted(this.videoMuteService.getMuteState());
            });
          }
        });
      }
    }
    setTimeout(() => {
      this.setupEventListeners();
    }, 0);
  }






  private playersInitialized = false;
  async setupAfterReady() {
    this.firstVideo = true;
    setTimeout(() => {
      this.setupOtherPlayers().then(() => {
        this.setupSrcs(this.slicks);
      });
    }, 0);
  }

  sourceOk: boolean = false;


  listPixels() {
    this.assetService.list_pixel().pipe(takeUntil(this.destroy$)).subscribe({
      next: (res) => {
        this.transformAndSaveData(res.data);
      }
    });
  }
  loadCustomerCrypto() {
    this.userInfoService.getUserInfo().pipe(takeUntil(this.destroy$)).subscribe((userInfo) => {
      let customerCrypto = this.metadataLocalStorageService.getCustomerCrypto();
      if (customerCrypto && customerCrypto.chat_user && customerCrypto.chat_user.cart_id) {
        // Se já existe um cart_id, atualizar apenas localização e dispositivo
        this.customerCrypto = this.metadataLocalStorageService.updateCustomerCrypto(customerCrypto, userInfo.location, userInfo.device);
      } else {
        // Se não existe cart_id, gerar um novo
        this.issueForm = this.fb.group({
          "guest_id": this.generateUID()
        });
        this.assetService.gerarSessaoVisitante(this.issueForm.value).pipe(takeUntil(this.destroy$)).subscribe({
          next: (res) => {
            const cartId = res.shopping_cart_id;  // Receber o novo cart_id da resposta
            customerCrypto = {
              is_logged_in: false,
              has_user_name: false,
              chat_user: {
                name: this.username,
                role: 1,
                cart_id: cartId,
                banned: false
              },
              metadata: {
                name: this.username,
                role: 1,
                cart_id: cartId,
                banned: false
              },
              location: userInfo.location,
              device: userInfo.device
            };

            // Salvar customerCrypto no localStorage usando o serviço
            this.metadataLocalStorageService.setCustomerCrypto(customerCrypto);
            this.customerCrypto = customerCrypto;
          },
          error: (err) => { }
        });
      }
      // Atualizar e salvar customerCrypto independente de cart_id novo ou existente
      this.customerCrypto = {
        ...customerCrypto,
        location: userInfo.location,
        device: userInfo.device
      };
      this.metadataLocalStorageService.setCustomerCrypto(this.customerCrypto);  // Salvar usando o serviço
    });
  }
  //@ViewChildren('videoElement0, videoElement1, videoElement2') videoElements?: QueryList<ElementRef>;
  @ViewChildren('videoElement') videoElements?: QueryList<ElementRef>;


  private slicksSubject = new Subject<any[]>();

  setLogoStore() {
    const storedInfoJSON = localStorage.getItem('store');
    if (storedInfoJSON) {
      const storedInfo = JSON.parse(storedInfoJSON);
      const img = storedInfo.store_logo;
      // new CloudinaryImage(storedInfo.store_logo, { cloudName: 'slicksell' })
      //   .resize(scale().width(500))
      //   .delivery(quality('auto'))
      //   .delivery(format(auto()))
      //   .setDeliveryType("fetch").toURL();
      this.logoStore = img;
    }
  }

  // Método para configurar o primeiro player de índice zero
  async setupFirstPlayer(elementData: any) {
    const { element, index } = elementData;

    await new Promise<void>((resolve) => {
      window.requestAnimationFrame(() => {
        if (typeof videojs !== 'undefined') {
          const player = videojs(element.nativeElement, {
            controls: false,
            autoplay: true,
            preload: 'auto',
            muted: true,
            loop: true
          });

          // Verifica se o plugin foi carregado e o adiciona ao player
          if (videojs.getPlugin('eventTracking')) {
            player.eventTracking({
              performance: true,
              activity: true,
            });
          } else {
            console.warn('eventTracking plugin não encontrado');
          }
          //
          this.setArrayPlayers(player, index);
          const videoJsWrapper = element.nativeElement.closest('.video-js');
          if (videoJsWrapper && videoJsWrapper.hasAttribute('src')) {
            videoJsWrapper.removeAttribute('src');
          }
          resolve();
        }

      });
    });
  }

  async setArrayPlayers(player: any, index: number) {
    // Adicionar o player ao array para controle futuro
    this.arrayPlayers.push({ player: player, isDuplicate: false, index: index });
    this.videoPlayers[index] = player;
    this.videoPlayers = this.videoPlayers.filter((player) => player !== undefined);
  }

  playersSetupCompleted: boolean = false;
  // Método para configurar os demais players e slides duplicados
  async setupOtherPlayers() {

    // Executar todos os métodos em paralelo
    await Promise.all([
      this.setupPlayerAtIndex(1, false),
      this.setupPlayerAtIndex(2, false),
      this.setupPlayerAtIndex(0, true),
      this.setupPlayerAtIndex(2, true),
    ]);
  }

  async setupPlayerAtIndex(index: number, isDuplicate: boolean) {
    await new Promise<void>((resolve) => {
      requestAnimationFrame(() => {
        const elementData = this.videoElements?.find((videoElement: ElementRef<any>, i: number) => {
          const slideEl = videoElement.nativeElement.closest('.swiper-slide');

          if (!slideEl) {
            return false;
          }

          const slideIdx = parseInt(slideEl.getAttribute('data-swiper-slide-index') || "-1", 10);
          return slideIdx === index && slideEl.classList.contains('swiper-slide-duplicate') === isDuplicate;
        });

        if (elementData) {
          resolve();
        }
      });
    });

    const elementData = this.videoElements?.find((videoElement: ElementRef<any>, i: number) => {
      const slideEl = videoElement.nativeElement.closest('.swiper-slide');

      if (!slideEl) {
        return false;
      }

      const slideIdx = parseInt(slideEl.getAttribute('data-swiper-slide-index') || "-1", 10);
      return slideIdx === index && slideEl.classList.contains('swiper-slide-duplicate') === isDuplicate;
    });

    if (!elementData) return;
    const element = elementData;

    if (isDuplicate) {
      await this.setupDuplicateSlide(element, index);
    } else {
      await this.setupRealPlayer(element, index);
    }
  }


  async setupDuplicateSlide(element: ElementRef<any>, index: number) {
    const createPosterSrc = (src: string): string => {
      return `https://res.cloudinary.com/slicksell/video/fetch/so_0/f_webp/e_blur:1000/${src}`;
    };

    const swiperInstance = this.swiperElement?.swiperRef;
    if (swiperInstance) {
      swiperInstance.on('loopFix', () => {
        swiperInstance.slides.forEach((slide) => {
          const slideElement = slide as HTMLElement;
          const slideIndexAttr = slideElement.getAttribute('data-swiper-slide-index');

          if (slideIndexAttr !== null) {
            const slideIndex = parseInt(slideIndexAttr, 10);
            const isDuplicateSlide = slideElement.classList.contains('swiper-slide-duplicate');

            if (isDuplicateSlide && slideIndex === index) {
              const videoElement = slideElement.querySelector('video');
              if (videoElement) {
                const posterSrc = this.slicks[index].media.src;
                //videoElement.setAttribute('poster', posterSrc);
              }
            }
          }
        });
      });
      swiperInstance.emit('loopFix');
    }
  }

  async setupRealPlayer(element: ElementRef<any>, index: number) {
    const videoSrc = this.slicks[index].media.src;

    await new Promise<void>((resolve) => {
      requestIdleCallback(() => {
        const player = videojs(element.nativeElement, {
          controls: false,
          autoplay: true,
          preload: 'auto',
          muted: true,
          loop: true,
          plugins: {
            eventTracking: {
              performance: true,
              activity: true,
            },
          },
        });

        player.on('playing', () => {
          this.playVideoAtual();
        });

        player.on('play', () => {
          player.muted(this.videoMuteService.getMuteState());
        });

        player.on('ready', () => {
          this.playersSetupCompleted = true;
          resolve();
        });

        requestAnimationFrame(() => {
          // Adicionar o player ao array para controle futuro
          this.arrayPlayers.push({ player: player, isDuplicate: false, index: index });
          this.videoPlayers[index] = player;
          this.videoPlayers = this.videoPlayers.filter((player) => player !== undefined);

          const videoJsWrapper = element.nativeElement.closest('.video-js');
          if (videoJsWrapper && videoJsWrapper.hasAttribute('src')) {
            videoJsWrapper.removeAttribute('src');
          }
        });
      });
    });
  }

  firstVideo: boolean = false;
  firstSwipe: boolean = false;
  onSwiper(swiper: SwiperCore): void {
    this.swiperInstance = swiper;
    const activeIndex = swiper?.realIndex ?? 0; // Obter o índice ativo real
    this.activeSlideIndex$.next(activeIndex);
    //this.swiperInstance.allowSlidePrev = false;
  }
  get customSwiperInstance(): Swiper {
    return this.swiperElement.swiperRef;
  }
  novoVirtualIndex: number = 0;
  getVirtualActiveIndex(): number {
    let slickN = this.loaded_slides;
    let virtualIndex = this.virtualIndex;

    let swipeFactor = this.getSwipeFactor(this.realActiveIndex, this.lastRealIndex);
    this.novoVirtualIndex = this.calculateIndexSum(virtualIndex, swipeFactor, slickN);

    return this.novoVirtualIndex;
  }
  getSwipeFactor(active: any, last: any) {
    return (active - last + 3) % 3 === 1 ? 1 : -1;
  }
  calculateIndexSum(virtualIndex: any, swipeFactor: any, slickN: any) {
    let indexSum = (virtualIndex + swipeFactor) % slickN;

    // Ajusta se o resultado for negativo
    if (indexSum < 0) {
      indexSum += slickN;
    }

    return indexSum;
  }

  progressBarOpacity: number = 1;
  lastRealIndex: number = 0;
  lastRealIndexes: number[] = [0];
  realActiveIndex: number = 0;
  virtualIndex: number = 0;
  directionMove?: string;
  isFirstMove: boolean = false;
  previousVirtualIndex: number = 0;

  pauseAllExceptActiveVideo() {
    const swiperInstance = this.swiperElement?.swiperRef;
    if (!swiperInstance) {
      return;
    }

    // Obter o índice do slide atualmente visível (real ou duplicado)
    const activeRealIndex = swiperInstance.realIndex;
    const activeSlide = swiperInstance.slides[swiperInstance.activeIndex];
    const isDuplicate = activeSlide.classList.contains('swiper-slide-duplicate');
    // Pausar todos os players, exceto o ativo
    this.arrayPlayers.forEach(({ player, index, isDuplicate: isDup }) => {
      if (index !== activeRealIndex || isDup !== isDuplicate) {
        //player.muted(true);
        player.pause();
      }
    });
  }

  slickInPlay: number = 0;
  playVideoAtual() {
    const swiperInstance = this.swiperElement?.swiperRef;
    if (!swiperInstance) {
      return;
    }
    this.videoPlayerService.playVideoAtual(swiperInstance, swiperInstance.realIndex, (index: number, isDuplicate: boolean) => this.getPlayerFromArray(index, isDuplicate), this.isPaused, this.virtualIndex, this.slickInPlay, () => this.pauseOhters());
  }

  pauseOhters() {
    const swiperInstance = this.swiperElement?.swiperRef;
    if (!swiperInstance) {
      return;
    }
    // // Pausar todos os players que não possuem a classe swiper-slide-visible
    Array.from(swiperInstance.slides).forEach((slide: any) => {
      if (!slide.classList.contains('swiper-slide-visible')) {
        const indexStr = slide.getAttribute('data-swiper-slide-index');
        if (indexStr) {
          const index = parseInt(indexStr, 10);
          const isSlideDuplicate = slide.classList.contains('swiper-slide-duplicate');
          const slidePlayer = this.getPlayerFromArray(index, isSlideDuplicate);
          if (slidePlayer && !slidePlayer.paused()) {
            slidePlayer.pause();
          }
        }
      }
    });
  }

  onClose() {
    this.showLoadingToast = false;
    this.chatAutorization = false;
    this.showAllShortucutLinks = false;
    this.screenChat = false;

    // const sidebarElement = document.querySelector('.ss-l-pSidebarChat');
    // if (sidebarElement) {
    //   sidebarElement.classList.add('onHide'); // Adiciona a animação de saída
    //   sidebarElement.classList.remove('onShow'); // Remove a animação de entrada
    // }
    this.restoreOverlayToBody(); // Restaura o overlay ao fechar a sidebar
    this.cdr.detectChanges(); // Garante a atualização da UI
  }
  @ViewChild('overlayContainer', { static: true }) overlayContainer!: ElementRef<HTMLDivElement>;
  private originalParent: HTMLElement | null = null;
  adjustOverlayZIndex() {
    const overlayElement = document.querySelector('.p-sidebar-mask');
    if (overlayElement) {
      this.renderer.setStyle(overlayElement, 'z-index', '1103');
    }
    this.moveOverlayToComponent();
    // const sidebarElement = document.querySelector('.ss-l-pSidebarChat');
    // if (sidebarElement) {
    //   sidebarElement.classList.add('onShow'); // Adiciona a animação de entrada
    //   sidebarElement.classList.remove('onHide'); // Remove a animação de saída
    // }
  }
  moveOverlayToComponent(): void {
    const overlay = document.querySelector('.p-component-overlay') as HTMLElement;
    if (overlay && this.overlayContainer) {
      this.originalParent = overlay.parentElement; // Armazena o pai original
      this.overlayContainer.nativeElement.appendChild(overlay); // Move o overlay para o contêiner
    }
  }

  restoreOverlayToBody(): void {
    const overlay = document.querySelector('.p-component-overlay') as HTMLElement;
    if (overlay && this.originalParent) {
      this.originalParent.appendChild(overlay); // Restaura o overlay ao pai original
    }
  }
  // onClose

  get dynamicGridStyles() {
    if (!this.dynamicWidth || this.dynamicWidth <= 0) {
      // Se `dynamicWidth` não estiver definido ou for zero, retorna um objeto vazio
      return {};
    }
    // Adapta as colunas e linhas com base em `dynamicWidth`
    if (this.dynamicWidth < 350) {
      return {
        'grid-template-columns': '20% 65% 15%',
        'grid-template-rows': '9% 8% 9.5% 5% 6% 15% 47.5%',
        '--swiper-slide-max-width': '75%'
      };
    } else if (this.dynamicWidth >= 350 && this.dynamicWidth < 400) {
      return {
        'grid-template-columns': '15% 70% 15%',
        'grid-template-rows': '9% 8% 12.5% 5% 6% 15% 44.5%',
        '--swiper-slide-max-width': '75%'
      };
    } else if (this.dynamicWidth >= 400 && this.dynamicWidth < 600) {
      return {
        'grid-template-columns': '20% 65% 15%',
        'grid-template-rows': '9% 8% 12.5% 5% 6% 15% 44.5%',
        '--swiper-slide-max-width': '75%'
      };
    } else if (this.dynamicWidth >= 600 && this.dynamicWidth < 960) {
      return {
        'grid-template-columns': '20% 65% 15%',
        'grid-template-rows': '9% 8% 12.5% 5% 6% 15% 44.5%',
        '--swiper-slide-max-width': '50%'
      };
    } else if (this.dynamicWidth >= 960 && this.dynamicWidth < 1280) {
      return {
        'grid-template-columns': '7% 86% 7%',
        'grid-template-rows': '9% 8% 13.5% 5% 6% 15% 43.5%',
        '--swiper-slide-max-width': '30%'
      };
    } else if (this.dynamicWidth >= 1280 && this.dynamicWidth < 1920) {
      return {
        'grid-template-columns': '5% 90% 5%',
        'grid-template-rows': '9% 8% 13.5% 5% 6% 15% 43.5%',
        '--swiper-slide-max-width': '30%'
      };
    } else {
      return {
        'grid-template-columns': '5% 90% 5%',
        'grid-template-rows': '9% 8% 13.5% 5% 6% 15% 43.5%',
        '--swiper-slide-max-width': '25%'
      };
    }
  }
  get mergedStyles() {
    return { ...this.combinedStyles, ...this.dynamicGridStyles };
  }
  get combinedStyles() {
    const widthValue = this.dynamicWidth > 0 ? `${this.dynamicWidth}px` : '100vw';
    return { ...this.dynamicStyle, ...this.dynamicOpacity, width: widthValue };
  }
  get combinedStylesWidth() {
    const widthValue = this.dynamicWidth > 0 ? `${this.dynamicWidth}px` : '100vw';
    return { ...this.dynamicStyle, width: widthValue };
  }
  progressBarTop: string = '0px';

  progress: number = 0;
  countChanges: number = 0;
  isTransitioning: boolean = false;
  pauseActiveVisibleSlide(): void {
    const swiperInstance = this.swiperElement?.swiperRef;
    if (swiperInstance) {
      const activeVisibleSlide = Array.from(swiperInstance.slides).find((slide: any) => {
        const slideElement = slide as HTMLElement;
        return (
          slideElement.classList.contains('swiper-slide-visible') &&
          slideElement.classList.contains('swiper-slide-active')
        );
      }) as HTMLElement;

      // Se o slide for encontrado, procurar pela tag <video> dentro dele e pausar
      if (activeVisibleSlide) {
        const player = this.checkPlayer(); // Inicializar o player usando videojs se necessário
        //player.pause(); // Pausar o vídeo no slide ativo e visível
      }
    }
  }


  //updateVideoSrc


  changeSrcs(): void {
    try {
      this.realActiveIndex = this.getActiveSlideIndex();
      this.changeSourceService.changeSrcs(this.swiperElement, this.virtualIndex, this.directionMove, this.slicks, this.realActiveIndex, this.arrayPlayers);
      // As chamadas dos métodos são feitas diretamente após a chamada de `changeSrcs`
      this.pauseAllExceptActiveVideo();
      this.playVideoAtual();
    } catch (error) {
      console.error('Error changing sources:', error);
    }
  }

  currentSlideIndex = 0; // Índice do slide atual no swiper


  lastIndex: number = 0;

  swiperIndexBeforeMove = 0;
  blockOpacity: boolean = false;

  getSwiperInstance(): Swiper {
    return this.swiperElement?.swiperRef ?? null;
  }

  ngOnDestroy(): void { // destro
    if (this.observer) {
      this.observer.disconnect();
    }
    // Certifique-se de desconectar o Worker ao destruir o componente para evitar vazamentos de memória
    if (this.theaterModeWorker) {
      this.theaterModeWorker.terminate();
    }

    this.subscription?.unsubscribe();
    if (this.videoElements) {
      this.videoElements.forEach((elementRef) => {
        const videoElement = elementRef.nativeElement;
        if (videoElement) {
          videoElement.removeEventListener('enterpictureinpicture', this.enterPiPListener);
        }
      });
    }

    // Completar os Subjects para evitar memory leaks
    this.visibleButtonWhatsApp.complete();
    this.visibleButton.complete();
    this.filteredAlerts.complete();
    this.filteredLinksShortucut.complete();
    this.filteredLinks.complete();

    const swiperInstance = this.getSwiperInstance();
    if (swiperInstance) {
      swiperInstance.destroy(true, true);
    }
    this.subscriptionsTime.forEach(sub => sub.unsubscribe());
    this.subscriptions.unsubscribe();
    if (this.muteSubscription)
      this.muteSubscription.unsubscribe();
    // Emite um valor para finalizar o Observable
    this.destroy$.next();
    // Completa o Subject para liberar recursos
    this.destroy$.complete();
    // Emite um valor para finalizar o Observable
    this.destroyNovo$.next();
    // Completa o Subject para liberar recursos
    this.destroyNovo$.complete();
    // Emite um valor para finalizar o Observable
    this.muteState$.next();
    // Completa o Subject para liberar recursos
    this.muteState$.complete();
    // Emite um valor para finalizar o Observable
    this.requestNewVideos$.next();
    // Completa o Subject para liberar recursos
    this.requestNewVideos$.complete();

    if (this.resizeObserverBreakPoints) {
      this.resizeObserverBreakPoints.disconnect();
    }

    if (this.resizeSubscriptions) {
      this.resizeSubscriptions.forEach(subscription => subscription.unsubscribe());
    }



    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }


    const progressContainerArray = this.progressContainers?.toArray();
    if (progressContainerArray?.length > 0 && progressContainerArray[0]?.nativeElement) {
      const progressContainer = progressContainerArray[0].nativeElement;
      progressContainer.removeEventListener('mousemove', this.boundOnDragging);
      progressContainer.removeEventListener('mouseup', this.boundOnDragEnd);
      progressContainer.removeEventListener('touchmove', this.boundOnDragging);
      progressContainer.removeEventListener('touchend', this.boundOnDragEnd);
    }

    document.removeEventListener('mousemove', this.boundOnDragging);
    document.removeEventListener('mouseup', this.boundOnDragEnd);
    document.removeEventListener('touchmove', this.boundOnDragging);
    document.removeEventListener('touchend', this.boundOnDragEnd);

    this.subscriptions.unsubscribe();
    if (this.muteSubscription) {
      this.muteSubscription.unsubscribe();
    }
    this.filteredLinksSubscription?.unsubscribe();
    this.filteredAlertsSubscription?.unsubscribe();


    if (this.player) {
      // Verifique se o player possui o método removeEventListener
      if (this.player.removeEventListener && this.leavePiPListener) {
        this.player.removeEventListener('leavepictureinpicture', this.leavePiPListener);
      }

      // Certifique-se de que o método dispose existe no player
      if (typeof this.player.dispose === 'function') {
        this.player.dispose();
      }
    }
    if (this.videoTimeSubscription) {
      this.videoTimeSubscription.unsubscribe();
    }
    this.stateManagementService.complete();

    if (this.resizeObserverProgressContainerWrapper) {
      this.resizeObserverProgressContainerWrapper.disconnect();
    }
    if (this.videoTimeSubscription) {
      this.videoTimeSubscription.unsubscribe();
    }
    this.playerSubscriptions.forEach(sub => sub.unsubscribe());

    // Remover os listeners para evitar memory leaks
    const progressBar = this.progressContainerWrapper?.nativeElement;
    if (progressBar) {
      progressBar.removeEventListener('touchmove', this.touchMoveListener);
      progressBar.removeEventListener('touchend', this.touchEndListener);
      progressBar.removeEventListener('touchcancel', this.touchEndListener);
      progressBar.removeEventListener('mousemove', this.mouseMoveListener);
      progressBar.removeEventListener('mouseleave', this.mouseLeaveListener);
    }


    this.removeProgressBarListeners();
    this.removeHoverListeners();
    this.removeSpriteListeners();
  }

  uidSlick?: string;
  slideIndex?: number;
  activeUid?: string;

  checkPlayer() {
    const swiperInstance = this.swiperElement?.swiperRef;
    if (!swiperInstance) {
      return;
    }
    // Verifica se o slide ativo é um duplicado
    const activeSlide = swiperInstance.slides[swiperInstance.activeIndex];
    const isDuplicate = activeSlide.classList.contains('swiper-slide-duplicate');

    // Obter o índice real, ignorando duplicatas
    const realIndex = this.getActiveSlideIndex();

    return this.getPlayerFromArray(realIndex, false);
  }

  async backToRealIndex(swiper: any): Promise<void> {

  }

  @ViewChild('progressBarTime') progressBarTime: ElementRef | null = null;
  @ViewChild('gridContainer') gridContainer: ElementRef<HTMLDivElement> | null = null;


  async freeMove(swiper: any): Promise<void> {
    if (!this.isRequestingSlides && !this.isTransitioning) {
      //swiper.allowSlideNext = true;
      swiper.allowTouchMove = true;
      if (this.firstSwipe) {
        swiper.allowSlidePrev = true;
      }
    }

    //
  }
  inicialSlide: boolean = true;

  async setupCtas(): Promise<void> {
    const playerDataString = localStorage.getItem('player_data');
    // Verificar se o dado existe no localStorage
    if (playerDataString) {
      // Converter o dado de volta para um objeto JavaScript
      const playerData = JSON.parse(playerDataString);

      // Acessar o valor de 'slide_index' dentro de 'current_asset'
      this.slideIndex = playerData.current_asset.slide_index;
    }

    if (this.slideIndex == this.virtualIndex && !this.firstSlick) {
      this.inicialSlide = false;
      //return;
    }
    // const visibleSlide = swiper.el.querySelector('.swiper-slide-visible') as HTMLElement;
    // if (!visibleSlide) {
    //   return;
    // }
    //const visibleIndexStr = visibleSlide.getAttribute('data-swiper-slide-index');
    // if (!visibleIndexStr) {
    //   return;
    // }
    //const realIndex = this.getActiveSlideIndex();
    //const visibleIndex = parseInt(visibleIndexStr, 10);
    //if (realIndex == visibleIndex) {

    const ctaComponents = this.slicks[this.virtualIndex]?.content?.cta?.components || {};
    this.lastSrc = this.slicks[this.virtualIndex].media.src;
    // InicialSlicks
    const activeSlideUid = this.slicks[this.virtualIndex]?.content?.uid;
    // Atualizar alertas


    // this.linksPerSlide[this.realActiveIndex] = [];
    // //this.alertsPerSlide[this.realActiveIndex] = [];
    // this.shortcutsPerSlide[this.realActiveIndex] = [];
    // this.buttonsPerSlide[this.realActiveIndex] = [];
    // this.whatsAppButtonsPerSlide[this.realActiveIndex] = [];
    this.currentVideoIndex = this.getActiveSlideIndex();

    // if (this.setupCtasTimeout) {
    //   clearTimeout(this.setupCtasTimeout);
    // }
    //setTimeout(() => {
    this.showSwiper = true;
    this.setupFilteredAlerts(this.directionMove);
    this.setupVisibleButton(this.directionMove);
    this.setupVisibleButtonWhatsApp(this.directionMove);
    this.setupFilteredLinks(this.directionMove);
    this.setupFilteredLinksShortucut(this.directionMove);
    //}, 0);



    this.hasLinks = Array.isArray(this.shortcutsPerSlide[this.realActiveIndex]) && this.shortcutsPerSlide[this.realActiveIndex].length > 0;

    this.playPauseColor = this.slicks[this.virtualIndex].extra.player.player_color != "" ? this.slicks[this.virtualIndex].extra.player.player_color : '#ff0000';
    this.progressBarColor = this.slicks[this.virtualIndex].extra.player.progress_bar_color != "" ? this.slicks[this.virtualIndex].extra.player.progress_bar_color : '#ff0000';
    this.updatePlayPauseColor(this.slicks[this.virtualIndex].extra.player.player_color);

    this.updateProgressBarColor(this.slicks[this.virtualIndex].extra.player.progress_bar_color);

    this.uidSlick = this.slicks[this.virtualIndex]?.content?.uid || '';
    this.progressBarColor = this.slicks[this.virtualIndex].extra.player.progress_bar_color;

    this.setupFilteredContent(this.directionMove, this.realActiveIndex, this.virtualIndex);

    // this.slickDesc = this.slicks[this.virtualIndex].content.description;
    // this.slickTitle = this.slicks[this.virtualIndex].content.title;


    // this.slickTitles[this.realActiveIndex] = this.slicks[this.virtualIndex].content.title;
    // if (this.slickDesc === null) {
    //   this.truncatedDesc = '';
    //   this.showDesc = false;
    // } else { // Teste
    //   this.showDesc = true;
    // }
    // this.handleTruncatedDesc();


    // // Atualize a imagem sprite com base no novo índice
    // const thumbnailPreview = this.thumbnailPreview.nativeElement;
    // if (this.slicks[this.virtualIndex]) {
    //   //thumbnailPreview.style.backgroundImage = `url('https://d2434dp583bttd.cloudfront.net/public/media/${this.slicks[this.virtualIndex].content.uid}/sprite/${this.slicks[this.virtualIndex].content.uid}.jpeg')`;
    // }

    //this.isFinishTranstion = this.slicks.map((_, index) => index === swiper.activeIndex ? true : false);
    //this.isFinishTranstion = this.slicks.map((_, index) => index === this.currentVideoIndex ? false : true);
    // 
    // setTimeout(() => {
    //   const swiperInstance = this.swiperElement?.swiperRef;

    //   if (swiperInstance) {
    //     // Converte o Dom7Array de slides para um array nativo
    //     const slides = Array.from(swiperInstance.slides);

    //     // Itera sobre todos os slides
    //     slides.forEach(slide => {
    //       const element = slide as HTMLElement; // Cast para HTMLElement
    //       const isActiveRealSlide = element.classList.contains('swiper-slide-active') &&
    //         !element.classList.contains('swiper-slide-duplicate');

    //       // Seleciona todas as gridContainer dentro do slide
    //       const gridContainers = element.querySelectorAll<HTMLElement>('.gridContainer');

    //       gridContainers.forEach(gridContainer => {
    //         const alertDivs = gridContainer.querySelectorAll<HTMLElement>('.ss-l-element');

    //         alertDivs.forEach(alertDiv => {
    //           if (isActiveRealSlide) {
    //             // Adiciona a classe apenas para os alertDivs do slide ativo
    //             alertDiv.classList.add('ss-l-visible');
    //           } else {
    //             // Remove a classe dos alertDivs de outros slides
    //             alertDiv.classList.remove('ss-l-visible');
    //           }
    //         });
    //       });
    //     });
    //   }
    // }, 0);



    this.updateGridVisibility();
    //}


  }
  setupFilteredContent(directionMove: string | undefined, realIndex: number, virtualIndex: number): void {
    let targetIndex: number = 0; // O índice do slide a ser atualizado
    let slickIndex: number = 0;

    if (directionMove === 'next') {
      if (realIndex === 0) slickIndex = 1;
      else if (realIndex === 1) slickIndex = 2;
      else if (realIndex === 2) slickIndex = 0;
    }
    if (directionMove === 'prev') {
      if (realIndex === 0) slickIndex = 2;
      else if (realIndex === 1) slickIndex = 0;
      else if (realIndex === 2) slickIndex = 1;
    }
    this.showBtnVerMais[slickIndex] = false;
    // Verificar se é a primeira posição (realActiveIndex = 0 e directionMove undefined)
    if (directionMove === undefined && realIndex === 0) {
      targetIndex = virtualIndex;

      // Atualizar o índice 0 e também o próximo (índice 1)
      [0, 1].forEach(index => {
        slickIndex = index;
        if (targetIndex >= 0 && targetIndex < this.slicks.length) {
          const slickContent = this.slicks[index == 0 ? targetIndex : targetIndex + 1]?.content;
          targetIndex = index == 0 ? targetIndex : targetIndex + 1;

          // Atualizar título e descrição
          this.slickTitles[slickIndex] = slickContent?.title || ''; // Vazio se não houver título
          this.slickDescs[slickIndex] = slickContent?.description || ''; // Vazio se não houver descrição

          // Atualizar descrição truncada
          this.updateTruncatedDescription(slickIndex);
        } else {
          // Garantir que posições inválidas sejam limpas
          this.slickTitles[slickIndex] = '';
          this.slickDescs[slickIndex] = '';
          this.truncatedDescs[slickIndex] = '';
          this.showBtnVerMais[slickIndex] = false;
        }
      });
    } else if (directionMove === 'next') {
      targetIndex = (virtualIndex + 1) % this.slicks.length;

      if (targetIndex >= 0 && targetIndex < this.slicks.length) {
        const slickContent = this.slicks[targetIndex]?.content;

        this.slickTitles[slickIndex] = slickContent?.title || ''; // Vazio se não houver título
        this.slickDescs[slickIndex] = slickContent?.description || ''; // Vazio se não houver descrição

        this.updateTruncatedDescription(slickIndex);
      } else {
        this.slickTitles[slickIndex] = '';
        this.slickDescs[slickIndex] = '';
        this.truncatedDescs[slickIndex] = '';
        this.showBtnVerMais[slickIndex] = false;
      }
    } else if (directionMove === 'prev') {
      targetIndex = virtualIndex - 1 >= 0 ? virtualIndex - 1 : this.slicks.length - 1;

      if (targetIndex >= 0 && targetIndex < this.slicks.length) {
        const slickContent = this.slicks[targetIndex]?.content;

        this.slickTitles[slickIndex] = slickContent?.title || ''; // Vazio se não houver título
        this.slickDescs[slickIndex] = slickContent?.description || ''; // Vazio se não houver descrição

        this.updateTruncatedDescription(slickIndex);
      } else {
        this.slickTitles[slickIndex] = '';
        this.slickDescs[slickIndex] = '';
        this.truncatedDescs[slickIndex] = '';
        this.showBtnVerMais[slickIndex] = false;
      }
    }
  }
  updateTruncatedDescription(index: number): void {
    const desc = this.slickDescs[index];

    if (desc) {
      if (this.viewMode === 1) {
        this.truncatedDescs[index] =
          desc.length > 25 ? `${desc.substring(0, 25)}...` : desc;
      } else {
        this.truncatedDescs[index] =
          desc.length > 120 ? `${desc.substring(0, 120)}...` : desc;
      }
      this.showBtnVerMais[index] = desc.length > (this.viewMode === 1 ? 25 : 120);
    } else {
      this.truncatedDescs[index] = '';
      this.showBtnVerMais[index] = false;
    }
  }

  // handleDivClick
  goVerMais() {
    this.fullViewActive = true;
    this.showBtnVerMaisFechar = true;
    this.showBtnVerMais[this.realActiveIndex] = false;
    // this.center = this.center - 8;
    // this.bottom3 = this.bottom3 + 8;
    this.truncatedDescs[this.realActiveIndex] = this.slickDescs[this.realActiveIndex];
    this.gridTemplateRows = `${this.top1}% ${this.top2}% ${this.center}% ${this.bottom0}% ${this.bottom1}% ${this.bottom2}% ${this.bottom3}%`;
    this.sum100();
    this.cdr.detectChanges();
  }
  goVerMaisFechar(): void {
    this.fullViewActive = false;
    this.showBtnVerMais[this.realActiveIndex] = true;
    this.showBtnVerMaisFechar = false;
    this.gridTemplateRows = `${this.top1}% ${this.top2}% ${this.center}% ${this.bottom0}% ${this.bottom1}% ${this.bottom2}% ${this.bottom3}%`;

    // Atualiza a descrição truncada para o slide ativo
    this.handleTruncatedDesc(this.realActiveIndex);

    this.sum100();
  }
  handleDivClick(event: Event): void {
    event.stopPropagation(); // Isso sempre será chamado.
    if (this.fullViewActive) {
      this.goVerMaisFechar();
    }
  }
  handleTruncatedDesc(index: number): void {
    if (this.slickDescs[index]) {
      const desc = this.slickDescs[index]; // Descrição atual baseada no índice
      if (this.viewMode === 1) {
        if (desc.length > 25) {
          this.truncatedDescs[index] = `${desc.substring(0, 25)}...`;
          this.showBtnVerMais[index] = true; // Ativa o botão "ver mais"
        } else {
          this.truncatedDescs[index] = desc; // Exibe o texto completo se for menor que o limite
          this.showBtnVerMais[index] = false;
        }
      } else {
        if (desc.length > 120) {
          this.truncatedDescs[index] = `${desc.substring(0, 120)}...`;
          this.showBtnVerMais[index] = true; // Ativa o botão "ver mais"
        } else {
          this.truncatedDescs[index] = desc; // Exibe o texto completo se for menor que o limite
          this.showBtnVerMais[index] = false;
        }
      }
    }
  }
  // handleTruncatedDesc(targetIndex: number, t2: number): void {
  //   if (this.slickDescs[targetIndex]) {
  //     if (this.viewMode === 1) {
  //       if (this.slickDescs[targetIndex].length > 25) {
  //         this.truncatedDescs[targetIndex] = `${this.slickDescs[t2].substring(0, 25)}...`;
  //         this.showBtnVerMais = true;
  //       } else {
  //         this.truncatedDescs[targetIndex] = this.slickDescs[t2];
  //         this.showBtnVerMais = false;
  //       }
  //     } else {
  //       if (this.slickDescs[targetIndex].length > 120) {
  //         this.truncatedDescs[targetIndex] = `${this.slickDescs[t2].substring(0, 120)}...`;
  //         this.showBtnVerMais = true;
  //       } else {
  //         this.truncatedDescs[targetIndex] = this.slickDescs[t2];
  //         this.showBtnVerMais = false;
  //       }
  //     }
  //   }
  // }

  updateGridVisibility(): void {
    // const gridElements = this.gridContainers?.toArray(); // Converte a QueryList em um array
    // this.currentVideoIndex = this.getActiveSlideIndex();
    // // Evita alterações se o índice ativo não mudou
    // if (this.previousVideoIndex === this.currentVideoIndex) {
    //   gridElements?.forEach((grid, index) => {
    //     const gridNative = grid.nativeElement;
    //     gridNative.classList.add('visible-grid');
    //     gridNative.classList.remove('invisible-grid');
    //   });
    //   return;
    // }

    // gridElements?.forEach((grid, index) => {
    //   const gridNative = grid.nativeElement;
    //   if (index === this.currentVideoIndex) {
    //     // Adiciona as classes para o grid ativo
    //     gridNative.classList.add('visible-grid');
    //     gridNative.classList.remove('invisible-grid');
    //   } else {
    //     // Remove as classes para grids inativos
    //     gridNative.classList.add('invisible-grid');
    //     gridNative.classList.remove('visible-grid');
    //   }
    // });

    // // Atualiza o índice anterior
    // this.previousVideoIndex = this.currentVideoIndex;
  }
  previousVideoIndex: number | null = null;
  @ViewChildren('gridContainer') gridContainers?: QueryList<ElementRef<HTMLDivElement>>;
  modalIsDisplayed: boolean = false;
  onActiveIndexChange(event: any): void {
    if (!this.isTransitioning) {
      this.targetElements.forEach((elementRef) => {
        const element = elementRef.nativeElement;
        element.style.setProperty('--element-opacity', '0');
        element.style.setProperty('--element-visibility', 'hidden');
      });
    }


  }
  onScroll(event: WheelEvent | TouchEvent) {
    if (this.isTransitioning) {
      event.preventDefault();
    }

  }
  firstSlick: boolean = true;
  isDuplicated: boolean = false;
  private setupCtasTimeout: any;
  private changeSrcTimeOut: any;
  private pipChatTimeout: any;
  appliedTheaterMode: { [index: number]: boolean } = {};

  applyTheaterMode() {
    const swiperInstance = this.swiperElement?.swiperRef;

    if (!swiperInstance) return;

    // Limpa as classes dos outros slides
    Array.from(swiperInstance.slides).forEach((slide: any) => {
      const videoUploadContainer = slide.querySelector('.videoUploadContainer');
      if (videoUploadContainer) {
        videoUploadContainer.classList.remove('ss-g-view-mode-mobile', 'ss-g-view-mode-desktop');
      }
    });

    // Adiciona a classe ao slide real ativo
    const activeSlide = swiperInstance.slides[swiperInstance.activeIndex] as HTMLElement;

    if (activeSlide) {
      const videoUploadContainer = activeSlide.querySelector('.videoUploadContainer');
      if (videoUploadContainer) {
        if (this.aspect_ratio === '1') {
          videoUploadContainer.classList.add('ss-g-view-mode-mobile');
        } else if (this.aspect_ratio === '2') {
          videoUploadContainer.classList.add('ss-g-view-mode-desktop');
        }
      }
    }
  }

  async onTransitionEnd(swiper: any) {
    console.log('transitionEnd');
    //
    let lastIndex = this.swiperEvents.getLastIndex(swiper, this.directionMove);


    if (this.changeSrcTimeOut) {
      clearTimeout(this.changeSrcTimeOut);
    }
    if (this.pipChatTimeout) {
      clearTimeout(this.pipChatTimeout);
    }
    this.showCTAs = true;

    this.currentVideoIndex = this.getActiveSlideIndex();
    const slideIndex = this.swiperEvents.getSlideIndexFromLocalStorage();
    this.dynamicOpacity = { opacity: 1 };
    this.progressBarOpacity = 1;
    this.blockOpacity = false;

    this.changeSrcTimeOut = setTimeout(() => {
      this.targetElements.forEach((elementRef) => {
        const element = elementRef.nativeElement;
        element.style.setProperty('--element-opacity', '1');
        element.style.setProperty('--element-visibility', 'visible');
      });
    }, 500);
    if ((swiper.realIndex == 2 && lastIndex == 0) || (swiper.realIndex == 0 && lastIndex == 2)) {
      swiper.loopFix();
      this.isDuplicated = true;
    } else {
      this.isDuplicated = false;
    }
    if (slideIndex !== undefined) {
      this.slideIndex = slideIndex; // Atualiza a variável global `slideIndex`
    }

    if (this.directionMove != undefined) {
      //this.changeSrcTimeOut = setTimeout(() => {
      //if (!this.isTransitioning) {
      this.changeSrcs();
      // }
      //}, 100);

    }

    // alertas, links shorts e swiper, cores de btn play/pause e barra de progresso
    this.isTransitioning = false;
    //await this.freeMove(swiper); // slide next/prev true e allowTouchMove true


    this.isInTheaterMode = true;

    this.viewModeTheaterMode();
    if (this.directionMove != undefined) {
      this.playActiveVideo();
    }
    if (this.slideIndex == this.virtualIndex && !this.firstSlick) {
      return;
    }
    this.goVerMaisFechar();
    // if(!this.slicks[this.realActiveIndex]?.content?.description){
    //   this.showBtnVerMais = false;
    // }
    //this.showBtnVerMais = false;



    let lastSlideIndex = 0;
    if (this.directionMove === 'prev') {
      if (this.realActiveIndex === 0) lastSlideIndex = 1;
      else if (this.realActiveIndex === 1) lastSlideIndex = 2;
      else if (this.realActiveIndex === 2) lastSlideIndex = 0;
    }
    if (this.directionMove === 'next') {
      if (this.realActiveIndex === 0) lastSlideIndex = 2;
      else if (this.realActiveIndex === 1) lastSlideIndex = 0;
      else if (this.realActiveIndex === 2) lastSlideIndex = 1;
    }
    if (this.realActiveIndex != lastSlideIndex) {
      let player = this.getPlayerFromArray(lastSlideIndex, false);
      if (player) {
        player.currentTime(0);
      }
    }

    this.playVideoAtual();
    this.isPaused = false;
    if (this.isDuplicated) {
      return;
    }
    this.firstSlick = false;



    //////////////////////////
    //

    if (this.directionMove != undefined) {
      this.verificarToast();
      this.checkAndLoadMoreSlides();
    }

    let lastUid;
    let currentUid;
    if (this.currentVideoIndex >= 0 && this.slicks[this.virtualIndex]) {
      lastUid = this.slicks[this.virtualIndex].content.uid;
      this.lastSlickUid = this.slicks[this.virtualIndex].content.uid;
      let idSlick = (this.currentVideoIndex - 1) > -1 ? this.currentVideoIndex - 1 : 0;
      localStorage.setItem('last-slick', this.slicks[idSlick].content.uid);
    }
    if (this.slicks[this.virtualIndex]) {
      currentUid = this.slicks[this.virtualIndex].content.uid;
      localStorage.setItem('current_asset_uid', this.slicks[this.virtualIndex].content.uid);
    }
    this.total_swipes++;
    if (this.isPiPActive && document.pictureInPictureElement) {
      try {
        await document.exitPictureInPicture();
        this.isPiPActive = false;
      } catch (error) { }
    } else {
      this.isPiPActive = false;
    }

    ////////////////// 
    this.playerSessionId = this.generateUID();
    let pagination = {
      loaded_slides: this.loaded_slides,
      page: this.page
    };
    let current_asset = {
      id: this.slicks[this.virtualIndex].content.id,
      tittle: this.slicks[this.virtualIndex].content.title,
      description: this.slicks[this.virtualIndex].content.description,
      url: this.slicks[this.virtualIndex].media.src,
      thumbnail: this.slicks[this.virtualIndex].media.thumbnail,
      slide_index: this.virtualIndex,
      total_duration: this.slicks[this.virtualIndex].media.duration,
      pitch_time: this.slicks[this.virtualIndex].extra.player.time_to_pitch_value
    };

    let playerData = {
      current_asset: current_asset,
      player_session_id: this.playerSessionId,
      total_swipes: this.total_swipes,
      pagination: pagination,
      previous_asset_uid: this.lastSlickUid
    };
    localStorage.setItem('player_data', JSON.stringify(playerData));

    ////////
    this.pipChatTimeout = setTimeout(async () => {
      if (this.videoElements) {
        const activeIndex = this.virtulIndexService.getVirtualActiveIndex(this.loaded_slides, this.virtualIndex, this.realActiveIndex, this.lastRealIndex);

        // Verificar se o índice ativo está dentro do intervalo de videoElements
        if (activeIndex >= 0 && activeIndex < this.videoElements.length) {
          const activeVideoElement = this.videoElements.toArray()[0]?.nativeElement;

          if (activeVideoElement) {
            activeVideoElement.removeEventListener('enterpictureinpicture', this.enterPiPListener);
            activeVideoElement.addEventListener('enterpictureinpicture', this.enterPiPListener);
            this.addPiPEventListener(activeVideoElement);
          }
        }
      }
      this.chatAutorization = false;
    }, 500);

    this.firstSwipe = true;

  }
  private lastContainerWidth: number | undefined;
  private lastAspectRatio: string | undefined;
  private lastIsInTheaterMode: boolean | undefined;


  viewModeTheaterMode(): void {
    this.isFullScreen = true;
    this.aspect_ratio = this.slicks[this.virtualIndex].media.aspect_ratio;
    // Verificar se o Web Worker está disponível
    if (this.theaterModeWorker) {
      const gridContainer = this.gridContainers?.first;
      if (gridContainer && gridContainer.nativeElement) {
        const containerWidth = gridContainer.nativeElement.clientWidth;

        // Somente enviar para o worker se os valores mudarem
        if (
          containerWidth !== this.lastContainerWidth ||
          this.aspect_ratio !== this.lastAspectRatio ||
          this.isInTheaterMode !== this.lastIsInTheaterMode
        ) {
          // Armazena os valores atuais para a próxima verificação
          this.lastContainerWidth = containerWidth;
          this.lastAspectRatio = this.aspect_ratio;
          this.lastIsInTheaterMode = this.isInTheaterMode;

          // Enviar dados para o Worker calcular
          this.theaterModeWorker.postMessage({
            containerWidth,
            aspect_ratio: this.aspect_ratio,
            isInTheaterMode: this.isInTheaterMode
          });

          // Definir um listener para receber a resposta do Worker
          this.theaterModeWorker.onmessage = ({ data }) => {
            const { viewMode, theaterMode, hasTheaterMode, isFullScreen } = data;

            // Atualizar as variáveis globais com os valores retornados pelo Worker
            this.viewMode = viewMode;
            this.theaterMode = theaterMode;
            this.hasTheaterMode = hasTheaterMode;
            this.isFullScreen = isFullScreen;
            this.cdr.detectChanges(); // Atualizar o DOM manualmente, se necessário
          };
        }
      }
    }
  }


  lastVI: number = -1;
  // Método para iniciar o monitoramento
  private startMonitoringVirtualIndex() {
    if (this.isRequestingSlides) {
      const swiperInstance = this.swiperElement?.swiperRef;
      if (swiperInstance) {
        swiperInstance.allowSlideNext = false;
        swiperInstance.allowSlidePrev = false;
        swiperInstance.allowTouchMove = false;
      }
      this.modalIsDisplayed = true;
      this.openToast();
    }

  }
  @Input() page: number = 1;
  dragStartY: number | null = null;
  isDragging: boolean = false;
  interleaveOffset: number = 0.75;
  boundOnDragging: any;
  boundOnDragEnd: any;

  private theaterModeWorker: Worker | undefined;
  // Debounced function

  // Método para atualizar o estado


  issueForm: FormGroup;
  generateUID() {
    return uuidv4();
  }

  user_chat?: string;
  subdomain?: string;


  viewMode: number = 1;
  aspect_ratio: string = "1";
  private muteSubscription?: Subscription;
  manutantionMode: boolean = false;
  pageLoading: boolean = true;
  ////////////////////////////////////

  logoStore: string = '';
  srcLottie: string = '';
  isShowLottie: boolean = false;
  leavePiPListener: any;

  addPiPEventListener(videoElement: HTMLVideoElement): void {
    this.leavePiPListener = () => {
      this.isPiPActive = false;
      if (this.aspect_ratio === '1' && this.aspectRatioBefore == '2') {
        let stCS = setTimeout(() => {
          this.aspect_ratio = '2';
          this.cdr.detectChanges();
          clearTimeout(stCS);
        }, 100); // Ajuste o tempo conforme necessáriof enterpictureinpicture

      }
    };

    videoElement.addEventListener('leavepictureinpicture', this.leavePiPListener);
  }
  enterPiPListener: any;
  allVideoElements: any[] = [];
  arrayPlayers: any[] = [];
  @ViewChild('thumbnailPreview') thumbnailPreview!: ElementRef;
  @ViewChild('timeDisplay') timeDisplay!: ElementRef;
  private touchMoveListener: any;
  private touchEndListener: any;
  private mouseMoveListener: any;
  private mouseLeaveListener: any;
  private transitionEndListener: any;


  setupEventListeners() {
    const wrapper = this.progressContainerWrapper?.nativeElement;
    if (wrapper) {
      wrapper.addEventListener('pointerdown', this.onDragStart.bind(this));
      wrapper.addEventListener('touchstart', this.onDragStart.bind(this), { passive: true });
    }
  }

  resizeObserver?: ResizeObserver;
  private cleanupResizeObserver() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
      this.resizeObserver = undefined;
    }
  }
  initialWidth?: number = 0;
  setupResizeObserver(): void {
    this.cdr.detectChanges();

    // Verificar se existe um gridContainer válido
    const gridContainer = this.gridContainers?.first;

    if (gridContainer && gridContainer.nativeElement) {
      // Obter a largura atual do gridContainer e ajustar as colunas
      this.initialWidth = gridContainer.nativeElement.offsetWidth;
      this.itemsToShow = this.adjustGridColumn(this.initialWidth, gridContainer);
    }
  }
  itemsToShow: number = 3;
  combinedStylesBar() {
    return {
      'opacity': this.progressBarOpacity,
    };
  }
  adjustBottom3: number = 0;
  ml: number = 20;
  mc: number = 65;
  mr: number = 15;

  top1: number = 9;
  top2: number = 8; // alertas
  center: number = 9.5;
  bottom0: number = 5; // description
  bottom1: number = 6; // custom
  bottom2: number = 15; // produtos
  bottom3: number = 35; //swiper links

  gridTemplateColumns?: string;
  lastGridTemplateColumns?: string | undefined;
  adjustGridColumn(width: number, gridContainer: ElementRef<HTMLDivElement>) {
    const totalHeightPx = window.innerHeight * ((100 - this.top1) / 100);
    const fixedHeightPx = this.convertRemToPixels(28);
    const availableHeightPx = totalHeightPx - fixedHeightPx;
    const itemHeightPx = this.convertRemToPixels(4.8);
    let itemsToShow = Math.floor(availableHeightPx / itemHeightPx);
    if (itemsToShow < 0) {
      itemsToShow = 0;
    }

    return itemsToShow;
  }
  sum100(): void {
    const { center, gridTemplateRows, sumTotal } = this.styleService.calculateGridTemplateRows(this.top1, this.top2, this.center, this.bottom0, this.bottom1, this.bottom2, this.bottom3);
    this.center = center;
    this.gridTemplateRows = gridTemplateRows;
    this.sumTotal = sumTotal;
  }
  calculateMaxWidth(containerWidth: number | undefined): void {
    let maxWidth: string;
    if (containerWidth) {
      if (containerWidth < 600) {
        maxWidth = '75%';
      } else if (containerWidth < 960) {
        maxWidth = '50%';
      } else if (containerWidth < 1280) {
        maxWidth = '30%';
      } else {
        maxWidth = '25%';
      }
      const currentWidth = getComputedStyle(document.documentElement).getPropertyValue('--swiper-slide-max-width');
      if (currentWidth !== maxWidth) {
        document.documentElement.style.setProperty('--swiper-slide-max-width', maxWidth);
      }
    }



  }

  getActionMenu(): MenuItem[] {
    const actionMenu: MenuItem[] = [];

    // Adicionar o item de Modo Teatro de acordo com as regras definidas
    if (this.hasTheaterMode && !this.isInTheaterMode) {
      actionMenu.push({
        label: 'Abrir Modo Teatro',
        icon: 'bi bi-aspect-ratio',
        command: () => this.modoTeatro()
      });
    } else if (this.hasTheaterMode && this.isInTheaterMode) {
      actionMenu.push({
        label: 'Sair do Modo Teatro',
        icon: 'bi bi-aspect-ratio-fill',
        command: () => this.sairModoTeatro()
      });
    }

    // Adicionar o item de PiP
    actionMenu.push({
      label: 'PiP',
      icon: this.isPiPActive ? 'bi bi-pip-fill' : 'bi bi-pip',
      command: () => this.togglePiP()
    });

    return actionMenu;
  }

  private convertRemToPixels(rem: number): number {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
  }

  hasProducsts: boolean = false;

  totalBuffered: number = 0;
  totalPause: number = 0;
  totalForwards: number = 0;
  totalRewinds: number = 0;
  currentProgress: number = 0;

  convertTimeToSeconds(timeStr: any) {
    const regex = /(\d+)(h|m|s)/g;
    let seconds = 0;
    let m;
    while ((m = regex.exec(timeStr)) !== null) {
      if (m[2] === 'h') {
        seconds += parseInt(m[1]) * 3600;
      } else if (m[2] === 'm') {
        seconds += parseInt(m[1]) * 60;
      } else if (m[2] === 's') {
        seconds += parseInt(m[1]);
      }
    }
    return seconds;
  }



  watchedIntervals: WatchedInterval[] = [];
  lastCurrentTime = 0; // Track last current time to handle seeking
  firstPlay: boolean = false;
  firstPlayResetDone: boolean = false;
  lastSeekTime = 0;
  //isDragging: boolean = false;
  handleSeeking(player: any) {
    this.isSeeking = true;
    this.lastSeekTime = player.currentTime();
  }

  // Function to handle seeking end
  handleSeeked(player: any) {
    this.isSeeking = false;
    const newTime = player.currentTime();
    this.lastSeekTime = newTime;  // Atualiza o último tempo após a movimentação
  }

  playerSessionId: string = "";

  dynamicHeight: string = 'auto';
  showDesc: boolean = false;

  private destroy$ = new Subject<void>();
  private destroyNovo$ = new Subject<void>();
  private requestNewVideos$ = new Subject<void>();
  private muteState$ = new Subject<void>();
  private videoTimeSubject = new Subject<number>();

  private subscriptionsTime: Subscription[] = [];
  private videoTimeSubscription?: Subscription;
  lastSrc: string = "";
  retryIndex?: number;
  retryIndexes: { index: number, url: string }[] = [];
  array: number[] = [];
  getPlayerFromArray(index: number, isDuplicate: boolean): any {
    const playerObj = this.arrayPlayers.find(playerObj => playerObj.index === index && playerObj.isDuplicate === isDuplicate);
    if (playerObj) {
      return playerObj.player;
    }
  }

  menuOpen = false;
  toggleMenu(event: Event, menu: any): void {
    this.menuOpen = !this.menuOpen;
    menu.toggle(event);

    if (this.menuOpen) {
      this.cdr.detach(); // Desativar detecção de mudanças
    } else {
      this.cdr.reattach(); // Reativar detecção de mudanças
    }
  }
  // getMute
  async playActiveVideo() { // player.muted timeupdate
    console.log("playActiveVideo")
    if (this.videoElements && this.videoElements.length > 0) {
      const swiperInstance = this.swiperElement?.swiperRef;
      if (!swiperInstance) {
        return;
      }
      const hasValidItems = this.slicks.some(item => Object.keys(item).length > 0);
      if (!hasValidItems) {
        return;
      }

      // Ative apenas o vídeo do slide ativo
      const activeIndex = this.getActiveSlideIndex();

      // Obter o índice real, ignorando duplicatas
      const realIndex = this.getActiveSlideIndex();

      const slidesCount = swiperInstance.slides.length;
      const recalculatedIndex = (realIndex + slidesCount) % slidesCount;

      // Verifica se o slide ativo é um duplicado
      const activeSlide = swiperInstance.slides[swiperInstance.activeIndex];
      const isDuplicate = activeSlide.classList.contains('swiper-slide-duplicate');


      // Obter o player associado ao elemento de vídeo usando arrayPlayers
      let player = this.getPlayerFromArray(recalculatedIndex, false);
      this.cdr.detectChanges();
      //if (player.paused()) {
      //player.off('canplay');
      player.on('canplay', () => {
        console.log("canplay")
        // Obter o slide visível
        const visibleSlide = swiperInstance.el.querySelector('.swiper-slide-visible') as HTMLElement;

        if (!visibleSlide) {
          return;
        }
        const visibleIndexStr = visibleSlide.getAttribute('data-swiper-slide-index');
        if (!visibleIndexStr) {
          return;
        }
        const realIndex = this.getActiveSlideIndex();
        const visibleIndex = parseInt(visibleIndexStr, 10);
        if (realIndex == visibleIndex) {
          if (player.paused()) {
            try {
              if (!this.isPaused) {
                player.play()?.then(() => { });
              }

            } catch (error) { }
          }
          /// exibir imagens em posições diferentes da ativa no swiper e exibir player na posição ativa
          const activeSlide = swiperInstance.slides[swiperInstance.activeIndex];
          if (activeSlide) {
            const poster = activeSlide.querySelector('.ss-l-poster') as HTMLElement;
            if (poster) {
              poster.classList.add('ss-l-hidden');
            }
            const video = activeSlide.querySelector('.ss-l-video') as HTMLElement;
            if (video) {
              video.classList.remove('ss-l-hidden');
            }
          }
        }
      });



      ///////////////////////////////////////
      if (!player) {
        return;
      }
      this.cleanupPlayerEventListeners(player);
      this.firstPlay = false;
      this.firstPlayResetDone = false;
      this.totalBuffered = 0;
      this.totalForwards = 0;
      this.totalRewinds = 0;
      this.watchedIntervals = [];
      this.isSeeking = false;
      this.lastCurrentTime = 0;
      /////////////////////////
      player.on('playing', () => {
        this.playVideoAtual();
      });


      if (this.videoTimeSubscription) {
        this.videoTimeSubscription.unsubscribe();
      }
      ////////////////////////
      player.ready(() => {

        let timeoutId;
        player.off('play');
        player.on('play', () => {
          player.muted(this.videoMuteService.getMuteState());
          this.slickInPlay = this.virtualIndex;

          const currentActiveIndex = this.getActiveSlideIndex();
          if (realIndex === currentActiveIndex) {
            if (!this.firstPlayResetDone) {
              this.totalPause = 0;
              this.firstPlayResetDone = true;
            }
          }




        });

        //player.off('ended');
        let lastProgressPercent = 0;
        let pitchEventTriggered = false;
        player.off('timeupdate');
        player.on('timeupdate', () => {
          this.player = player;
          this.lastCurrentTime = player.currentTime();
          this.handleTimeUpdate(player);
          const currentActiveIndex = this.getActiveSlideIndex();
          if (realIndex === currentActiveIndex) {
            const currentTime = player.currentTime();
            const duration = player.duration();
            this.currentProgress = (currentTime / duration) * 100;

            if (typeof duration === 'number') {
              const progressPercent = (currentTime / duration) * 100;
              if (progressPercent - lastProgressPercent >= 5 || progressPercent < lastProgressPercent) {
                lastProgressPercent = progressPercent;
                this.totalBuffered = this.videoPlayerService.calculateBufferedData(player);
                this.printWatchedTimeAndBuffered();
              }
            }

            //this.videoTimeSubject.next(currentTime);
            this.stateManagementService.updateVideoTime(currentTime);

            if (this.slicks && this.slicks[this.virtualIndex] && this.slicks[this.virtualIndex].extra && this.slicks[this.virtualIndex].extra.player) {
              let time_to_pitch_value = this.slicks[this.virtualIndex].extra.player.time_to_pitch_value;
              let targetTime = this.convertTimeToSeconds(time_to_pitch_value);

              // Verificar se currentTime está próximo de targetTime, dentro de uma margem de 0.5 segundos
              if (time_to_pitch_value !== "00h00m00s" && (Math.abs(currentTime - targetTime) < 0.5)) {
                if (!pitchEventTriggered) {
                  this.pitchTimeEvent();
                  pitchEventTriggered = true;
                }
              }
            }
            if (typeof currentTime === 'number') {
              this.zone.run(() => {
                const swiperInstance = this.swiperElement?.swiperRef;
                const activeIndex = swiperInstance?.activeIndex;
                const realIndex = swiperInstance?.realIndex;

                // Verifica se o slide atual é duplicado
                const activeSlide = swiperInstance.slides[activeIndex];
                const isDuplicateSlide = activeSlide.classList.contains('swiper-slide-duplicate');
                if (!this.isDragging) {
                  this.tempoAtual = this.formatTimeBar(currentTime);   // Atualiza a variável diretamente
                  this.durationTotal = this.formatTime(duration);
                  this.updateProgressBar();
                }

                this.cdr.detectChanges();
              });
            }
          }

        });
        // // Aplicar throttleTime no Subject para limitar a frequência de chamadas
        // this.videoTimeSubject.pipe(
        //   throttleTime(1000), // Limitar a chamada do updateVideoTime para 1 segundo
        //   takeUntil(this.destroy$)
        // ).subscribe((currentTime) => {

        // });
        player.on('tracking:firstplay', (e: any, data: any) => {

          if (!this.firstPlay) {
            this.firstPlay = true;

            this.firstPlayEvent();
          }
        })

        player.on('tracking:seek', (e: any, data: any) => {
          if (player.currentTime() > data.seekTo) {
            this.totalRewinds++;
          } else {
            this.totalForwards++;
          }
        });

        player.on('tracking:pause', (e: any, data: any) => {
          this.totalPause = data.pauseCount;
        });

        player.on('seeking', (e: any, data: any) => {
          this.handleSeeking(player)
        });
        player.on('seeked', (e: any, data: any) => {
          this.handleSeeked(player)
        });


        player.on('ended', () => {
          this.printWatchedTimeAndBuffered();

          this.watchedIntervals = [];
          this.isSeeking = false;
          this.lastCurrentTime = 0;

          if (player.readyState() >= 3) {
            //this.player.pause();
            this.totalPause = 0;
            let stds = setTimeout(() => player.play(), 10);
            clearTimeout(stds);
          }
        });

        // Event listeners
        const sub1 = fromEvent(player, 'waiting').pipe(takeUntil(this.destroy$)).subscribe(() => this.handlePlayerWaiting(player));
        const sub2 = fromEvent(player, 'playing').pipe(takeUntil(this.destroy$)).subscribe(() => this.handlePlayerPlaying(player));
        const sub3 = fromEvent(player, 'error').pipe(takeUntil(this.destroy$)).subscribe(() => this.handlePlayerError(player));
        const sub4 = fromEvent(player, 'ended').pipe(takeUntil(this.destroy$)).subscribe(() => this.handlePlayerEnded(player));
        const sub5 = fromEvent(player, 'seeking').pipe(takeUntil(this.destroy$)).subscribe(() => this.handleSeeking(player));
        const sub6 = fromEvent(player, 'seeked').pipe(takeUntil(this.destroy$)).subscribe(() => this.handleSeeked(player));
      });
    }
  }
  private handlePlayerEnded(player: any) {
  }
  private handlePlayerError(player: any) {
  }
  private handlePlayerPlaying(player: any) {
  }
  private handlePlayerWaiting(player: any) {
  }

  private playerSubscriptions: Subscription[] = [];
  private cleanupPlayerEventListeners(player: any) {
    //player.off('play');
    player.off('timeupdate');
    player.off('waiting');
    player.off('playing');
    player.off('error');
    player.off('ended');
    player.off('seeking');
    player.off('seeked');
    player.off('tracking:firstplay');
    player.off('tracking:seek');
    player.off('tracking:pause');
    player.off('tracking:buffered');
    player.off('tracking:performance');
  }

  handleTimeUpdate(player: any) {
    const currentTime = player.currentTime(); // Get current time in seconds

    // Only store intervals when not seeking, player is playing, and interval hasn't been stored
    if (!this.isSeeking && player.paused() === false) {
      const existingIndex = this.watchedIntervals.findIndex(interval => {
        return currentTime >= interval.start && currentTime <= interval.end;
      });

      if (existingIndex === -1) {
        this.watchedIntervals.push({ start: currentTime, end: currentTime });
      }
    }
  }


  printWatchedTimeAndBuffered(): void {
    const currentSlick = this.slicks[this.virtualIndex];
    if (!currentSlick || !currentSlick.extra || !currentSlick.extra.player || !currentSlick.content) {
      return;
    }
    const time_to_pitch_value = currentSlick.extra.player.time_to_pitch_value;
    const uid = currentSlick.content.uid;
    this.pMetaStore.sendPlayerProgressEvent(this.currentProgress, this.videoPlayerService.calculateWatchedDuration(), this.totalBuffered, this.totalPause, this.totalForwards, this.totalRewinds, uid, time_to_pitch_value
    );
  }

  updateSwiper(): void {
    if (this.swiperElement && this.swiperElement.swiperRef) {
      this.swiperElement.swiperRef.update();
    }
  }
  getVideoSrc(src: string): string {
    const newSrc = `${src.split('?')[0]}?ngsw-bypass=true`;
    return newSrc;
  }
  ctasTypesFixAndObs: boolean = false;
  swipes: number = 0;
  calcSlides?: number;

  // public verificarToast(): void {
  //   this.updateVerificationService.verificarToast(
  //     this.virtualIndex,
  //     this.loaded_slides,
  //     this.isRequestingSlides,
  //     this.openToast.bind(this),
  //     this.startMonitoringVirtualIndex.bind(this),
  //     (display: boolean) => { this.modalIsDisplayed = display; },
  //     (progress: number) => { this.progress = progress; },
  //     (interval: any) => { this.progressInterval = interval; } // Callback para atualizar `progressInterval`
  //   );
  // }
  async verificarToast(): Promise<void> {
    if (this.virtualIndex != 1) {
      this.calcSlides = this.loaded_slides - this.virtualIndex - 1;
    }
    if (this.calcSlides == 0) {
      this.startMonitoringVirtualIndex();
    } else {
      this.modalIsDisplayed = false;
    }
  }

  async verificarLoadSlides(): Promise<void> {
    if (this.virtualIndex >= (this.loaded_slides - 2) && this.directionMove == 'next' && !this.finishFindFiles && !this.isRequestingSlides) {
      this.isRequestingSlides = true;
      this.loadMoreSlides();
    }
  }
  trackByFn(index: number, item: any): number {
    return index; // ou alguma propriedade única do item
  }
  private checkAndLoadMoreSlides(): void {
    const shouldLoadMore = this.updateVerificationService.verificarLoadSlides(
      this.virtualIndex,
      this.loaded_slides,
      this.directionMove,
      this.finishFindFiles,
      this.isRequestingSlides
    );

    if (shouldLoadMore) {
      this.isRequestingSlides = true; // Atualiza o estado no componente
      this.loadMoreSlides(); // Chama `loadMoreSlides` diretamente no componente
    }
  }

  monitorInterval: any;
  public isRequestingSlides: boolean = false;
  public showLoadingToast: boolean = false;
  finishFindFiles: boolean = false;
  @Input() totalAssets: number = 0;
  private loadMoreSlides() {
    this.page += 1;

    // Verificar se já atingimos o número total de slides
    if (this.slicks.length == this.totalAssets && this.totalAssets != 0) {
      this.finishFindFiles = true;
      this.isRequestingSlides = false;

      const swiperInstance = this.swiperElement?.swiperRef;
      if (swiperInstance) {
        swiperInstance.allowSlideNext = true;
        swiperInstance.allowSlidePrev = true;
        swiperInstance.allowTouchMove = true; // swiper.allowTouchMove = true;
      }
      this.closeToast();
      return; // Não faz mais requisições
    }

    const limit = 3;
    // verificar se existe widget_id e alterar request
    if (this.widget_id != null) {
      this.assetService.getAssetsFromWidgetsPublic(this.widget_id, limit, 'shorts', this.page).pipe(takeUntil(this.destroy$)).subscribe({
        next: (res: AssetsResponse) => {
          const newSlicks = res.assets.flatMap(asset => asset.items).map(slick => {
            const cleanSrc = slick.media.src.includes('?') ? slick.media.src.split('?')[0] : slick.media.src;

            const videoSrc = cleanSrc;
            // new CloudinaryVideo(cleanSrc, { cloudName: 'slicksell' })
            //   .resize(scale().width(1000))  // Ajusta a largura do vídeo
            //   .delivery(quality('auto'))    // Ajusta automaticamente a qualidade
            //   .delivery(format(auto()))     // Formato ajustado automaticamente
            //   .setDeliveryType('fetch')     // Buscar o vídeo de uma URL externa
            //   .toURL();
            // Atualiza a src no slick
            return {
              ...slick,
              media: {
                ...slick.media,
                src: videoSrc
              }
            };
          });
          this.slicks = [...this.slicks, ...newSlicks];
          this.currentVideoIndex = this.getActiveSlideIndex();
          //this.isFinishTranstion = this.slicks.map((_, index) => index === this.currentVideoIndex ? false : true);

          this.loaded_slides = (this.loaded_slides) + newSlicks.length;
          this.isRequestingSlides = false;

          this.totalAssets = res.totalAssets;

          if (newSlicks.length == 0) {
            this.finishFindFiles = true;
          }

          newSlicks.forEach((slick, index) => {
            const newIndex = this.slicks.length - newSlicks.length + index;
          });
          const swiperInstance = this.swiperElement?.swiperRef;
          if (swiperInstance) {
            swiperInstance.allowSlideNext = true;
            swiperInstance.allowTouchMove = true;
            swiperInstance.allowSlidePrev = true;
          }
          if (this.modalIsDisplayed) {
            this.changeSrcs();
            this.modalIsDisplayed = false;
          }
          this.closeToast();
        },
        error: (err) => { }
      });
    } else {
      this.assetService.getAssetsPublic(limit, 'shorts', this.page, this.seed, this.slick_id).pipe(takeUntil(this.requestNewVideos$)).subscribe({
        next: (res) => {
          const newSlicks = res.assets.flatMap(asset => asset.items).map(slick => {
            const cleanSrc = slick.media.src.includes('?') ? slick.media.src.split('?')[0] : slick.media.src;

            const videoSrc = cleanSrc;
            // new CloudinaryVideo(cleanSrc, { cloudName: 'slicksell' })
            //   .resize(scale().width(1000))  // Ajusta a largura do vídeo
            //   .delivery(quality('auto'))    // Ajusta automaticamente a qualidade
            //   .delivery(format(auto()))     // Formato ajustado automaticamente
            //   .setDeliveryType('fetch')     // Buscar o vídeo de uma URL externa
            //   .toURL();
            // Atualiza a src no slick
            return {
              ...slick,
              media: {
                ...slick.media,
                src: videoSrc
              }
            };
          });
          this.slicks = [...this.slicks, ...newSlicks];
          this.currentVideoIndex = this.getActiveSlideIndex();
          //this.isFinishTranstion = this.slicks.map((_, index) => index === this.currentVideoIndex ? false : true);

          this.loaded_slides = (this.loaded_slides) + newSlicks.length;
          this.isRequestingSlides = false;

          this.totalAssets = res.totalAssets;

          if (newSlicks.length == 0) {
            this.finishFindFiles = true;
          }

          newSlicks.forEach((slick, index) => {
            const newIndex = this.slicks.length - newSlicks.length + index;
          });
          const swiperInstance = this.swiperElement?.swiperRef;
          if (swiperInstance) {
            swiperInstance.allowSlideNext = true;
            swiperInstance.allowTouchMove = true;
            swiperInstance.allowSlidePrev = true;
          }
          if (this.modalIsDisplayed) {
            this.changeSrcs();
            this.modalIsDisplayed = false;
          }
          this.closeToast();
        },
        error: (err) => {
          if (err.status == 404) {
            this.finishFindFiles = true;
          }
        }
      });
    }
  }
  openToast() {
    this.messageService.clear('confirm');
    this.messageService.add({
      key: 'confirm',
      severity: 'info',
      summary: 'Carregando novos vídeos...',
      detail: '',
      sticky: true, // Deixa o toast visível até ser removido manualmente
    });
    this.startProgressLoop();
  }
  private progressInterval: any;
  startProgressLoop() {
    const totalTime = 5000; // Tempo total da simulação em milissegundos (5 segundos)
    const incrementInterval = 100; // Intervalo de incremento em milissegundos (0.1 segundo)
    const totalIncrements = totalTime / incrementInterval; // Quantidade de incrementos até 100%
    const progressIncrement = 100 / totalIncrements; // Quanto progresso adicionar por incremento
    this.progress = 0; // Reseta o progresso
    this.progressInterval = setInterval(() => {
      this.progress += progressIncrement;
      if (this.progress >= 100) {
        this.progress = 100; // Garante que o valor não passe de 100%
        clearInterval(this.progressInterval); // Para o loop de progresso
      }
    }, incrementInterval);
  }
  closeToast() {
    const swiperInstance = this.swiperElement?.swiperRef;
    if (swiperInstance) {
      swiperInstance.allowSlideNext = true;
      swiperInstance.allowTouchMove = true;
      swiperInstance.allowSlidePrev = true;
    }
    this.messageService.clear('confirm');
    this.stopProgressLoop();
  }

  stopProgressLoop() {
    // Para o loop de progresso quando o toast é fechado
    if (this.progressInterval) {
      clearInterval(this.progressInterval);
      this.progressInterval = null;
    }
  }
  private initializeNewPlayers(newSlicksCount: number) {
    const startIndex = this.slicks.length - newSlicksCount;
    const endIndex = this.slicks.length;

    for (let index = startIndex; index < endIndex; index++) {
      const videoElement = this.videoElements?.toArray()[index];
      if (videoElement && !this.videoPlayers[index]) {
        const player = videojs(videoElement.nativeElement, {
          controls: false,
          autoplay: false,
          preload: 'auto'
        });
        this.videoPlayers[index] = player;
        player.pause();
      }
    }

    // Aplicar estado de mute global aos novos vídeos após inicialização
    this.setMuteState(this.videoMuteService.getMuteState());

  }

  setMuteState(isMuted: boolean) {
    this.arrayPlayers.forEach((playerObj, index) => {
      const player = playerObj.player; // Acesse a propriedade `player` dentro do objeto
      player.muted(isMuted);
      if (!isMuted) {
        player.volume(this.lastVolume);
      } else {
        player.volume(0);
      }
    });
  }

  transformAndSaveData(data: any) {
    // Estrutura base
    let pixels: { facebook: Pixel[] } = {
      facebook: []
    };

    // Itera sobre os dados e transforma a estrutura
    data.forEach((item: any) => {
      let viewEvents = item.value_2 ? item.value_2.view_events : null;
      let conversionEvents = item.value_3 ? item.value_3.conversion_events : null;

      // Verifica se os eventos não são nulos e não são objetos vazios
      if (viewEvents && Object.keys(viewEvents).length !== 0 || conversionEvents && Object.keys(conversionEvents).length !== 0) {
        let pixel: Pixel = {
          id: item.pixel_id || '',
          category: 'facebook',
          events: {
            view: viewEvents,
            conversion: conversionEvents
          }
        };
        pixels.facebook.push(pixel);
      }
    });

    // Salva no localStorage
    localStorage.setItem('pixels', JSON.stringify(pixels));
  }

  private waitForSelectedStoreId(): Promise<void> {
    return new Promise((resolve) => {
      const checkInterval = setInterval(() => {
        const selectedStoreId = localStorage.getItem('SelectedStoreId');
        if (selectedStoreId) {
          const storedInfoJSON = localStorage.getItem('store');
          if (storedInfoJSON) {
            // Converte a string JSON de volta para um objeto
            const storedInfo = JSON.parse(storedInfoJSON);

            // Acesse os dados do objeto
            const storeId = storedInfo.SelectedStoreId;
            const img = storedInfo.store_logo;
            // new CloudinaryImage(storedInfo.store_logo, { cloudName: 'slicksell' })
            //   .resize(scale().width(500))
            //   .delivery(quality('auto'))
            //   .delivery(format(auto()))
            //   .setDeliveryType("fetch").toURL();
            this.logoStore = img;

          }
          clearInterval(checkInterval);
          resolve();
        }
      }, 100);
    });
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }
  alertsAppComponentsTmp?: any;
  private player: any;
  assetUid?: string;
  customerCrypto?: any;

  initializedIndices: Set<number> = new Set();

  shouMuteImg: boolean = false;
  showLottiePlayer: boolean = false;
  updatePlayPauseColor(value: string | object): void {
    const color = typeof value === 'string' ? value : ''; // Use um valor padrão ou faça uma conversão conforme necessário
    this.stateManagementService.updatePlayerControlsColors(this.playPauseColor, this.progressBarColor);
  }
  updateProgressBarColor(value: string | object): void {
    const color = typeof value === 'string' ? value : ''; // Use um valor padrão ou faça uma conversão conforme necessário
    this.stateManagementService.updatePlayerControlsColors(this.playPauseColor, this.progressBarColor);
  }
  zeroVideos: boolean = false;
  public slicks$: Subject<any[]> = new Subject<any[]>();



  @ViewChild('bottomDiv') bottomDiv?: ElementRef<HTMLDivElement>;
  private resizeObserverProgressContainerWrapper?: ResizeObserver;
  @ViewChildren('bottomDiv') bottomDivs!: QueryList<ElementRef<HTMLDivElement>>;

  loaded_slides: number = 0;
  session_id: string = '';

  formatTimeBar(seconds: number): string {
    const date = new Date(0);
    date.setSeconds(seconds);
    return date.toISOString().substr(11, 8);
  }
  chatId: string = '';

  total_swipes: number = 0;
  lastSlickUid?: string;

  private connectToRoomAuth(index: number): void {
    // this.chatId = this.slicks[index].content.uid;
    // this.chatWebSocketService.joinRoom(this.chatId, this.username, this.user_chat);
    // let customer_crypto = localStorage.getItem('customer_crypto');

    // // Verifica se o objeto existe
    // if (customer_crypto) {
    //   // Converte o objeto JSON para um objeto JavaScript
    //   let customer_crypto_obj = JSON.parse(customer_crypto);

    //   // Atualiza as propriedades desejadas
    //   customer_crypto_obj.is_logged_in = true;
    //   customer_crypto_obj.has_user_name = true;

    //   // Converte o objeto JavaScript de volta para uma string JSON
    //   localStorage.setItem('customer_crypto', JSON.stringify(customer_crypto_obj));

    //   // Atualiza a propriedade this.customerCrypto no componente
    //   this.customerCrypto = customer_crypto_obj;
    // }

  }
  private connectToRoom(index: number): void {
    // this.chatId = this.slicks[index].content.uid;
    // this.chatWebSocketService.joinRoom(this.chatId, this.username, this.user_chat);
    // let customer_crypto = localStorage.getItem('customer_crypto');

    // // Verifica se o objeto existe
    // if (customer_crypto) {
    //   // Converte o objeto JSON para um objeto JavaScript
    //   let customer_crypto_obj = JSON.parse(customer_crypto);

    //   // Atualiza as propriedades desejadas
    //   customer_crypto_obj.is_logged_in = true;
    //   customer_crypto_obj.has_user_name = true;

    //   // Converte o objeto JavaScript de volta para uma string JSON
    //   localStorage.setItem('customer_crypto', JSON.stringify(customer_crypto_obj));

    //   // Atualiza a propriedade this.customerCrypto no componente
    //   this.customerCrypto = customer_crypto_obj;
    // }
    // //this.chatWebSocketService.getInitialMessages(this.chatId);
    // this.chatWebSocketService.receiveMessages().pipe(takeUntil(this.destroy$)).subscribe(message => {
    // });

    // // Subscrever para receber o número de usuários e atualizar a propriedade
    // const numUsersSub = this.chatWebSocketService.getNumUsers(this.chatId).pipe(takeUntil(this.destroy$)).subscribe(numUsers => {
    //   this.currentNumUsers = numUsers;
    // });
    // this.subscriptions.add(numUsersSub);
  }

  setCookie(name: string, value: string, days: number) {
    const expires = new Date(Date.now() + days * 864e5).toUTCString();
    document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires}; path=/`;
  }

  isFinishTranstion: boolean[] = [];
  currentNumUsers: number = 0;
  private subscriptions = new Subscription();

  private connectToChat(chatId: string): void {
    this.chatWebSocketService.getNumUsers(chatId).pipe(takeUntil(this.destroy$)).subscribe(numUsers => {
      this.currentNumUsers = numUsers;
    });
  }

  alertsTabs: AlertsTab[] = [
    {
      id: 0,
      header: 'Alerta 1',
      text: '',
      font_color: '#FFFFFF',
      background: '#000000',
      define_interval: false,
      show_item: false,
      interval_time: { start: "00:00:00", end: "00:00:00" },
      slider_time_range: [0, 0],
      videoUid: '',
    }
  ];
  configSwiperThird: SwiperOptions = { // swiper de links swiperConfig
    slidesPerView: 'auto',
    loop: false,
    spaceBetween: 10,
    lazy: {
      loadPrevNext: true, // Carrega o slide anterior e o próximo
      loadPrevNextAmount: 1, // Pode ajustar para carregar mais slides além dos visíveis
    },
    pagination: false,
    navigation: false,
  };
  configSwiper: SwiperOptions = { // swiper de alertas
    loop: false,
    slidesPerView: 'auto',
    spaceBetween: 10,
    lazy: {
      loadPrevNext: true, // Carrega o slide anterior e o próximo
      loadPrevNextAmount: 1, // Pode ajustar para carregar mais slides além dos visíveis
    },
    keyboard: {
      enabled: true,
    },
    pagination: false,
    navigation: false,
  };

  timeToSeconds(time: string): number {
    const [hours, minutes, seconds] = time.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  }
  showCTAs: boolean = true;
  typeLive: boolean = false;

  sliderMaxValue: number = 0;



  hasVisibleButton: boolean = false;
  hasVisibleAlerts: boolean = false;
  hasVisibleProducts: boolean = false;
  hasVisibleLinksSwiper: boolean = false;
  hasVisibleDesc: boolean = false;
  hiddenAlerts: Set<number> = new Set();

  showSwiper: boolean = true; // Controla a visibilidade do swiper
  //numberOfAlerts: number = 0;
  numberOfAlerts: number[] = [];
  screenAlertsBtn: boolean[] = [];
  subscription: Subscription | null = null;
  toggleSwiperVisibility(): void {
    // Alterna a visibilidade do Swiper
    this.showSwiper = !this.showSwiper;

    // Se o Swiper estiver sendo ocultado, atualize imediatamente a contagem de alertas
    if (!this.showSwiper) {
      // Obter o índice real ativo no Swiper
      const activeIndex = this.realActiveIndex;

      // Verificar se o índice ativo é válido
      if (activeIndex >= 0 && activeIndex < this.alertsPerSlide.length) {
        // Atualizar o número de alertas do slide ativo
        this.numberOfAlerts[activeIndex] = this.alertsPerSlide[activeIndex].length;
      }
    }
  }

  showAlerts(): void {
    this.showSwiper = true;
  }


  private applySwiperStyles2(container: HTMLElement, containerWidth: number, swiperClass: string): void {
    const swiperSlides = container.querySelectorAll(`.${swiperClass} .swiper-slide`) as NodeListOf<HTMLElement>;
    if (!swiperSlides) {
      return;
    }

    swiperSlides.forEach(swiperSlide => {
      if (containerWidth < 600) {
        swiperSlide.style.maxWidth = '75%';
      } else if (containerWidth < 960) {
        swiperSlide.style.maxWidth = '50%';
      } else if (containerWidth < 1280) {
        swiperSlide.style.maxWidth = '30%';
      } else {
        swiperSlide.style.maxWidth = '25%';
      }
    });
  }

  convertAccordionTimeToSliderValue(start: string, end: string, sliderMaxValue: number): [number, number] {
    const convertTimeStringToSliderValue = (timeString: string): number => {
      const [hours, minutes, seconds] = timeString.split(':').map(Number);
      // Converte o tempo para segundos totais
      const totalTimeInSeconds = (hours * 3600) + (minutes * 60) + seconds;
      // Ajusta o valor para o intervalo do slider
      return totalTimeInSeconds / sliderMaxValue; // Proporção do sliderMaxValue
    };

    const startValue = convertTimeStringToSliderValue(start) * sliderMaxValue;
    const endValue = convertTimeStringToSliderValue(end) * sliderMaxValue;

    // Garante que os valores estejam dentro dos limites do slider
    return [startValue, endValue];
  }

  formatTime(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const sec = seconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${sec.toString().padStart(2, '0')}`;
  }
  trackById(index: any, item: any) {
    return item.id; // Supondo que cada 'alert' tem um 'id' único
  }
  accordionTabs: AccordionTab[] = [
    {
      header: 'Botão 1',
      button_text: 'Botão 1',
      redirect_url: '',
      show_item: false,
      font_color_custom: '#FFFFFF',
      bg_color_custom: '#000000',
      define_interval: false,
      interval_time: { start: "00:00:00", end: "00:00:00" },
      slider_time_range: [0, 0],
      videoUid: ''
    }
  ];
  customComponentsTmp?: any;
  // Definição do Subject para o botão visível
  visibleButton = new Subject<AccordionTab | null>();

  setupVisibleButton(directionMove: string | undefined): void {
    let targetIndex: number;
    let slideIndex: number = 0;

    if (directionMove === undefined && this.realActiveIndex === 0) {
      targetIndex = this.virtualIndex;

      // Atualizar o índice 0 e também o próximo (índice 1)
      [0, 1].forEach(index => {
        slideIndex = index;
        if (targetIndex >= 0 && targetIndex < this.slicks.length) {
          let i = index == 0 ? 0 : 1;
          const customComponents = this.slicks[index]?.content?.cta?.components?.custom || [];

          this.buttonsPerSlide[index] = customComponents
            .filter(component => component.show_item)
            .slice(0, 1)
            .map(component => ({
              id: component.id,
              header: component.title || 'Default Header',
              button_text: component.name || '',
              redirect_url: component.redirect_url || '',
              font_color_custom: component.font || '#FFFFFF',
              bg_color_custom: component.background || '#000000',
              define_interval: component.define_interval === 1,
              show_item: component.show_item === 1,
              interval_time: {
                start: component.time_from || '00:00:00',
                end: component.time_to || '00:00:00',
              },
              slider_time_range: this.convertAccordionTimeToSliderValue(
                component.time_from || '00:00:00',
                component.time_to || '00:00:00',
                this.sliderMaxValue
              ),
              videoUid: '',
            }));
        }
      });
    } else if (directionMove === 'next') {
      targetIndex = (this.virtualIndex + 1) % this.slicks.length;
      if (directionMove === 'next') {
        if (this.realActiveIndex === 0) slideIndex = 1;
        else if (this.realActiveIndex === 1) slideIndex = 2;
        else if (this.realActiveIndex === 2) slideIndex = 0;
      }

    } else if (directionMove === 'prev') {
      targetIndex = (this.virtualIndex - 1 + this.slicks.length) % this.slicks.length;
      if (directionMove === 'prev') {
        if (this.realActiveIndex === 0) slideIndex = 2;
        else if (this.realActiveIndex === 1) slideIndex = 0;
        else if (this.realActiveIndex === 2) slideIndex = 1;
      }
    } else {
      return;
    }

    if (targetIndex >= 0 && targetIndex < this.slicks.length && directionMove !== undefined) {
      const customComponents = this.slicks[targetIndex]?.content?.cta?.components?.custom || [];
      const slideUid = this.slicks[targetIndex]?.content?.uid || '';

      this.buttonsPerSlide[slideIndex] = customComponents
        .filter(component => component.show_item)
        .slice(0, 1)
        .map(component => ({
          id: component.id,
          header: component.title || 'Default Header',
          button_text: component.name || '',
          redirect_url: component.redirect_url || '',
          font_color_custom: component.font || '#FFFFFF',
          bg_color_custom: component.background || '#000000',
          define_interval: component.define_interval === 1,
          show_item: component.show_item === 1,
          interval_time: {
            start: component.time_from || '00:00:00',
            end: component.time_to || '00:00:00',
          },
          slider_time_range: this.convertAccordionTimeToSliderValue(
            component.time_from || '00:00:00',
            component.time_to || '00:00:00',
            this.sliderMaxValue
          ),
          videoUid: slideUid,
        }));
    }
  }


  updateButtonsPerSlide(realIndex: number, videoTime: number): void {
    const realPositionOffsets = [
      realIndex === 0 ? 0 : realIndex === 1 ? -1 : -2, // Posição 0
      realIndex === 0 ? 1 : realIndex === 1 ? 0 : -1,  // Posição 1
      realIndex === 0 ? 2 : realIndex === 1 ? 1 : 0    // Posição 2
    ];

    realPositionOffsets.forEach((offset, index) => {
      const adjustedIndex = this.virtualIndex + offset;

      // Verificar se o índice ajustado está dentro dos limites
      if (adjustedIndex >= 0 && adjustedIndex < this.slicks.length) {
        const customComponents = this.slicks[adjustedIndex]?.content?.cta?.components?.custom || [];
        const slideUid = this.slicks[adjustedIndex]?.content?.uid || '';

        // Verifica se todos os intervalos são inválidos
        const allIntervalsEmpty = customComponents.every(component => {
          const start = component.time_from || '00:00:00';
          const end = component.time_to || '00:00:00';
          return (
            component.show_item &&
            (start === '00:00:00' || start === null) &&
            (end === '00:00:00' || end === null)
          );
        });

        if (allIntervalsEmpty) {
          // Incluir todos os botões, independentemente do intervalo
          this.buttonsPerSlide[index] = customComponents.map(component => ({
            id: component.id || index,
            header: component.title || 'Default Header',
            button_text: component.name || '',
            redirect_url: component.redirect_url || '',
            font_color_custom: component.font || '#FFFFFF',
            bg_color_custom: component.background || '#000000',
            define_interval: component.define_interval === 1,
            show_item: component.show_item === 1,
            interval_time: {
              start: component.time_from || '00:00:00',
              end: component.time_to || '00:00:00'
            },
            slider_time_range: this.convertAccordionTimeToSliderValue(
              component.time_from || '00:00:00',
              component.time_to || '00:00:00',
              this.sliderMaxValue
            ),
            videoUid: slideUid
          }));
          return;
        } else {
          // Filtrar botões com intervalos válidos e mapear
          this.buttonsPerSlide[index] = customComponents
            .filter(component => {
              const startSeconds = this.timeToSeconds(component.time_from || '00:00:00');
              const endSeconds = this.timeToSeconds(component.time_to || '00:00:00');

              return (
                component.show_item &&
                ((videoTime >= startSeconds && videoTime <= endSeconds) ||
                  (startSeconds === 0 && endSeconds === 0))
              );
            })
            .map(component => ({
              id: component.id || index,
              header: component.title || 'Default Header',
              button_text: component.name || '',
              redirect_url: component.redirect_url || '',
              font_color_custom: component.font || '#FFFFFF',
              bg_color_custom: component.background || '#000000',
              define_interval: component.define_interval === 1,
              show_item: component.show_item === 1,
              interval_time: {
                start: component.time_from || '00:00:00',
                end: component.time_to || '00:00:00'
              },
              slider_time_range: this.convertAccordionTimeToSliderValue(
                component.time_from || '00:00:00',
                component.time_to || '00:00:00',
                this.sliderMaxValue
              ),
              videoUid: slideUid
            }));
        }
      } else {
        // Índice fora do limite, definir como array vazio
        this.buttonsPerSlide[index] = [];
      }
    });

  }




  visibleButtons: any = [];
  shouldShowButton(tab: AccordionTab, videoTime: number): boolean {
    if (tab.show_item !== true) {
      return false; // Filtra as abas que não têm show_item igual a 1 ou true
    }

    if (tab.define_interval === false) {
      return true;
    }

    const startSeconds = this.timeToSeconds(tab.interval_time?.start || '00:00:00');
    const endSeconds = this.timeToSeconds(tab.interval_time?.end || '00:00:00');
    return (videoTime >= startSeconds && videoTime <= endSeconds) || (startSeconds === 0 && endSeconds === 0);
  }
  @ViewChild('chatBoxComponent') chatBoxComponent?: ElementRef;
  @ViewChild('videoInner') videoInnerRef?: ElementRef;

  displayDialog: boolean = false;
  tempUrl: string = '';
  sidebarUrl: string = '';

  normalizeUrl(url: string): string {
    url = url.trim();
    if (!url.match(/^http[s]?:\/\//)) {
      url = 'https://' + url;
    }
    return url;
  }
  isSidebarVisible: boolean = false;

  toggleSidebar(url: string): void {
    this.sidebarUrl = 'https://' + url;
    this.isSidebarVisible = true;
  }

  // A função para abrir o link foi extraída para ser reutilizada.
  openLink(url: string) {
    window.open('https://' + url, '_blank');
  }
  //////////////////////////
  whatsappTabs: WhatsAppTab[] = [
    {
      id: undefined,
      header: 'Whatsapp 1',
      ddd: '',
      fone: '',
      define_interval: false,
      show_item: false,
      interval_time: { start: "00:00:00", end: "00:00:00" },
      slider_time_range: [0, 0],
      videoUid: ''
    }
  ];
  // Definição do Subject para o botão do WhatsApp visível
  visibleButtonWhatsApp = new Subject<WhatsAppTab | null>();

  whatsAppComponentsTmp?: any;

  showAllShortucutLinks: boolean = false;
  openSidebarCTALinks() {
    this.showAllShortucutLinks = !this.showAllShortucutLinks;
    this.cdr.detectChanges();
  }

  linksSelecteds: Links[] = [];
  additionalLinks$?: Observable<Links[]>;

  //confirmRedirect

  async cancelRedirect(): Promise<void> {
    this.displayDialog = false;
    this.displayDialogWhats = false;
    this.displayDialogCustom = false;
    await this.closePictureInPicture();
  }
  async closePictureInPicture(): Promise<void> {
    if (document.pictureInPictureElement) {
      try {
        await document.exitPictureInPicture();
        this.isPiPActive = false;
      } catch (error) {
      }
    }
  }
  navigateTo(url: string | undefined): void {
    if (!url) return;  // Verifica se URL é nula ou vazia

    if (url.startsWith('http')) {
      window.open(url, '_blank');
    } else {
      window.open('https://' + url, '_blank');
    }
  }

  buttonsPerSlide: any[] = []; // Botões visíveis por slide
  whatsAppButtonsPerSlide: any[] = [];

  setupVisibleButtonWhatsApp(directionMove: string | undefined): void {
    let targetIndex: number;
    let slideIndex: number = 0;

    if (directionMove === undefined && this.realActiveIndex === 0) {
      targetIndex = this.virtualIndex;

      // Atualizar o índice 0 e também o próximo (índice 1)
      [0, 1].forEach(index => {
        slideIndex = index;
        if (targetIndex >= 0 && targetIndex < this.slicks.length) {
          const whatsappTabs = this.slicks[index]?.content?.cta?.components?.whatsapp || [];

          this.whatsAppButtonsPerSlide[index] = whatsappTabs
            .filter(component => component.show_item)
            .slice(0, 1)
            .map(component => ({
              id: component.id,
              header: component.title || 'Default Header',
              ddd: component.ddd || '',
              fone: component.phone || '',
              show_item: component.show_item === 1,
              define_interval: component.define_interval === 1,
              interval_time: {
                start: component.time_from || '00:00:00',
                end: component.time_to || '00:00:00',
              },
              slider_time_range: this.convertAccordionTimeToSliderValue(
                component.time_from || '00:00:00',
                component.time_to || '00:00:00',
                this.sliderMaxValue
              ),
              videoUid: this.slicks[targetIndex]?.content?.uid || '',
            }));
        }
      });
    } else if (directionMove === 'next') {
      targetIndex = (this.virtualIndex + 1) % this.slicks.length;
      if (directionMove === 'next') {
        if (this.realActiveIndex === 0) slideIndex = 1;
        else if (this.realActiveIndex === 1) slideIndex = 2;
        else if (this.realActiveIndex === 2) slideIndex = 0;
      }

    } else if (directionMove === 'prev') {
      targetIndex = (this.virtualIndex - 1 + this.slicks.length) % this.slicks.length;
      if (directionMove === 'prev') {
        if (this.realActiveIndex === 0) slideIndex = 2;
        else if (this.realActiveIndex === 1) slideIndex = 0;
        else if (this.realActiveIndex === 2) slideIndex = 1;
      }
    } else {
      return;
    }

    if (targetIndex >= 0 && targetIndex < this.slicks.length && directionMove !== undefined) {
      const whatsappTabs = this.slicks[targetIndex]?.content?.cta?.components?.whatsapp || [];

      this.whatsAppButtonsPerSlide[slideIndex] = whatsappTabs
        .filter(component => component.show_item)
        .slice(0, 1)
        .map(component => ({
          id: component.id,
          header: component.title || 'Default Header',
          ddd: component.ddd || '',
          fone: component.phone || '',
          show_item: component.show_item === 1,
          define_interval: component.define_interval === 1,
          interval_time: {
            start: component.time_from || '00:00:00',
            end: component.time_to || '00:00:00',
          },
          slider_time_range: this.convertAccordionTimeToSliderValue(
            component.time_from || '00:00:00',
            component.time_to || '00:00:00',
            this.sliderMaxValue
          ),
          videoUid: this.slicks[targetIndex]?.content?.uid || '',
        }));
    }
  }


  updateWhatsAppButtonsPerSlide(realIndex: number, videoTime: number): void {
    const realPositionOffsets = [
      realIndex === 0 ? 0 : realIndex === 1 ? -1 : -2, // Posição 0
      realIndex === 0 ? 1 : realIndex === 1 ? 0 : -1,  // Posição 1
      realIndex === 0 ? 2 : realIndex === 1 ? 1 : 0    // Posição 2
    ];

    realPositionOffsets.forEach((offset, index) => {
      const adjustedIndex = this.virtualIndex + offset;

      // Verificar se o índice ajustado está dentro dos limites
      if (adjustedIndex >= 0 && adjustedIndex < this.slicks.length) {
        const whatsappComponents = this.slicks[adjustedIndex]?.content?.cta?.components?.whatsapp || [];

        // Converter `whatsappComponents` para o formato `WhatsAppTab`
        const whatsappTabs: WhatsAppTab[] = whatsappComponents.map((component: any) => ({
          id: component.id || index,
          header: component.title || 'Default Header',
          ddd: component.ddd || '',
          fone: component.phone || '',
          show_item: component.show_item === 1,
          define_interval: component.define_interval === 1,
          interval_time: {
            start: component.time_from || '00:00:00',
            end: component.time_to || '00:00:00'
          },
          slider_time_range: this.convertAccordionTimeToSliderValue(
            component.time_from || '00:00:00',
            component.time_to || '00:00:00',
            this.sliderMaxValue
          ),
          videoUid: this.slicks[adjustedIndex]?.content?.uid || ''
        }));

        // Verifica se todos os intervalos são inválidos
        const allIntervalsEmpty = whatsappTabs.every(tab => {
          const start = tab.interval_time?.start || '00:00:00';
          const end = tab.interval_time?.end || '00:00:00';
          return tab.show_item && (start === '00:00:00' || start === null) && (end === '00:00:00' || end === null);
        });

        if (allIntervalsEmpty) {
          // Se todos os intervalos são inválidos, inclui todos os botões
          this.whatsAppButtonsPerSlide[index] = whatsappTabs;
          return;
        } else {
          // Filtrar e mapear botões com intervalos válidos
          this.whatsAppButtonsPerSlide[index] = whatsappTabs
            .filter(tab => {
              const startSeconds = this.timeToSeconds(tab.interval_time?.start || '00:00:00');
              const endSeconds = this.timeToSeconds(tab.interval_time?.end || '00:00:00');

              return tab.show_item &&
                ((videoTime >= startSeconds && videoTime <= endSeconds) ||
                  (startSeconds === 0 && endSeconds === 0));
            });
        }
      } else {
        // Índice fora do limite, definir como array vazio
        this.whatsAppButtonsPerSlide[index] = [];
      }
    });

  }





  shouldShowButtonWhatsApp(tab: WhatsAppTab, videoTime: number): boolean {
    if (tab.define_interval === false) {
      return true;
    }

    const startSeconds = this.timeToSeconds(tab.interval_time?.start || '00:00:00');
    const endSeconds = this.timeToSeconds(tab.interval_time?.end || '00:00:00');
    return (videoTime >= startSeconds && videoTime <= endSeconds) || (startSeconds === 0 && endSeconds === 0);
  }
  isRealSlide(index: number): boolean {
    const swiperInstance = this.swiperElement?.swiperRef;

    if (!swiperInstance) return false;

    // Verifica se o índice corresponde ao índice ativo real (não duplicado)
    const isActiveRealSlide = swiperInstance.realIndex === index;

    // Garante que a posição não seja duplicada no DOM
    const slides = Array.from(swiperInstance.slides);
    const slide = slides[index] as HTMLElement;

    const isDuplicate = slide?.classList.contains('swiper-slide-duplicate');

    // Retorna true apenas se for o slide ativo real e não duplicado
    return isActiveRealSlide && !isDuplicate;
  }
  filteredAlerts = new Subject<AlertsTab[]>();
  activeSlideIndex$ = new BehaviorSubject<number>(0);
  alertsPerSlide: AlertsTab[][] = [];
  private alertsBySlide: { [slideUid: string]: any[] } = {};

  getActiveSlideIndex(): number {
    const swiperInstance = this.swiperElement?.swiperRef;
    return swiperInstance?.realIndex ?? 0;
  }

  // toggleSwiperVisibility
  setupFilteredAlerts(directionMove: string | undefined): void {
    let targetIndex: number;
    let slideIndex: number = 0;

    this.numberOfAlerts = new Array(this.slicks.length).fill(0);
    this.screenAlertsBtn = new Array(this.slicks.length).fill(true);

    if (directionMove === undefined && this.realActiveIndex === 0) {
      targetIndex = this.virtualIndex;

      slideIndex = 0;
      if (targetIndex >= 0 && targetIndex < this.slicks.length) {
        const alerts = this.slicks[targetIndex]?.content?.cta?.components?.alerts || [];
        this.alertsPerSlide[slideIndex] = alerts
          .filter(alert => alert.show_item)
          .map(alert => this.mapAlert(alert));

        this.numberOfAlerts[slideIndex] = this.alertsPerSlide[slideIndex].length;
        this.screenAlertsBtn[slideIndex] = this.numberOfAlerts[slideIndex] > 0;
      }

      slideIndex = 1;
      if (targetIndex + 1 >= 0 && targetIndex + 1 < this.slicks.length) {
        const alerts = this.slicks[targetIndex + 1]?.content?.cta?.components?.alerts || [];
        this.alertsPerSlide[slideIndex] = alerts
          .filter(alert => alert.show_item)
          .map(alert => {
            return this.mapAlert(alert);
          });

        this.numberOfAlerts[slideIndex] = this.alertsPerSlide[slideIndex].length;
        this.screenAlertsBtn[slideIndex] = this.numberOfAlerts[slideIndex] > 0;
      }
    } else if (directionMove === 'next') {
      targetIndex = (this.virtualIndex + 1) % this.slicks.length;
      if (directionMove === 'next') {
        if (this.realActiveIndex === 0) slideIndex = 1;
        else if (this.realActiveIndex === 1) slideIndex = 2;
        else if (this.realActiveIndex === 2) slideIndex = 0;
      }

      const alerts = this.slicks[targetIndex]?.content?.cta?.components?.alerts || [];
      this.alertsPerSlide[slideIndex] = alerts
        .filter(alert => alert.show_item)
        .map(alert => this.mapAlert(alert));

      this.numberOfAlerts[slideIndex] = this.alertsPerSlide[slideIndex].length;
      this.screenAlertsBtn[slideIndex] = this.numberOfAlerts[slideIndex] > 0;
    } else if (directionMove === 'prev') {
      targetIndex = (this.virtualIndex - 1 + this.slicks.length) % this.slicks.length;
      if (directionMove === 'prev') {
        if (this.realActiveIndex === 0) slideIndex = 2;
        else if (this.realActiveIndex === 1) slideIndex = 0;
        else if (this.realActiveIndex === 2) slideIndex = 1;
      }

      const alerts = this.slicks[targetIndex]?.content?.cta?.components?.alerts || [];
      this.alertsPerSlide[slideIndex] = alerts
        .filter(alert => alert.show_item)
        .map(alert => this.mapAlert(alert));

      this.numberOfAlerts[slideIndex] = this.alertsPerSlide[slideIndex].length;
      this.screenAlertsBtn[slideIndex] = this.numberOfAlerts[slideIndex] > 0;
    }
  }

  mapAlert(alert: any): any {
    return {
      id: alert.id,
      header: alert.header || 'Default Header',
      text: alert.text || '',
      showAlerts: alert.show_alerts === 1,
      font_color: alert.font_color || '#FFFFFF',
      background: alert.background || '#000000',
      define_interval: alert.define_interval === 1,
      show_item: alert.show_item === 1,
      interval_time: alert.interval_time || { start: '00:00:00', end: '00:00:00' },
      slider_time_range: this.convertAccordionTimeToSliderValue(
        alert.interval_time?.start || '00:00:00',
        alert.interval_time?.end || '00:00:00',
        this.sliderMaxValue
      )
    };
  }


  updateAlertsPerSlide(realIndex: number, videoTime: number): void {
    const realPositionOffsets = [
      realIndex === 0 ? 0 : realIndex === 1 ? -1 : -2, // Posição 0
      realIndex === 0 ? 1 : realIndex === 1 ? 0 : -1,  // Posição 1
      realIndex === 0 ? 2 : realIndex === 1 ? 1 : 0    // Posição 2
    ];

    realPositionOffsets.forEach((offset, index) => {
      const adjustedIndex = this.virtualIndex + offset;

      // Verificar se o índice ajustado está dentro dos limites
      if (adjustedIndex >= 0 && adjustedIndex < this.slicks.length) {
        const alerts = this.slicks[adjustedIndex]?.content?.cta?.components?.alerts || [];

        // Verifica se todos os intervalos são inválidos
        const allIntervalsEmpty = alerts.every(alert => {
          const start = alert.interval_time?.start || '00:00:00';
          const end = alert.interval_time?.end || '00:00:00';
          return alert.show_item && (start === '00:00:00' || start === null) && (end === '00:00:00' || end === null);
        });

        if (allIntervalsEmpty) {
          // Mapear todos os alertas sem considerar intervalos
          this.alertsPerSlide[index] = alerts.map((alert: any) => ({
            id: alert.id || index, // Gerar um ID, se necessário
            header: alert.header || 'Default Header',
            text: alert.text || '',
            showAlerts: alert.show_alerts === 1,
            font_color: alert.font_color || '#FFFFFF',
            background: alert.background || '#000000',
            define_interval: alert.define_interval === 1,
            show_item: alert.show_item === 1,
            interval_time: alert.interval_time || { start: '00:00:00', end: '00:00:00' },
            slider_time_range: this.convertAccordionTimeToSliderValue(
              alert.interval_time?.start || '00:00:00',
              alert.interval_time?.end || '00:00:00',
              this.sliderMaxValue
            ),
            videoUid: this.slicks[adjustedIndex]?.content?.uid || ''
          }));
          return;
        } else {
          // Mapear e filtrar alertas com intervalos válidos
          this.alertsPerSlide[index] = alerts
            .filter((alert: any) => {
              const startSeconds = this.timeToSeconds(alert.interval_time?.start || '00:00:00');
              const endSeconds = this.timeToSeconds(alert.interval_time?.end || '00:00:00');

              return alert.show_item && (
                (videoTime >= startSeconds && videoTime <= endSeconds) ||
                (startSeconds === 0 && endSeconds === 0)
              );
            })
            .map((alert: any) => ({
              id: alert.id || index, // Gerar um ID, se necessário
              header: alert.header || 'Default Header',
              text: alert.text || '',
              showAlerts: alert.show_alerts === 1,
              font_color: alert.font_color || '#FFFFFF',
              background: alert.background || '#000000',
              define_interval: alert.define_interval === 1,
              show_item: alert.show_item === 1,
              interval_time: alert.interval_time || { start: '00:00:00', end: '00:00:00' },
              slider_time_range: this.convertAccordionTimeToSliderValue(
                alert.interval_time?.start || '00:00:00',
                alert.interval_time?.end || '00:00:00',
                this.sliderMaxValue
              ),
              videoUid: this.slicks[adjustedIndex]?.content?.uid || ''
            }));
        }
      } else {
        // Índice fora do limite, definir como array vazio
        this.alertsPerSlide[index] = [];
      }
    });

  }



  filteredLinks = new Subject<Links[]>();
  filteredLinksShortucut = new Subject<Links[]>();
  linksPerSlide: Links[][] = []; // Links por slide do Swiper
  shortcutsPerSlide: Links[][] = [];
  shortcutsPerSlideOriginal: Links[][] = [];
  hasLinks: boolean = false;

  setupFilteredLinksShortucut(directionMove: string | undefined): void {
    let targetIndex: number;
    let slideIndex: number = 0;

    if (directionMove === undefined && this.realActiveIndex === 0) {
      targetIndex = this.virtualIndex;

      // Atualizar o índice 0 e também o próximo (índice 1)
      [0, 1].forEach(index => {
        slideIndex = index;
        if (targetIndex >= 0 && targetIndex < this.slicks.length) {
          let linksSelecteds = this.slicks[index == 0 ? targetIndex : targetIndex + 1]?.content?.cta?.components?.links || [];
          const slideUid = this.slicks[targetIndex]?.content?.uid || '';

          let shortcuts = linksSelecteds
            .filter(link => link.link_type === 'shortcut' && link.show_item)
            .map(link => ({
              id: link.id || 0,
              name: link.name,
              bg_color: link.bg_color || '#000000',
              define_interval: !!link.define_interval,
              show_item: !!link.show_item,
              link_type: link.link_type,
              position: link.position || 0,
              redirect_url: link.redirect_url || '',
              click_url: link.click_url || '',
              interval_time: link.interval_time || { start: '00:00:00', end: '00:00:00' },
              slider_time_range: this.convertAccordionTimeToSliderValue(
                link.interval_time?.start || '00:00:00',
                link.interval_time?.end || '00:00:00',
                this.sliderMaxValue
              ),
              videoUid: slideUid,
              slideIndex: slideIndex
            }));

          this.shortcutsPerSlide[index] = shortcuts.slice(0, this.itemsToShow);
          this.shortcutsPerSlideOriginal[index] = shortcuts;
        }
      });
    } else if (directionMove === 'next') {
      targetIndex = (this.virtualIndex + 1) % this.slicks.length;
      if (directionMove === 'next') {
        if (this.realActiveIndex === 0) slideIndex = 1;
        else if (this.realActiveIndex === 1) slideIndex = 2;
        else if (this.realActiveIndex === 2) slideIndex = 0;
      }

    } else if (directionMove === 'prev') {
      targetIndex = (this.virtualIndex - 1 + this.slicks.length) % this.slicks.length;
      if (directionMove === 'prev') {
        if (this.realActiveIndex === 0) slideIndex = 2;
        else if (this.realActiveIndex === 1) slideIndex = 0;
        else if (this.realActiveIndex === 2) slideIndex = 1;
      }
    } else {
      return;
    }

    if (targetIndex >= 0 && targetIndex < this.slicks.length && directionMove !== undefined) {
      const linksSelecteds = this.slicks[targetIndex]?.content?.cta?.components?.links || [];
      const slideUid = this.slicks[targetIndex]?.content?.uid || '';

      let shortcuts = linksSelecteds
        .filter(link => link.link_type === 'shortcut' && link.show_item)
        .map(link => ({
          id: link.id || 0,
          name: link.name,
          bg_color: link.bg_color || '#000000',
          define_interval: !!link.define_interval,
          show_item: !!link.show_item,
          link_type: link.link_type,
          position: link.position || 0,
          redirect_url: link.redirect_url || '',
          click_url: link.click_url || '',
          interval_time: link.interval_time || { start: '00:00:00', end: '00:00:00' },
          slider_time_range: this.convertAccordionTimeToSliderValue(
            link.interval_time?.start || '00:00:00',
            link.interval_time?.end || '00:00:00',
            this.sliderMaxValue
          ),
          videoUid: slideUid,
          slideIndex: slideIndex
        }));

      this.shortcutsPerSlide[slideIndex] = shortcuts.slice(0, this.itemsToShow);
      this.shortcutsPerSlideOriginal[slideIndex] = shortcuts;
    }
  }
  getFlattenedShortcuts(slideIndex: number): any[] {
    return this.shortcutsPerSlideOriginal.flat().filter((link, index, array) => {
      // Retornar apenas links únicos com base no índice do slide
      return (
        link.slideIndex === slideIndex &&
        array.findIndex(l => l.id === link.id && l.slideIndex === link.slideIndex) === index
      );
    });
  }
  updateShortcutsPerSlide(): void {
    if (this.shortcutsPerSlideOriginal) {
      this.shortcutsPerSlide = this.shortcutsPerSlideOriginal.map((links: any[]) =>
        links.slice(0, this.itemsToShow) // Recalcula os itens visíveis com base no tamanho da tela
      );
      this.hasLinks = this.shortcutsPerSlide.some((links: any[]) => links.length > 0);
    }
  }




  ////////////////////

  ////////////////////

  setupFilteredLinks(directionMove: string | undefined): void {
    let targetIndex: number;
    let slideIndex: number = 0;

    if (directionMove === undefined && this.realActiveIndex === 0) {
      targetIndex = this.virtualIndex;
      // teste commit
      // Atualizar o índice 0 e também o próximo (índice 1)
      [0, 1].forEach(index => {
        slideIndex = index;
        if (targetIndex >= 0 && targetIndex < this.slicks.length) {
          const linksSelecteds = this.slicks[index]?.content?.cta?.components?.links || [];
          const slideUid = this.slicks[targetIndex]?.content?.uid || '';

          this.linksPerSlide[index] = linksSelecteds
            .filter(link => !link.link_type || (link.link_type === 'swiper' && link.show_item))
            .map(link => ({
              id: link.id,
              name: link.name,
              bg_color: link.bg_color || '#000000',
              define_interval: link.define_interval,
              show_item: link.show_item,
              link_type: link.link_type,
              position: link.position,
              redirect_url: link.redirect_url,
              click_url: link.click_url,
              interval_time: link.interval_time || { start: '00:00:00', end: '00:00:00' },
              slider_time_range: this.convertAccordionTimeToSliderValue(
                link.interval_time?.start || '00:00:00',
                link.interval_time?.end || '00:00:00',
                this.sliderMaxValue
              ),
              videoUid: slideUid,
              slideIndex: slideIndex
            }));
        }
      });
    } else if (directionMove === 'next') {
      targetIndex = (this.virtualIndex + 1) % this.slicks.length;
      if (directionMove === 'next') {
        if (this.realActiveIndex === 0) slideIndex = 1;
        else if (this.realActiveIndex === 1) slideIndex = 2;
        else if (this.realActiveIndex === 2) slideIndex = 0;
      }

    } else if (directionMove === 'prev') {
      targetIndex = (this.virtualIndex - 1 + this.slicks.length) % this.slicks.length;
      if (directionMove === 'prev') {
        if (this.realActiveIndex === 0) slideIndex = 2;
        else if (this.realActiveIndex === 1) slideIndex = 0;
        else if (this.realActiveIndex === 2) slideIndex = 1;
      }
    } else {
      return;
    }

    if (targetIndex >= 0 && targetIndex < this.slicks.length && directionMove !== undefined) {
      const linksSelecteds = this.slicks[targetIndex]?.content?.cta?.components?.links || [];
      const slideUid = this.slicks[targetIndex]?.content?.uid || '';

      this.linksPerSlide[slideIndex] = linksSelecteds
        .filter(link => !link.link_type || (link.link_type === 'swiper' && link.show_item))
        .map(link => ({
          id: link.id,
          name: link.name,
          bg_color: link.bg_color || '#000000',
          define_interval: link.define_interval,
          show_item: link.show_item,
          link_type: link.link_type,
          position: link.position,
          redirect_url: link.redirect_url,
          click_url: link.click_url,
          interval_time: link.interval_time || { start: '00:00:00', end: '00:00:00' },
          slider_time_range: this.convertAccordionTimeToSliderValue(
            link.interval_time?.start || '00:00:00',
            link.interval_time?.end || '00:00:00',
            this.sliderMaxValue
          ),
          videoUid: slideUid,
          slideIndex: slideIndex
        }));
    }
  }

  private filteredLinksSubscription?: Subscription;
  private filteredAlertsSubscription?: Subscription;
  @ViewChildren('btnsRightSup') btnsRightSups!: QueryList<ElementRef<HTMLDivElement>>;
  private linksSource$ = new Observable<Links[]>;
  // Modifique filteredLinks$ para filteredLinks do tipo Subject
  visibleItemsCount = new BehaviorSubject<number>(6);

  fullScreen: boolean = false;
  isMobile: boolean = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  // btns menu superior
  volumeControlTimeout: any;
  volumeControlVisible: boolean = false;

  stopSwiper(event: Event): void {
    event.stopPropagation();
  }
  showVolumeControl() {
    this.volumeControlVisible = true;
    clearTimeout(this.volumeControlTimeout); // Limpa qualquer timeout existente antes de definir um novo
  }
  @ViewChild('volumeSlider', { static: false }) volumeSlider!: ElementRef;
  volumeValue: number = 0;
  lastVolume: number = 0.5;

  saveVolume(volume: number) {
    if (volume > 0) {
      localStorage.setItem('volume', volume.toString());
    }
  }
  isVideoMuted(): boolean {
    return this.videoMuteService.getMuteState()
  }

  isMuted: boolean = true;
  muteUnmuteAll: boolean = false;
  showMute: boolean = true;
  //isVideoMuted()

  isPiPActive: boolean = false;

  aspectRatioBefore?: string;
  openPip() {
    this.aspectRatioBefore = this.aspect_ratio;
    if (this.aspect_ratio === '2') {
      this.aspect_ratio = '1';
      let stCS = setTimeout(() => {
        this.player.requestPictureInPicture().then(() => {
          this.isPiPActive = true;
        });
        clearTimeout(stCS);
      }, 150);
    } else {
      this.player.requestPictureInPicture().then(() => {
        this.isPiPActive = true;
      });
    }
  }

  togglePiP() {
    // Obtenha a instância do swiper
    const swiperInstance = this.swiperElement?.swiperRef;
    if (!swiperInstance) {
      return;
    }

    // Determine o índice correto baseado se o slide é duplicado ou real
    const realIndex = swiperInstance.realIndex;
    const activeIndex = (swiperInstance.activeIndex === 0 && !swiperInstance.slides[swiperInstance.activeIndex].classList.contains('swiper-slide-duplicate'))
      ? realIndex
      : this.getActiveSlideIndex();

    // Selecione o slide ativo
    const activeSlide = swiperInstance.slides[swiperInstance.activeIndex];

    // Busque o vídeo dentro do slide ativo com a classe correta
    const videoElement = activeSlide.querySelector(`.my-video-${activeIndex}`) as HTMLVideoElement;

    if (!videoElement) {
      return;
    }

    // Obtenha o player associado ao elemento de vídeo selecionado
    this.player = videojs(videoElement);

    // Abrir ou fechar o PiP
    if (this.isPiPActive) {
      this.player.exitPictureInPicture().then(() => {
        this.isPiPActive = false;
      });
    } else {
      if (this.player.requestPictureInPicture) {
        try {
          this.openPip();
        } catch (error) {
        }
      } else {
      }
    }
  }

  toggleMute() {
    this.showMute = false;
    const isMuted = this.videoMuteService.getMuteState();
    this.videoMuteService.setMuteState(!isMuted);
    // Obter a instância do swiper
    const swiperInstance = this.swiperElement?.swiperRef;
    if (!swiperInstance) {
      return;
    }
    // Obter o índice real, ignorando duplicatas
    const realIndex = swiperInstance.realIndex;

    // Verificar se o slide ativo é duplicado
    const activeSlide = swiperInstance.slides[swiperInstance.activeIndex];
    const isDuplicate = activeSlide.classList.contains('swiper-slide-duplicate');
    // Buscar o player correspondente no arrayPlayers
    const player = this.videoPlayerService.getPlayerFromArray(realIndex, isDuplicate, this.arrayPlayers);
    if (player) {
      // Atualizar o estado de mudo com o valor retornado pelo serviço
      this.isMuted = this.videoPlayerService.toggleMute(player, this.isMuted);

      // Atualizar a visibilidade do ícone de mute
      if (!this.isMuted) {
        this.shouMuteImg = false;
        this.showMute = false;
      }
    }
  }

  togglePlayPause() {
    if (this.progressBarOpacity == 0) {
      this.progressBarOpacity = 1;
      this.blockOpacity = false;
    }

    ///////////////////////////////////////
    // Obter a instância do swiper
    const swiperInstance = this.swiperElement?.swiperRef;
    if (!swiperInstance) {
      return;
    }

    // Obter o índice real, ignorando duplicatas
    const realIndex = swiperInstance.realIndex;
    const activeSlide = swiperInstance.slides[swiperInstance.activeIndex];
    const isDuplicate = activeSlide.classList.contains('swiper-slide-duplicate');
    const player = this.videoPlayerService.getPlayerFromArray(realIndex, isDuplicate, this.arrayPlayers);
    // Verificar se o player foi encontrado
    if (player) {
      this.isPaused = this.videoPlayerService.togglePlayPause(player, this.isPaused);
    }
  }
  async handleLinkClickShortcut(link: any): Promise<void> {
    this.tempUrl = link.redirect_url;
    this.displayDialog = true;
    // Iniciar PiP
    if (!this.isPiPActive) {
      try {
        this.openPip();
      } catch (error) {
      }
    }
    this.typeCtaEvent = 'link_shortcut';
    this.tempCtaId = link.id;
    this.events('cta_events', this.typeCtaEvent, link.id);
  }

  showMenuInf = false;
  myButtonDom: any;
  @ViewChild('videoUploadContainer') videoUploadContainer?: ElementRef;
  goMinscreen = () => {
    this.fullScreen = false;
    this.events('minimize', '', 0);

    this.showMenuInf = false;
    if (this.videoUploadContainer) {
      const elem = this.videoUploadContainer.nativeElement;
      if (document.fullscreenElement) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if ((document as any).webkitExitFullscreen) { // Safari
          (document as any).webkitExitFullscreen();
        } else if ((document as any).mozCancelFullScreen) { // Firefox
          (document as any).mozCancelFullScreen();
        } else if ((document as any).msExitFullscreen) { // IE e Edge
          (document as any).msExitFullscreen();
        } else {
          // Remover estilos CSS se nenhum método de fullscreen for aplicado
          elem.style.position = '';
          elem.style.top = '';
          elem.style.left = '';
          elem.style.width = '';
          elem.style.height = '';
          elem.style.zIndex = '';
        }
      }
      ////////////////////////
      if (!this.typeLive) {
        if (this.fullScreen) {
          this.myButtonDom.innerHTML = "<i class='pi pi-window-minimize' style='cursor:pointer;'></i>";
        } else {
          this.myButtonDom.innerHTML = "<i class='pi pi-window-maximize' style='cursor:pointer;'></i>";
        }
        this.myButtonDom.onclick = () => {
          if (this.fullScreen) {
            this.goMinscreen();
          } else {
            this.goFullscreen();
          }
        };
      }
    }
  }
  goFullscreen() {
    this.fullScreen = true;
    this.events('maximize', '', 0);
    this.cdr.detectChanges();
    if (this.videoUploadContainer && this.videoUploadContainer.nativeElement) {

      const elem = this.videoUploadContainer.nativeElement;
      const userAgent = navigator.userAgent;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) { /* Firefox */
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { /* IE/Edge */
        elem.msRequestFullscreen();
      } else if (elem.webkitEnterFullscreen) {
        elem.webkitEnterFullscreen();
      } else if (elem.webkitDisplayingFullscreen) {
        elem.webkitDisplayingFullscreen();
      } else if (elem.webkitDisplayingFullscreen) {
        elem.webkitDisplayingFullscreen();
      }

      this.fullScreen = true;
      if (!this.typeLive) {
        const videoJsControlBar = document.getElementsByClassName('vjs-control-bar');
        if (this.fullScreen && videoJsControlBar[0]) {
          this.myButtonDom.innerHTML = "<i class='pi pi-window-minimize' style='cursor:pointer;'></i>";
        } else {
          this.myButtonDom.innerHTML = "<i class='pi pi-window-maximize' style='cursor:pointer;'></i>";
        }
        this.myButtonDom.onclick = () => {
          if (this.fullScreen) {
            this.goMinscreen();
          } else {
            this.goFullscreen();
          }
        };
      }
    }
  }
  screenChat: boolean = false;
  showBtns: boolean = false;
  sidebarChatApp: boolean = false;
  sidebarChat: boolean = true;
  acceptedTerms: boolean = false;
  username: string = '';
  autorizeChat() {
    if (this.username?.trim() !== '') {  // Verifica se o nome não está vazio
      localStorage.setItem('authChat', 'ok');
      localStorage.setItem('user_name', this.username);
      const activeIndex = this.getActiveSlideIndex();
      this.connectToRoom(activeIndex);
      // chamar chat message
      //this.chatWebSocketService.joinRoom(this.chatId, this.username, this.user_chat);
      this.chatAutorization = false;
      this.screenChat = true;
      this.cdr.detectChanges();
    }
  }
  openSreenChat() {
    const storedUsername = localStorage.getItem('user_name');
    if (storedUsername) {
      this.username = storedUsername;
    } else {
      this.username = '';
    }
    if (localStorage.getItem('authChat') == 'ok' && this.username != '') {
      this.autorizeChat();
    } else {
      this.chatAutorization = true;
      this.cdr.detectChanges();
    }
    if (this.chatBoxComponent) {
      this.chatBoxComponent.nativeElement.loadMessages();
    }
    this.events('openChat', '', 0);
  }
  closeSreenChat() {
    this.screenChat = false;
    this.cdr.detectChanges();
  }
  canActivateButton(): boolean {
    return this.username.trim().length > 0 && this.acceptedTerms;
  }
  iconColor: string = '#FFFFFF';
  isPaused: boolean = false;
  playPauseColor: string = '#FF0000';
  showPlayPauseButton: boolean = true;
  @ViewChild('playPauseButton') playPauseButton?: ElementRef;

  getActiveSlideIndex2(): number {
    const swiperInstance = this.swiperElement?.swiperRef;
    return swiperInstance?.activeIndex ?? 0;
  }

  progressBarColor: string = '#FF6347';
  fakeBar: boolean = true;
  private _fakeBar = new BehaviorSubject<boolean>(true);
  fakeBar$ = this._fakeBar.asObservable();
  setFakeBar(value: boolean): void {
    this._fakeBar.next(value);

  }

  listenersAdded: boolean = false;
  private dragEvents = new Subject<MouseEvent | TouchEvent>();
  @ViewChildren('progressContainer') progressContainers!: QueryList<ElementRef>;
  @ViewChild('progressContainer') progressContainer?: ElementRef;
  @ViewChildren('originalProgress') originalProgresses!: QueryList<ElementRef>;
  @ViewChildren('parallelProgress') parallelProgresses!: QueryList<ElementRef>;
  isDraggingProgressBar: boolean = false;

  private setOpacity(progressOpacity: number, gridOpacity: number): void {
    if (this.progressBarTime && this.progressBarTime.nativeElement) {
      if (progressOpacity == 1) {
        this.progressBarTime.nativeElement.classList.add('visible');
      } else {
        this.progressBarTime.nativeElement.classList.remove('visible');
      }
    }
    this.gridContainers?.forEach((gridContainer) => {
      gridContainer.nativeElement.style.opacity = gridOpacity.toString();
    });
  }

  isSeeking: boolean = false;
  displayedTime: string = '';
  // Atualize isto com o valor real ou estimado
  formatDuration2(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    let timeString = '';

    if (hours > 0) {
      // Formata como 00h00m00s
      timeString += `${hours.toString().padStart(2, '0')}h`;
    }
    if (hours > 0 || minutes > 0) {
      // Inclui minutos se houver horas ou minutos
      timeString += `${minutes.toString().padStart(2, '0')}m`;
    }
    // Sempre inclui segundos
    timeString += `${remainingSeconds.toString().padStart(2, '0')}s`;

    return timeString;
  }

  getClientXFromEvent(event: MouseEvent | TouchEvent): number | null {
    if (event instanceof MouseEvent) {
      return event.clientX;
    } else if (this.isTouchEvent(event)) {
      let clientX: number | null = null; // Inicializado como null
      if (event.type === 'touchend' || event.type === 'touchcancel') {
        if (event.changedTouches && event.changedTouches.length > 0) {
          clientX = event.changedTouches[0].clientX; // Usar changedTouches em touchend/touchcancel
        } else {
          console.warn('TouchEvent (touchend/touchcancel) sem clientX válido');
        }
      } else if (event.touches && event.touches.length > 0) {
        clientX = event.touches[0].clientX; // Usar touches para eventos normais de touch
      }
      return clientX; // Agora garantimos que `clientX` será null ou um número
    }
    return null;
  }

  private animationFrameId?: number;
  @ViewChild('progressContainerWrapper') progressContainerWrapper?: ElementRef<HTMLDivElement>;
  onDragging(event: MouseEvent | TouchEvent): void {
    if (!this.isDragging) return;

    if (this.swiperInstance) {
      this.swiperInstance.allowTouchMove = false;
      this.swiperInstance.allowSlideNext = false;
      this.swiperInstance.allowSlidePrev = false;
    }

    const progressContainer = this.progressContainerWrapper?.nativeElement;
    if (!progressContainer) return;

    const rect = progressContainer.getBoundingClientRect();
    let clientX: number | null = this.getClientXFromEvent(event);
    if (clientX === null) return;

    // Ajustar o ponto inicial para compensar margens, se houver
    const clickX = clientX - rect.left;

    // Garantir que o `clickX` não ultrapasse os limites da barra
    const boundedClickX = Math.max(0, Math.min(clickX, rect.width));

    const seekPercentage = (boundedClickX / rect.width) * 100; // Calcula o percentual exato
    const seekTime = (boundedClickX / rect.width) * this.player.duration(); // Calcula o tempo do vídeo com precisão
    this.seekTime = seekTime;

    // Atualiza a posição da barra de progresso e outras alterações no próximo frame de animação
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
    }

    // Atualiza o tempo mostrado
    this.animationFrameId = requestAnimationFrame(() => {
      this.updateProgressBarAndMarker(seekPercentage);
      this.updateDisplayedTime(seekTime);

      if (this.progressContainer) {
        if (this.isMobile) {
          this.progressContainer.nativeElement.classList.add('ss-g-progress-container-expanded');
        }
      }
      if (this.timeDisplay) {
        this.timeDisplay.nativeElement.style.display = 'block';
      }
      this.setOpacity(1, 0);
    });
  }
  updateDisplayedTime(time: number) {

    this.displayedTime = this.formatDuration2(time);
    this.cdr.detectChanges();
  }
  seekPercentage?: number;
  seekTime?: number;
  updateProgressBarAndMarker(seekPercentage: number): void {
    const originalProgress = this.originalProgresses.toArray()[0]?.nativeElement;
    const parallelProgress = this.parallelProgresses.toArray()[0]?.nativeElement;

    if (originalProgress) {
      originalProgress.style.width = `${seekPercentage}%`;
    }

    if (this.fakeBar && parallelProgress) {
      parallelProgress.style.width = `${seekPercentage}%`;
    }

    this.seekPercentage = seekPercentage;
  }

  onDragStart(event: MouseEvent | TouchEvent): void {
    this.isDraggingProgressBar = true;
    this.isDragging = true;

    const wrapper = this.progressContainerWrapper?.nativeElement;

    // Definir os listeners do sprite diretamente no onDragging e onDragEnd
    const mouseMoveListener = (event: MouseEvent) => {
      this.onDragging(event); // Executar o arrasto
      this.updateThumbnail(event.clientX); // Atualizar a posição da miniatura
    };

    const touchMoveListener = (event: TouchEvent) => {
      this.onDragging(event); // Executar o arrasto
      this.updateThumbnail(event.touches[0].clientX); // Atualizar a posição da miniatura
    };

    const touchEndListener = () => {
      if (this.thumbnailPreview && this.thumbnailPreview.nativeElement) {
        this.thumbnailPreview.nativeElement.style.display = 'none';
      }
    };

    const mouseLeaveListener = () => {
      if (this.thumbnailPreview && this.thumbnailPreview.nativeElement) {
        this.thumbnailPreview.nativeElement.style.display = 'none';
      }
    };

    // Adicionar listeners ao progress bar e à janela para finalizar
    wrapper?.addEventListener('mousemove', mouseMoveListener);
    wrapper?.addEventListener('touchmove', touchMoveListener, { passive: true });
    wrapper?.addEventListener('mouseup', this.onDragEnd.bind(this));
    wrapper?.addEventListener('touchend', this.onDragEnd.bind(this));
    wrapper?.addEventListener('touchcancel', this.onDragEnd.bind(this));
    wrapper?.addEventListener('mouseleave', mouseLeaveListener);

    // Armazenar os listeners para remoção futura
    this.spriteListeners = { touchMoveListener, mouseMoveListener, mouseLeaveListener, touchEndListener };
  }


  onDragEnd(event: MouseEvent | TouchEvent): void {
    if (this.swiperInstance) {
      this.swiperInstance.allowTouchMove = true;
      this.swiperInstance.allowSlideNext = true;
      this.swiperInstance.allowSlidePrev = true;
    }

    // Limpar a animação do `requestAnimationFrame`
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
      this.animationFrameId = undefined;
    }

    // Voltar a altura da barra de progresso para 5px
    if (this.progressContainer) {
      this.progressContainer.nativeElement.classList.remove('ss-g-progress-container-expanded');
    }
    this.setOpacity(0, 1);
    this.isDraggingProgressBar = false;
    this.isDragging = false;

    // Atualizar o tempo ao soltar a barra (importante para mobile)
    this.updateProgress(event); // Verifique se esta função está realmente sendo chamada no 'touchend'

    //this.removeProgressBarListeners();

    // // Remover listeners associados ao sprite
    this.removeSpriteListeners();
    this.removeHoverListeners();
    const thumbnailContainer = this.thumbnailContainer?.nativeElement;
    if (thumbnailContainer) {
      thumbnailContainer.style.display = 'none'; // Ocultar manualmente a miniatura
    }
    if (this.timeDisplay) {
      this.timeDisplay.nativeElement.style.display = 'none';
    }
  }
  removeHoverListeners(): void {
    const progressBar = this.progressContainerWrapper?.nativeElement;

    if (!progressBar || !this.hoverListeners) return;

    const { mouseEnterListener, mouseMoveListener, mouseLeaveListener } = this.hoverListeners;

    // Remover eventos de hover do progress bar apenas se eles existirem
    if (mouseEnterListener) progressBar.removeEventListener('mouseenter', mouseEnterListener);
    if (mouseMoveListener) progressBar.removeEventListener('mousemove', mouseMoveListener);
    if (mouseLeaveListener) progressBar.removeEventListener('mouseleave', mouseLeaveListener);
    const thumbnailContainer = this.thumbnailContainer?.nativeElement;
    if (thumbnailContainer) {
      thumbnailContainer.style.display = 'none'; // Ocultar manualmente a miniatura
    }
    // Limpar os listeners armazenados
    this.hoverListeners = {};

  }

  hoverListeners: { mouseEnterListener?: (event: MouseEvent) => void, mouseMoveListener?: (event: MouseEvent) => void, mouseLeaveListener?: (event: MouseEvent) => void } = {};
  addHoverListeners(): void {
    const progressBar = this.progressContainerWrapper?.nativeElement;
    const thumbnailPreview = this.thumbnailPreview?.nativeElement;
    const thumbnailContainer = this.thumbnailContainer?.nativeElement;

    if (!progressBar || !thumbnailPreview) {
      console.warn('progressBar ou thumbnailPreview não definidos para hover.');
      return;
    }

    // Definir os listeners para o sprite no hover
    const mouseEnterListener = (event: MouseEvent) => {
      if (this.timeDisplay) {
        this.timeDisplay.nativeElement.style.display = 'block';
      }
      // Exibir a miniatura e atualizar conforme a posição do mouse
      thumbnailPreview.style.display = 'block';
      this.updateThumbnail(event.clientX);
    };

    const mouseMoveListener = (event: MouseEvent) => {
      // Atualizar a miniatura durante o movimento do mouse
      this.updateThumbnail(event.clientX);
    };

    const mouseLeaveListener = () => {
      thumbnailPreview.style.display = 'none';
      if (this.timeDisplay) {
        this.timeDisplay.nativeElement.style.display = 'none';
      }
    };

    // Guardar os listeners para remover depois
    this.hoverListeners = { mouseEnterListener, mouseMoveListener, mouseLeaveListener };

    // Adicionar listeners ao progress bar
    progressBar.addEventListener('mouseenter', mouseEnterListener);
    progressBar.addEventListener('mousemove', mouseMoveListener);
    progressBar.addEventListener('mouseleave', mouseLeaveListener);
  }
  // Variável para guardar referência dos listeners
  private spriteListeners: {
    touchMoveListener?: (event: TouchEvent) => void,
    touchEndListener?: () => void,
    mouseMoveListener?: (event: MouseEvent) => void,
    mouseLeaveListener?: () => void
  } = {};
  removeSpriteListeners(): void {
    const wrapper = this.progressContainerWrapper?.nativeElement;
    if (!wrapper) return;

    const { touchMoveListener, mouseMoveListener, mouseLeaveListener, touchEndListener } = this.spriteListeners;

    if (touchMoveListener) wrapper.removeEventListener('touchmove', touchMoveListener);
    if (mouseMoveListener) wrapper.removeEventListener('mousemove', mouseMoveListener);
    if (mouseLeaveListener) wrapper.removeEventListener('mouseleave', mouseLeaveListener);
    if (touchEndListener) {
      wrapper.removeEventListener('touchend', touchEndListener);
      wrapper.removeEventListener('touchcancel', touchEndListener);
    }

    // Garantir que a miniatura seja removida
    const thumbnailPreview = this.thumbnailPreview?.nativeElement;
    if (thumbnailPreview) {
      thumbnailPreview.style.display = 'none'; // Ocultar manualmente a miniatura
    }
    const thumbnailContainer = this.thumbnailContainer?.nativeElement;
    if (thumbnailContainer) {
      thumbnailContainer.style.display = 'none'; // Ocultar manualmente a miniatura
    }

    this.spriteListeners = {};
  }

  removeProgressBarListeners() {
    const wrapper = this.progressContainerWrapper?.nativeElement;

    // Remova os eventos ao final do "drag"
    wrapper?.removeEventListener('mousemove', this.onDragging.bind(this));
    wrapper?.removeEventListener('touchmove', this.onDragging.bind(this));
    wrapper?.removeEventListener('mouseup', this.onDragEnd.bind(this));
    wrapper?.removeEventListener('touchend', this.onDragEnd.bind(this));
    wrapper?.removeEventListener('touchcancel', this.onDragEnd.bind(this));
  }
  @ViewChild('thumbnailContainer') thumbnailContainer?: ElementRef<HTMLDivElement>;
  updateThumbnail(clientX: number): void {
    const progressBar = this.progressContainerWrapper?.nativeElement;
    const thumbnailContainer = this.thumbnailContainer?.nativeElement;

    if (!progressBar || !thumbnailContainer) return;

    const rect = progressBar.getBoundingClientRect();
    const boundedClientX = Math.max(rect.left, Math.min(clientX, rect.right));
    const relativeX = boundedClientX - rect.left;

    const spriteTime = (relativeX / rect.width) * this.player.duration();
    this.displayedTime = this.formatDuration2(spriteTime);

    // Posicionar o contêiner de miniaturas
    thumbnailContainer.style.display = 'flex';
    const thumbnailWidth = thumbnailContainer.offsetWidth;

    // Define o espaço de margem mínima (ex: 10px)
    const margin = 28;

    // Centralizar e garantir que não ultrapasse os limites com margem
    const positionLeft = Math.min(
      Math.max(relativeX - thumbnailWidth / 2, margin), // Não ultrapassa o lado esquerdo (adiciona margem)
      rect.width - thumbnailWidth - margin // Não ultrapassa o lado direito (adiciona margem)
    );
    thumbnailContainer.style.left = `${positionLeft}px`;
    thumbnailContainer.style.top = this.aspect_ratio === '1' ? '-40px' : '-100px';

    // Atualizar o sprite
    const thumbnailPreview = this.thumbnailPreview?.nativeElement;
    if (thumbnailPreview) {
      const thumbnailIndex = Math.floor(spriteTime);
      const imgCloud = 'https://raw.githubusercontent.com/GiriAakula/samuel-miller-task/master/openvideo.png';

      let backgroundPosX: number;
      let backgroundSize: string;

      if (this.aspect_ratio === '1') {
        // Dimensões do quadro
        const frameWidth = 120; // Largura de cada quadro (1600px dividido por 10 quadros)
        const frameHeight = 190; // Altura de cada quadro (fixa)

        // Calcula a posição do quadro no sprite
        backgroundPosX = thumbnailIndex * -frameWidth; // Deslocamento horizontal do quadro
        backgroundSize = `${1200}px ${950}px`; // Tamanho total do sprite (mantém as dimensões originais)

        // Aplica as dimensões ao contêiner de miniaturas
        thumbnailPreview.style.width = `${frameWidth}px`; // Define a largura de um único quadro
        thumbnailPreview.style.height = `${frameHeight}px`; // Define a altura de um único quadro
      } else {
        // Ajustes para outras proporções
        backgroundPosX = thumbnailIndex * -160;
        backgroundSize = '1600px 450px';


      }
      thumbnailPreview.style.display = 'block';
      thumbnailPreview.style.backgroundImage = `url(${imgCloud})`;
      thumbnailPreview.style.backgroundPosition = `${backgroundPosX}px 0px`;
      thumbnailPreview.style.backgroundSize = backgroundSize;

    }
  }



  updateProgress(event: MouseEvent | TouchEvent): void {
    const progressContainer = this.progressContainerWrapper?.nativeElement;
    if (!progressContainer) return;
    if (!this.seekPercentage) return;

    this.player.currentTime(this.seekTime);

    let progressPercentage;
    if (this.fakeBar) {
      if (this.seekPercentage < 0.3) {
        progressPercentage = (this.seekPercentage / 0.3) * 70;
      } else {
        progressPercentage = 70 + ((this.seekPercentage - 0.3) / 0.7) * 30;
      }
      const parallelProgress = this.parallelProgresses.toArray()[0].nativeElement;
      if (parallelProgress) {
        parallelProgress.style.width = `${progressPercentage}%`;
      }
    } else {
      progressPercentage = this.seekPercentage * 100;
      const originalProgress = this.originalProgresses.toArray()[0].nativeElement;
      if (originalProgress) {
        originalProgress.style.width = `${progressPercentage}%`;
      }
    }
  }
  private isTouchEvent(event: MouseEvent | TouchEvent): event is TouchEvent {
    return event.type.startsWith('touch');
  }
  // play pause button
  sharing: boolean = false;
  uid: string = '';
  updateProgressBar(): void {
    // Obter o player associado ao elemento de vídeo selecionado
    this.player = this.checkPlayer();
    ///////////////////////////////////////
    if (this.slicks && this.slicks.length > this.virtualIndex &&
      this.slicks[this.virtualIndex] &&
      this.slicks[this.virtualIndex].extra &&
      this.slicks[this.virtualIndex].extra.player &&
      this.slicks[this.virtualIndex].extra.player.fake_bar) {

      this.fakeBar = this.slicks[this.virtualIndex].extra.player.fake_bar;
    } else {
      this.fakeBar = false;
    }

    if (!this.progressContainers || !this.originalProgresses || !this.parallelProgresses) {
      return;
    }

    const progressContainer = this.progressContainers.toArray()[0]?.nativeElement;
    const originalProgress = this.originalProgresses.toArray()[0]?.nativeElement;
    const parallelProgress = this.parallelProgresses.toArray()[0]?.nativeElement;

    if (!this.player || !progressContainer) {
      return;
    }

    const rect = progressContainer.getBoundingClientRect();
    const currentTime = this.player.currentTime();
    const duration = this.player.duration();

    const normalSeekPercentage = (currentTime / duration) * 100;
    const normalClickX = (rect.width * normalSeekPercentage) / 100;

    let adjustedClickX = normalClickX;
    let progressPercentage;
    if (this.fakeBar) {
      if (normalSeekPercentage < 30) {
        progressPercentage = (normalSeekPercentage / 30) * 70;
        adjustedClickX = (rect.width * progressPercentage) / 100;
      } else {
        progressPercentage = 70 + ((normalSeekPercentage - 30) / 70) * 30;
        adjustedClickX = (rect.width * progressPercentage) / 100;
      }
      if (parallelProgress) {
        parallelProgress.style.width = `${progressPercentage}%`;
      }
    } else {
      progressPercentage = normalSeekPercentage;
      if (originalProgress) {
        originalProgress.style.width = `${progressPercentage}%`;
      }
    }
  }

  liked: boolean = false;
  isActive = false;
  triggerHeartAnimation() {
    this.isActive = true;
    this.events('like', '', 0);
    // Remover a animação depois de 2 segundos (duração da animação)
    let stm = setTimeout(() => {
      this.isActive = false;
      clearTimeout(stm);
    }, 2000); // 2 segundos é o tempo da animação
  }
  sendLike() {
    this.liked = this.liked == true ? false : true;
    this.isActive = true;
    this.events('like', '', 0);
    setTimeout(() => {
      this.isActive = false;
      this.liked = true; // Muda o estado para liked após o efeito
    }, 1000);
  }
  tempCtaId: number = 0;
  async handleLinkClickSwiper(link: any): Promise<void> {
    this.tempUrl = link.redirect_url;
    this.displayDialog = true;

    // Iniciar PiP
    if (this.player && document.pictureInPictureEnabled) {
      try {
        await this.player.requestPictureInPicture();
      } catch (error) {
      }
    }
    this.typeCtaEvent = 'link_swiper';
    this.tempCtaId = link.id;
    this.events('cta_events', this.typeCtaEvent, link.id);
  }

  typeCtaEvent: string = '';

  confirmRedirect(): void {
    localStorage.setItem(this.tempUrl, 'confirmed'); // Salva a confirmação no localStorage
    this.navigateTo(this.tempUrl);
    this.displayDialog = false;

    this.events('cta_redirect', this.typeCtaEvent, this.tempCtaId);
  }
  displayDialogWhats: boolean = false;
  displayDialogCustom: boolean = false;

  openWhatsApp(tab: WhatsAppTab): void {
    if (!document.pictureInPictureElement && this.player.requestPictureInPicture) {
      try {
        this.openPip();
        this.displayDialogWhats = true;
      } catch (error) {
      }
    } else {
      // PiP já está ativo, apenas abre o WhatsApp.
      this.displayDialogWhats = true;
    }

    if (tab.ddd && tab.fone) {
      this.tempUrl = `55${tab.ddd}${tab.fone}`;
    } else {
      this.tempUrl = 'Tel. com erro';
    }
    this.typeCtaEvent = 'whatsapp';
    if (tab.id) {
      this.tempCtaId = tab.id;
      this.events('cta_events', this.typeCtaEvent, tab.id);
    }
  }
  confirmRedirectWhats() {
    this.displayDialogWhats = false;
    const whatsappUrl = `https://wa.me/${this.tempUrl}`;
    window.open(whatsappUrl, '_blank');
    this.events('cta_redirect', this.typeCtaEvent, this.tempCtaId);
  }
  confirmRedirectCustom() {
    this.displayDialogCustom = false;
    window.open(this.tempUrl, '_blank');
    this.events('cta_redirect', this.typeCtaEvent, this.tempCtaId);
  }
  openWhatsAppUrl(tab: WhatsAppTab) {
    if (tab.ddd && tab.fone) {
      const phoneNumber = `55${tab.ddd}${tab.fone}`;
      const whatsappUrl = `https://wa.me/${phoneNumber}`;
      window.open(whatsappUrl, '_blank');
    }
  }
  shareAssets() {
    const activeIndex = this.getActiveSlideIndex();
    this.uid = this.slicks[activeIndex].content.uid;

    const currentHost = window.location.hostname;
    const protocol = window.location.protocol;
    const shareUrl = `${protocol}//${currentHost}?slick_id=${this.uid}`;

    if (navigator.share) {
      navigator.share({
        title: 'Confira nosso site',
        url: shareUrl
      }).then(() => {
      }).catch((error) => {
      });
    }
    this.events('share', '', 0);
  }

  isSharing: boolean = false;
  compartilhar() {
    const domainStore = localStorage.getItem('domain_store');

    // Verificar e obter o subdomínio
    let subdomain = '';
    if (domainStore) {
      const parts = domainStore.split('.');
      if (parts.length >= 2) {
        subdomain = parts[0];
      }
    }
    const currentUrl = window.location.href;
    const uidMatch = currentUrl.match(/\/slick\/([^/]+)/);
    const uid = uidMatch ? uidMatch[1] : '';

    const redirectUrl = `${window.location.protocol}//${subdomain ? subdomain + '.' : ''}${window.location.host}?slick_id=${uid}`;

    ///////////////////////
    if (navigator.share) {
      if (this.isSharing) {
        console.warn('Já existe uma operação de compartilhamento em andamento.');
        return;
      }

      this.isSharing = true;

      let urlShare = domainStore ? `https://slicksell.link/${subdomain}` + '?slick_id=' + this.uidSlick : ""
      navigator.share({
        title: 'Compartilhar Link',
        url: urlShare
      }).then(() => {
      }).catch(err => {
      }).finally(() => {
        this.isSharing = false;
      });
    }
  }

  events(type: string, details: string, id_cta: number): void {
    const activeIndex = this.getActiveSlideIndex();
    this.pMetaStore.sendCustomEvents(type, details, id_cta, this.slicks, activeIndex, this.tempUrl);
  }

  firstPlayEvent(): void {
    const activeIndex = this.getActiveSlideIndex();
    this.pMetaStore.sendFirstPlayEvent(activeIndex, this.slicks, this.playerSessionId);
  }

  pitchTimeEvent(): void {
    const activeIndex = this.getActiveSlideIndex();
    this.pMetaStore.sendPitchTimeEvent(activeIndex, this.slicks);
  }

  theaterMode: any = {};
  hasTheaterMode: boolean = false;
  originalStyle?: any;
  isFullScreen?: boolean = true;
  dynamicOPC: any = {};
  isInTheaterMode: boolean = false;
  columnSize?: string;
  //isRezing: boolean = false;
  @HostListener('window:resize')
  onResize() {
    this.setupResizeObserver();
    this.styleService.updateDynamicStyle(this, this.height);
    this.updateShortcutsPerSlide();
    //this.updateGridMinHeight();
  }

  dynamicOpacity: any = {};
  @Input() widget_id?: number;
  @Input() dynamicStyle: any = {};
  height?: number;
  @Input() slick_id?: string;

  firstSlide: boolean = false;
  @ViewChild('swiperElement', { static: false }) swiperElement!: SwiperComponent;

  modoTeatro() {
    if (this.isFullScreen) {
      if (this.viewMode == 2 && this.aspect_ratio == "2") {
        this.theaterMode = { 'height': '100%', 'width': 'auto' };
      }
      if (this.viewMode == 1 && this.aspect_ratio == "1") {
        this.theaterMode = { 'height': '100%', 'width': 'auto' };
      }
      this.isInTheaterMode = true;
    } else {
      this.isFullScreen = true;
      this.columnSize = 'col-12';
      this.toggleLayoutClass();
      if (this.viewMode == 2 && this.aspect_ratio == "2") {
        this.theaterMode = { 'height': 'auto', 'width': '100%' };
      }
      if (this.viewMode == 1 && this.aspect_ratio == "1") {
        this.theaterMode = { 'height': '100%', 'width': 'auto' };
      }
      this.isInTheaterMode = true;
    }
  }
  sairModoTeatro() {
    this.isInTheaterMode = false;
    if (this.isFullScreen) {
      if ((this.viewMode == 1 && this.aspect_ratio == '2') || (this.viewMode == 2 && this.aspect_ratio == '1')) {
        this.hasTheaterMode = false;
      } else {
        this.hasTheaterMode = true;
      }
      this.cdr.detectChanges();
      if (this.viewMode == 2 && this.aspect_ratio == '2') {
        this.theaterMode = { 'height': '100%', 'width': '100%' };
      }
      if (this.viewMode == 1 && this.aspect_ratio == '1') {
        this.theaterMode = { 'height': '100%', 'width': '100%' };
      }
    }
  }
  noPadding: boolean = false;
  toggleLayoutClass() {
    this.manangeLayoutService.toggleClass();
    this.noPadding = !this.noPadding;
  }

  private resizeObserverBreakPoints?: ResizeObserver;
  private resizeSubscriptions: Subscription[] = [];

  principal: boolean = false;
  hasAlerts: boolean = true;
  gridTemplateRows: string = '8% 10% 34.5% 10% 15% 22.5%';
  gridTemplateRowsMobileFullScreen: string = '8% 10% 35.5% 10% 18% 18.5%';

  updateGridRows() {
    // Ajuste as proporções do grid quando não há alertas
    this.gridTemplateRows = this.hasAlerts ? '8% 10% 34.5% 10% 15% 22.5%' : '8% 0% 44.5% 10% 15% 22.5%';
  }

  sumTotal: number = 0;


  tempoAtual: string = '00:00';
  durationTotal: string = '00:00';
  combinedTime$!: Observable<{ current: string | null, total: string | null }>;
  maxTimeWidth: number = 200;
  get timeWidth(): number {
    const activeIndex = this.getActiveSlideIndex();
    let currentTime = parseInt(this.tempoAtual);
    let totalDuration = parseInt(this.durationTotal);
    return Math.min((currentTime / totalDuration) * this.maxTimeWidth, this.maxTimeWidth);
  }
  get progressContainerWidth(): string {
    return `100%`;
  }

  showBtnVerMais: boolean[] = [];

  showBtnVerMaisFechar: boolean = false;
  truncatedDesc?: string = '';
  fullViewActive: boolean = false;
  slickDesc?: string = '';
  slickTitle?: string = '';
  slickTitles = [
    '',
    '',
    '',
  ];
  slickDescs = [
    '',
    '',
    '',
  ];
  truncatedDescs = [
    '',
    '',
    '',
  ];



  preventSwiperOnTouchStart(event: TouchEvent): void {
    // Previne que o Swiper capture o evento
    event.stopPropagation();
  }

  preventSwiperOnTouchMove(event: TouchEvent): void {
    const target = event.target as HTMLElement;
    if (target.scrollHeight > target.clientHeight) {
      // Permite o scroll na div
      event.stopPropagation();
    } else {
      // Caso contrário, evita o comportamento padrão do Swiper
      event.preventDefault();
    }
  }

  convertToRgba(hexColor: string | undefined, opacity: number): string {
    if (!hexColor) {
      return ''; // Retorne um valor padrão ou mantenha vazio
    }

    return hexColor + '30'; // Retorna a cor original caso algo dê errado ou o formato não seja esperado
  }
  async handleLinkClick(link: any): Promise<void> {
    this.tempUrl = link.redirect_url;
    this.displayDialog = true;
    if (this.player && document.pictureInPictureEnabled) {
      try {
        this.openPip();
      } catch (error) {
      }
    }
  }
}
