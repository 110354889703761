import { Component, Input, OnInit, ChangeDetectionStrategy, OnChanges, SimpleChanges, ViewEncapsulation, ChangeDetectorRef, Output, EventEmitter, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common'; import { WidgetHelperService } from 'src/app/services/widget-helper.service';
import { ToastModule } from 'primeng/toast'; import { WidgetDetailsResponse } from 'src/app/models/widget';
import { WidgetsService } from 'src/app/services/widgets.service';
import { CarouselComponent } from '../carousel/carousel.component';
import { StoriesComponent } from '../stories/stories.component';
import { GridComponent } from '../grid/grid.component';
import { IframeComponent } from '../iframe/iframe.component';
import { MessageService } from 'primeng/api';
import { PopupComponent } from '../popup/popup.component';
import { InlineComponent } from '../inline/inline.component';
import { StoreService } from 'src/app/services/store.service';
import { ButtonModule } from 'primeng/button';
import { Item } from 'src/app/models/liveshop';
import { SkeletonModule } from 'primeng/skeleton';
interface VideoItem {
  uid: string;
  id: number;
  src: string;   // URL do vídeo
  poster: string; // Poster do vídeo
  thumbnail?: string;
  title?: string;
  description?: string;
  showProduct?: boolean;
}
@Component({
  selector: 'app-widget-template',
  standalone: true,
  templateUrl: './widget-template.component.html',
  styleUrls: ['./widget-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    CarouselComponent,
    StoriesComponent,
    GridComponent,
    IframeComponent,
    SkeletonModule,
    PopupComponent,
    ButtonModule,
    InlineComponent,
    ToastModule
  ],
  encapsulation: ViewEncapsulation.Emulated,
})
export class WidgetTemplateComponent implements OnInit, OnChanges {

  @Input() containerDiv?: ElementRef<HTMLDivElement>;
  @Input() templateType?: string = '';
  @Input() popupPosition?: 'center' | 'top' | 'bottom' | 'left' | 'right' | 'topleft' | 'topright' | 'bottomleft' | 'bottomright';
  @Input() highlight?: string;
  @Input() selectedShape?: string;
  @Input() bg_color?: string;
  @Input() font_color: string = '#000000';
  @Input() height?: number | undefined;
  @Input() aspectRatio?: any;
  @Input() showProducts?: boolean = false;
  @Input() showDescription?: boolean = false;
  @Input() widget_id?: number | undefined;
  @Input() widget_uid?: string | undefined;
  @Input() store_id?: number | undefined;
  @Input() template_uid?: string | undefined;
  @Input() limit: number | null = null;
  @Input() reloadRequest?: EventEmitter<void>;
  @Input() isCol4: boolean = false;
  @Input() isAdmin: boolean = false;
  @Input() selectedPopupPosition: 'center' | 'top' | 'bottom' | 'left' | 'right' | 'topleft' | 'topright' | 'bottomleft' | 'bottomright' = 'bottomleft';
  @Input() showSmartphones: boolean = false;
  @Input() showDesktops: boolean = false;
  totalAssets: number = 0;
  @Output() openSidebarEvent = new EventEmitter<void>();
  selectedResolution: any;
  pageToVideos: number = 0;
  resolutions: { label: string; value: { width: number; height: number } }[] = [];
  loading: boolean = false;
  videoUrls: VideoItem[] = [];
  posterUrls: string[] = [];
  private currentPage = 1;
  hasMoreVideos: boolean = true; // Flag para indicar se há mais vídeos disponíveis
  @Output() newVideos = new EventEmitter<VideoItem[]>();
  @Input() refreshTrigger: boolean = false;
  isMobileDevice() { const isMobileUA = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent); const isTouchCapable = navigator.maxTouchPoints > 0; return isMobileUA || isTouchCapable; }

  constructor(
    private storeService: StoreService,
    private widgetHelperService: WidgetHelperService,
    private messageService: MessageService,
    private widgetService: WidgetsService,
    private cdr: ChangeDetectorRef) {


  }

  ngOnInit(): void {
    if (this.store_id) {
      this.storeService.detailStoreInfosByID(this.store_id).subscribe(
        (response: any) => {
          const storeInfo = {
            SelectedStoreId: response.id,
            store_logo: response.store_logo
          };
          const storeInfoJSON = JSON.stringify(storeInfo);
          localStorage.setItem('store', storeInfoJSON);

        },
        (error: any) => {
        }
      );
      
    }
    console.log('ngOnInit')

  }
  onOpenSidebar(): void {
    console.log('onOpenSidebar repassando evento');
    this.openSidebarEvent.emit();
  }

  updateStore(store_id: number | undefined){
    console.log('chamou updateStore')
    this.storeService.detailStoreInfosByID(store_id).subscribe(
      (response: any) => {
        const storeInfo = {
          SelectedStoreId: response.id,
          store_logo: response.store_logo
        };
        const storeInfoJSON = JSON.stringify(storeInfo);
        localStorage.setItem('store', storeInfoJSON);

      },
      (error: any) => {
      }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('ngOnChanges')
    if (changes['widget_id'] && this.widget_id) {
      this.currentPage = 1; // Resetar para a primeira página sempre que o widget_id mudar
      this.hasMoreVideos = true; // Sempre reiniciar com "mais vídeos disponíveis" ao mudar de widget
    }
    if (changes['height']) {
      console.log('height foi atualizada:', changes['height'].currentValue);
      this.cdr.detectChanges();
    }
    if (changes['showProducts']) {
      console.log('showProducts foi atualizada:', changes['showProducts'].currentValue);
    }
    if (changes['showDescription']) {
      console.log('showDescription foi atualizada:', changes['showDescription'].currentValue);
    }
    if (changes['widget_uid']) {
      console.log('widget_uid foi atualizada:', changes['widget_uid'].currentValue);
    }
    if (changes['store_id']) {
      console.log('store_id foi atualizada:', changes['store_id'].currentValue);
      if(changes['store_id'].currentValue){
        this.updateStore(changes['store_id'].currentValue);
      }
      
    }
    if (changes['isAdmin']) {
      console.log('isAdmin foi atualizada:', changes['isAdmin'].currentValue);
    }
    if (changes['refreshTrigger']) {
      this.videoUrls = [];
      this.arraySlicksFull = [];
      this.currentPage = 1;
      this.hasMoreVideos = true;
      this.loadVideos();
      console.log('refreshTrigger foi atualizada widget:', changes['refreshTrigger'].currentValue);
    }
    if (changes['widget_id']) {
      console.log('widget_id foi atualizada:', changes['widget_id'].currentValue);
      if (!this.isAdmin) {
        this.widgetDetail();
      }

      //this.cdr.detectChanges();
    }
    if (changes['limit']) {
      console.log('limit foi atualizada:', changes['limit'].currentValue);
      this.cdr.detectChanges();
    }
    if (changes['containerDiv']) {
      console.log('containerDiv foi atualizada widget:', changes['containerDiv'].currentValue);
      this.cdr.detectChanges();
    }
    if (changes['isCol4']) {
      console.log('isCol4 foi atualizada widget:', changes['isCol4'].currentValue);
      this.cdr.detectChanges();
    }
    if (changes['font_color']) {
      console.log('font_color foi atualizada widget:', changes['font_color'].currentValue);
    }
    if (changes['showSmartphones']) {
      console.log('showSmartphones foi atualizada widget:', changes['showSmartphones'].currentValue);
      this.showSmartphones = changes['showSmartphones'].currentValue;
    }
    if (changes['showDesktops']) {
      console.log('showDesktops foi atualizada widget:', changes['showDesktops'].currentValue);
      this.showDesktops = changes['showDesktops'].currentValue;
    }
  }
  widgetDetail() {
    if (this.widget_uid) {
      this.widgetService.detailWidget(this.widget_uid).pipe().subscribe({
        next: (res: WidgetDetailsResponse) => {
          if (res.style) {
            const styleKeys = Object.keys(res.style);
            if (styleKeys.length > 0) {
              this.templateType = styleKeys[0];
            }
          }
          if (res.style && res.style.popup) {
            const styleKeys = Object.keys(res.style);
            if (styleKeys.length > 0) {
              this.templateType = styleKeys[0];
            }
            this.highlight = res.style.popup.highlight || '';
            this.selectedShape = res.style.popup.shape || 'circle';
            this.bg_color = res.style.popup.background_color || '#ffffff';
            this.font_color = res.style.popup.font_color || '#000000';
          }
          if (res.style && res.style.carousel) {
            this.showProducts = res.style.inline.showProducts;
            this.highlight = res.style.popup.highlight || '';
            this.bg_color = res.style.popup.background_color || '#ffffff';
            this.font_color = res.style.popup.font_color || '#000000';
            const position = res.style.carousel.position;
            this.selectedPopupPosition = position && ['center', 'top', 'bottom', 'left', 'right', 'topleft', 'topright', 'bottomleft', 'bottomright'].includes(position)
            ? this.widgetHelperService.mapPositionValue(position)
            : 'bottomleft';
          }
          if (res.style && res.style.stories) {
            this.showProducts = res.style.inline.showProducts;
            this.highlight = res.style.popup.highlight || '';
            this.bg_color = res.style.popup.background_color || '#ffffff';
            this.font_color = res.style.popup.font_color || '#000000';
            const position = res.style.stories.position;
            this.selectedPopupPosition = position && ['center', 'top', 'bottom', 'left', 'right', 'topleft', 'topright', 'bottomleft', 'bottomright'].includes(position)
            ? this.widgetHelperService.mapPositionValue(position)
            : 'bottomleft';
          }
          this.resolutions = this.widgetHelperService.getResolutions();
          if (res.style && res.style.iframe && res.style.iframe.selectedResolution) {
            const savedResolution = res.style.iframe.selectedResolution;
            const resolution = this.resolutions.find(
              r => r.value.width === savedResolution.width && r.value.height === savedResolution.height
            );

            if (resolution) {
              this.height = resolution.value.height;
            }


          }
          if (res.style && res.style.inline) {
            this.showDescription = res.style.inline.showDescription;
            this.showProducts = res.style.inline.showProducts;
            console.log('entrou no inline ')
            console.log(res.style.inline.selectedResolution);

            const savedResolution = res.style.inline.selectedResolution;
            if (savedResolution) {
              console.log('tem savedResolution no inline ')
              const resolution = this.resolutions.find(
                r => r.value.width === savedResolution.width && r.value.height === savedResolution.height
              );

              if (resolution) {
                this.selectedResolution = resolution.value;
                this.height = resolution.value.height;
              }
            }
            this.showSmartphones = res.widget_rule.device_display.mobile_display;
            this.showDesktops = res.widget_rule.device_display.desktop_display;

          }
          console.log('tipo do template:', this.templateType)
        },
        error: (err) => {
          this.loading = false;
        }
      });
    }
  }
  capitalizeFirstLetter(text: string | undefined): string {
    if (!text) return '';
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
  arraySlicksFull: Item[] = [];
  @Output() loadingComplete = new EventEmitter<void>();
  loadVideos(): void {
    console.log('loadVideos')
    if (!this.hasMoreVideos) {
      this.loadingComplete.emit();
    }
    if (!this.widget_id || !this.hasMoreVideos) return;

    this.loading = true;
    this.storeService.listarAssetsWidget2(this.widget_id, this.currentPage, this.limit).subscribe(
      (response: any) => {
        console.log('WidgetTemplateComponent: ', response);
        this.loading = false;

        // Atualize arraySlicksFull com todos os itens recebidos
        const newItems = response.assets.flatMap((asset: any) => asset.items);
        this.arraySlicksFull = [...this.arraySlicksFull, ...newItems];
        //this.arraySlicksFull = newItems.filter((item: Item) => Object.keys(item).length > 0);

        const totalNewItems = newItems.length;
        this.pageToVideos = Math.ceil(totalNewItems / 3);
        console.log('pageToVideos calculado:', this.pageToVideos);

        const newVideoItems = response.assets.flatMap((asset: any) =>
          asset.items.map((item: any) => ({
            src: item.media.src,
            thumbnail: item.media.thumbnail ?? 'assets/imgTmp.webp',
            title: item.content.title,
            uid: item.content.uid,
            id: item.content.id,
            description: item.content.description,
          }))
        );

        this.totalAssets = response.totalAssets;
        // Se a resposta não trouxer novos vídeos, significa que não há mais vídeos para carregar
        if (newVideoItems.length === 0) {
          this.hasMoreVideos = false;
        } else {
          if (this.currentPage === 1) {
            this.videoUrls = [...newVideoItems];
          } else {
            this.videoUrls = [...this.videoUrls, ...newVideoItems];
          }

          // Incrementar a página
          this.currentPage++;

        }

        this.highlight

        this.newVideos.emit(this.videoUrls);
        this.loadingComplete.emit();
        console.log('loadingComplete')
        // Forçar detecção de mudanças para que o DataView seja atualizado imediatamente
        this.cdr.detectChanges();
      },
      (error: any) => {
        this.loading = false;
        this.handleError(error);
      }
    );
  }

  loadMoreVideosForGrid(): void {
    console.log('LazyLoad de grid');
    if (this.templateType === 'grid' && !this.loading) {
      this.loadVideos();
    }
  }

  loadMoreVideosForCarousel(): void {
    console.log('LazyLoad de carousel');
    if ((this.templateType === 'carousel' || this.templateType === 'stories') && !this.loading) {
      this.loadVideos();
    }
  }

  handleError(error: any): void {
    let message = '';
    if ([400, 403, 401, 404, 429].includes(error.status)) {
      message = error.error.message;
    } else if (error.status === 500) {
      message = "Ops... Algo de errado aconteceu. Tente novamente mais tarde.";
    }
    if (message) {
      this.showErrorDialog(message);
    }
    console.error('erro:', error);
  }

  showErrorDialog(message: string) {
    this.messageService.add({ severity: 'error', summary: 'Erro', detail: message, icon: 'pi pi-times' });
  }
}
