<!-- <app-notfound [messageType]="2" *ngIf="zeroVideos"></app-notfound> -->
<!-- <div class="layout-preloader-container" >
    <div class="layout-preloader">
    </div>
</div> -->
<div #overlayContainer class="flex align-items-center justify-content-center ss-container-to-sidebar" style="position: relative;">
    <!-- <div #progressBarTime class="progress-times ml-1 ss-g-custom-bg ss-l-time-display" *ngIf="!typeLive"
        [ngStyle]="{'width.px': timeWidth}">
        <span class="text-white mr-1 font-bold">{{ displayedTime }}</span>
        <span class=" text-white font-light">/</span>
        <span class="text-white ml-1">{{ durationTotal | formatDuration }}</span>
    </div> -->
    <div class="col-12 p-0 unselectable" style="position: relative;"
        [style.height.px]="height && height > 0 ? height : null">
        <!-- -->
        <div #progressContainerWrapper class="col-12 p-0 ss-l-progress-container-wrapper"
            [ngStyle]="combinedStylesBar()">

            <div #progressContainer class="ss-l-progress-container z-9" style="z-index: 15000;"
                [ngStyle]="{'width': progressContainerWidth}"
                [ngClass]="{'ss-l-progress-container-fullscreen': fullScreen, 'ss-g-progress-container-preview': !fullScreen}">

                <div #originalProgress class="ss-l-original-progress" [hidden]="fakeBar"
                    [ngStyle]="{'--progress-bar-color': progressBarColor}">
                </div>

                <div #parallelProgress class="ss-l-parallel-progress" [hidden]="!fakeBar"
                    [ngStyle]="{'--progress-bar-color': progressBarColor}">
                </div>

                <!-- A marker fica dentro da barra de progresso -->
                <!-- <div #marker class="ss-l-marker" style="position: absolute;"></div> -->
                <!-- Garante que a marker se posicione corretamente -->

                <div #thumbnailContainer class="ss-l-thumbnail-container" [style.position]="'absolute'"
                    [style.display]="'none'">
                    <!-- Miniatura -->
                    <div #thumbnailPreview class="ss-l-thumbnail-preview" [style.display]="'none'"
                        [style.position]="'absolute'" [style.width.px]="aspect_ratio === '1' ? 90 : 160"
                        [style.height.px]="aspect_ratio === '1' ? 160 : 90" [ngStyle]="{'backgroundSize': 'cover'}"
                        [ngClass]="{'sprite-vertical': aspect_ratio === '1', 'sprite-horizontal': aspect_ratio === '2'}">
                    </div>
                    <!-- Tempo -->
                    <div #timeDisplay class="ss-l-time-display">
                        <span class="text-white mr-1 font-bold">{{ displayedTime }}</span>
                        <span class="text-white font-light">/</span>
                        <span class="text-white ml-1">{{ durationTotal | formatDuration }}</span>
                    </div>
                </div>
            </div>
        </div>



        <!-- -->
        <swiper [config]="swiperConfig" *ngIf="InicialSlicks.length" class="swiper-container ss-l-swiper-videos swiper"
            #swiperElement (swiper)="onSwiper($event)" (wheel)="onScroll($event)" (touchmove)="onScroll($event)"
            (activeIndexChange)="onActiveIndexChange($event)">
            <ng-template swiperSlide *ngFor="let slick of InicialSlicks; let i = index; trackBy: trackByFn">
                <div #videoUploadContainer class="relative video-container 123" [ngClass]="{
                'ss-g-video-container': viewMode === 1,
              }" [ngStyle]="combinedStylesWidth">

                    <div class="videoUploadContainer flex" #vc2 [ngClass]="{
                    'ss-g-view-mode-mobile': aspect_ratio === '1',
                    'ss-g-view-mode-desktop': aspect_ratio === '2',
                    'ss-g-view-mode-desktop-vertical': aspect_ratio === '2' && viewMode === 2
                  }" [ngStyle]="combinedStylesWidth">

                        <div #videoInner class="text-center flex video-inner modoTeatro" [ngClass]="{
                        'video-upload-container-full-vertical-desktop': (aspect_ratio === '1'),
                        'ss-g-view-mode-mobile-inner': aspect_ratio === '1',
                        'ss-g-video-container-inner-landscape': aspect_ratio === '2' }" [ngStyle]="theaterMode">
                            <video #videoElement id="my-video-{{i}}" [class]="'my-video-' + i " [ngStyle]="dynamicOPC"
                                class="video-js vjs-default-skin video-preview ss-l-muteClass ss-l-video" muted
                                playsinline webkit-playsinline preload="auto">
                            </video>
                            <div class="ss-l-poster"
                                [ngStyle]="{'background-image': 'url(' + slick.media.thumbnail + ')'}">
                            </div>
                        </div>

                        <div (click)="$event.stopPropagation();"
                            style="position: absolute; left: 0rem; right: 0rem; background-color: transparent !important;">
                            <div class="ss-g-controls aaa" *ngIf="isPaused">
                                <div class="text-white" *ngIf="showPlayPauseButton">
                                    <button (click)="togglePlayPause(); $event.stopPropagation();" #playPauseButton
                                        class="ss-l-control-button text-2xl zoomin animation-duration-100 cursor-pointer"
                                        style="z-index: 1002;" pButton pRipple
                                        [ngStyle]="{'background-color': playPauseColor}">
                                        <i class="bi bi-play-fill " *ngIf="isPaused"
                                            [ngStyle]="{'color': iconColor}"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <!-- grid aqui obs-->
                <div #gridContainer class="grid mx-0 position-grid gridContainer unselectable" [ngClass]="{
            'ss-g-view-mode-mobile': viewMode === 1,
            'ss-g-view-mode-desktop': viewMode === 2,
            'ss-g-view-mode-desktop-vertical': aspect_ratio === '2' && viewMode === 1
          }" [ngStyle]="mergedStyles" (click)="togglePlayPause()">

                    <div class="top-1 pt-3" [class.pr-3]="!isMobile" [class.pl-3]="!isMobile" [class.pr-2]="isMobile"
                        [class.pl-2]="isMobile">
                        <div (click)="$event.stopPropagation();" class="ss-l-logo-container">
                            <img class="ss-l-logoStore" [src]="logoStore" alt="Logo" />

                        </div>
                        <div class="ss-l-right-container">
                            <!-- <div class="ss-l-numUserChat-ex text-center" (click)="$event.stopPropagation();">
                            <div class="ss-l-numUserChat-in text-white"><i class="bi bi-person-fill"></i> {{
                                currentNumUsers }}
                            </div>
                        </div> -->

                        </div>
                    </div>
                    <div class="top-2" [class.pr-0]="!isMobile" [class.pl-3]="!isMobile" [class.pr-0]="isMobile"
                        [class.pl-2]="isMobile">
                        <div *ngIf="screenAlertsBtn[i] && showCTAs" #targetElement
                            class="ss-g-postionAlertsGrid ss-l-element" [class.text-left]="!showSwiper">
                            <!-- Swiper, mostrado somente se showSwiper for true -->
                            <swiper *ngIf="showSwiper" [config]="configSwiper" class="mySwiper ss-g-fade-out-swiper">
                                <ng-template swiperSlide *ngFor="let alert of alertsPerSlide[i]; trackBy: trackById">
                                    <div class="border-round alert-child cursor-pointer link-container ss-l-slide-element"
                                        (click)="$event.stopPropagation()"
                                        [style.backgroundColor]="convertToRgba(alert.background ?? 'rgb(0, 0, 0)', 0.5)"
                                        [style.color]="alert.font_color"
                                        style="max-height: 5vh; display: flex; align-items: center; justify-content: space-between; min-height: 3rem; padding-right: 1rem;">
                                        <i class="bi bi-bell-fill mr-2 ss-g-bellAlertsFix"></i>
                                        <span class="flex-grow-1 text-ellipsis ml-2">{{ alert.text }}</span>
                                        <i class="pi pi-times"
                                            (click)="toggleSwiperVisibility(); $event.stopPropagation();"></i>
                                    </div>
                                </ng-template>
                            </swiper>

                            <!-- Badge, mostrado somente se showSwiper for false -->
                            <button *ngIf="!showSwiper && numberOfAlerts[i] > 0" (click)="showAlerts(); $event.stopPropagation();"
                                class="m-0 border-none ss-g-custom-bg ss-g-btns-menu-sup ss-g-btns-menu-right" pButton pRipple>
                                <i pBadge="" [value]="numberOfAlerts[i].toString()"
                                    class="p-element pi bi bi-bell-fill mr-4 p-overlay-badge ss-g-sino">
                                    <span class="p-badge p-component p-badge-no-gutter">{{ numberOfAlerts[i] }}</span>
                                </i>
                            </button>
                        </div>
                    </div>
                    <div class="middle-left text-center" [class.pr-0]="!isMobile" [class.pl-3]="!isMobile"
                        [class.pr-0]="isMobile" [class.pl-2]="isMobile">
                        <div class="middle-left-top text-left">

                        </div>
                        <div class="middle-left-bottom text-center">

                        </div>
                    </div>
                    <div class="middle-center"></div>
                    <div class="middle-right" #btnsRightSup [class.pr-3]="!isMobile" [class.pr-1]="isMobile">
                        <div #targetElement class="ss-l-btns-right-sup ss-g-alignItensEnd ss-l-element">
                            <div class="positionLinks">
                                <div class="flex-container-links2">
                                    <div *ngFor="let link of shortcutsPerSlide[i]; trackBy: trackByFn"
                                        class="text-center">
                                        <div class="card p-1 shadow-none text-center my-0 ss-l-links ss-g-links-shorts  ss-l-links-shorts-style"
                                            (click)="handleLinkClickShortcut(link); $event.stopPropagation()"
                                            [ngStyle]="{'background-color': link.bg_color}">
                                            <img [src]="link.click_url" loading="lazy" />
                                        </div>
                                        <div class="text-white text-sm ss-l-text-sm">{{ link.name }}</div>
                                    </div>
                                    <button *ngIf="hasLinks" pButton pRipple
                                        (click)="openSidebarCTALinks(); $event.stopPropagation()" icon="pi pi-plus"
                                        class="ss-g-btn-plus-links border-none"></button>
                                </div>

                            </div>
                        </div>
                        <div class="ss-l-element " #targetElement>
                            <div *ngFor="let buttonWhats of whatsAppButtonsPerSlide[i]; let i = index"
                                class="ss-l-custom-btn-whats">
                                <div *ngIf="buttonWhats">
                                    <button *ngIf="buttonWhats?.show_item"
                                        class="ss-g-whatsapp-btn p-0 m-0 border-none ss-g-custom-bg ss-g-btns-menu-sup ss-g-btns-menu-right"
                                        pButton pRipple (click)="openWhatsApp(buttonWhats); $event.stopPropagation()">
                                        <i class="bi bi-whatsapp"></i>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="text-center ss-l-btns-right-inf mb-4 ss-g-alignItensEnd">
                            <div>
                                <button *ngIf="!screenChat" (click)="openSreenChat(); $event.stopPropagation();" pButton
                                    pRipple
                                    class="m-0 border-none ss-g-custom-bg ss-g-btns-menu-sup ss-g-btns-menu-right">
                                    <i class="bi bi-chat-fill"></i>
                                </button>
                                <button *ngIf="screenChat" (click)="openSreenChat(); $event.stopPropagation();" pButton
                                    pRipple
                                    class="m-0 border-none ss-g-custom-bg ss-g-btns-menu-sup ss-g-btns-menu-right">
                                    <i class="bi bi-chat-fill"></i>
                                </button>
                            </div>
                            <div>


                                <button (click)="triggerHeartAnimation(); $event.stopPropagation();"
                                    class="m-0 border-none ss-g-custom-bg ss-g-btns-menu-sup ss-g-btns-menu-right"
                                    pButton pRipple [ngClass]="{'liked': isActive}">
                                    <i class="bi bi-heart-fill" [ngClass]="{'ss-g-heartBgColor': isActive}"
                                        style="position: relative;top:3px;"></i>
                                </button>

                            </div>
                            <div>
                                <button pButton pRipple class="m-0 border-none ss-g-custom-bg  ss-g-btns-menu-right"
                                    (click)="compartilhar();$event.stopPropagation();" style="border: none;">
                                    <i class="bi bi-share-fill"></i>
                                </button>
                            </div>
                            <div>
                                <button pButton pRipple type="button"
                                    (click)="menu.toggle($event);$event.stopPropagation();"
                                    class="m-0 border-none ss-g-custom-bg  ss-g-btns-menu-right">
                                    <i class="bi bi-three-dots-vertical"></i>
                                </button>
                                <p-menu #menu class="s-g-menuLoja" [model]="getActionMenu()" [popup]="true"
                                    appendTo="body" [baseZIndex]="10001"
                                    overlayOptions="{ showTransitionOptions: '0ms', hideTransitionOptions: '0ms' }"></p-menu>
                            </div>
                            <div>
                                <button pButton pRipple (mousemove)="showVolumeControl()"
                                    (click)="toggleMute(); $event.stopPropagation();" (touchstart)="showVolumeControl()"
                                    class="m-0 border-none ss-g-custom-bg  ss-g-btns-menu-right">
                                    <i [ngClass]="isVideoMuted() ? 'bi bi-volume-mute-fill' : 'bi bi-volume-up'"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="bottom-0 pl-3">

                    </div>
                    <div class="bottom-1 pl-3">

                    </div>
                    <div class="bottom-2 pl-3"></div>
                    <div class="bottom-3  pl-3" #bottomDiv [class.pl-1]="isMobile">
                        <div class="p-tag p-component p-tag-warning ss-l-element flex text-center mb-2" #targetElement>
                            <i class="bi bi-person-fill" style="margin-right: 0.3rem;"></i>
                            {{ currentNumUsers }} views
                        </div>
                        <div class="text-white w-full font-bold select-none text-left ss-l-element " #targetElement>
                            {{slickTitles[i]}}
                        </div>
                        <div class="text-white w-full mb-2 cursor-pointer select-none ss-l-element " #targetElement
                            (click)="handleDivClick($event); $event.stopPropagation();"
                            style="text-align: left; max-height: auto; overflow-y: auto;"
                            [ngStyle]="{'max-height': dynamicHeight, 'overflow-y': 'auto'}"
                            (touchstart)="preventSwiperOnTouchStart($event)"
                            (touchmove)="preventSwiperOnTouchMove($event)">
                            {{truncatedDescs[i]}}
                            <a (click)="goVerMais(); $event.stopPropagation();" class="text-white cursor-pointer"
                                *ngIf="showBtnVerMais[i] && truncatedDescs[i].length > 0"><b>ver mais</b></a>

                        </div>

                        <div #targetElement class="ss-l-custom-btn-screen mb-2 ss-l-element "
                            style="display: flex; justify-content: flex-start;">
                            <div *ngFor="let button of buttonsPerSlide[i]; let i = index" class="w-full md:max-w-25rem">
                                <button *ngIf="button?.show_item" pButton pRipple
                                    class="font-bold py-1 ss-g-btn-screen-custom border-none ss-l-w-full md:max-w-25rem"
                                    [ngStyle]="{'color': button.font_color_custom, 'background-color': button.bg_color_custom}"
                                    (click)="handleLinkClickShortcut(button); $event.stopPropagation()">
                                    {{ button.button_text }}
                                </button>
                            </div>
                        </div>

                        <div #targetElement class="w-full mb-3 ss-l-element ">
                            <swiper [config]="configSwiperThird" class="mySwiperThird ss-g-fade-out-swiper">
                                <ng-template swiperSlide *ngFor="let link of linksPerSlide[i]; trackBy: trackByFn">
                                    <div class="link-container cursor-pointer"
                                        (click)="handleLinkClick(link); $event.stopPropagation();">
                                        <div class="link-card"
                                            [ngStyle]="{'background-color': convertToRgba(link.bg_color ?? 'rgb(0, 0, 0)', 0.5)}">
                                            <!-- Área da Imagem -->
                                            <div class="link-image" [ngStyle]="{'background-color': link.bg_color}">
                                                <img src="{{link.click_url}}" [alt]="link.name" class="image-style"
                                                    loading="lazy">
                                            </div>
                                            <!-- Conteúdo do Texto -->
                                            <div class="link-name text-sm text-white"
                                                [ngStyle]="{'background-color': convertToRgba(link.bg_color ?? 'rgb(0, 0, 0)', 0.5)}">
                                                <div class="text-white">{{link.name}}</div>
                                            </div>
                                            <!-- Ícone da Direita -->
                                            <div class="link-icon"
                                                [ngStyle]="{'background-color': convertToRgba(link.bg_color ?? 'rgb(0, 0, 0)', 0.5)}">
                                                <i class="pi pi-chevron-right"></i>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </swiper>

                        </div>
                        <div (click)="$event.stopPropagation();" class="grid grid-nogutter w-full mb-4">
                            <div
                                class="col-12 ss-l-div-inf-volume-power flex justify-content-center align-items-center">
                                <div class="text-center text-xs">
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.2" viewBox="0 0 879 152"
                                        style="height: 20px; max-width: 80px;">
                                        <style>
                                            .a {
                                                fill: #fff
                                            }
                                        </style>
                                        <path class="a"
                                            d="m6.7 103v-18.9q3.3-0.6 6.5-0.6 4.7 0 7 1.7 2.3 1.8 2.3 5.4 0 3.5-2.2 5.3-2.2 1.8-6.6 1.8-0.4 0-1.2 0h-0.6v5.3zm7-9q2 0 2.9-0.8 0.9-0.8 0.9-2.6 0-1.7-0.9-2.5-0.9-0.8-2.9-0.8-1 0-1.8 0.1v6.4q1 0.2 1.8 0.2zm21 9.3q-3.1 0-5.4-1.2-2.2-1.1-3.5-3.3-1.2-2.2-1.2-5.4 0-3.2 1.2-5.4 1.3-2.2 3.5-3.4 2.3-1.1 5.4-1.1 3.1 0 5.4 1.1 2.3 1.2 3.5 3.4 1.2 2.2 1.2 5.4 0 3.1-1.2 5.3-1.2 2.3-3.5 3.4-2.3 1.2-5.4 1.2zm0-4.2q2.3 0 3.6-1.4 1.2-1.4 1.2-4.2 0-3-1.2-4.4-1.3-1.5-3.6-1.5-2.3 0-3.5 1.4-1.3 1.4-1.3 4.3 0 3 1.2 4.4 1.3 1.4 3.6 1.4zm33.9-0.6l4-14.7h4.9l-5.4 19.2h-6.6l-3.9-13.8-4 13.8h-6.6l-5.4-19.2h5.3l3.8 14.5 4.1-14.5h5.8zm16.1-3.4v3.8h10v4h-15.2v-19.2h15v4h-9.8v3.4h8.5v4zm30.1 7.8h-5.6l-4.8-6.7q-0.3 0-0.7 0-0.4 0-0.6 0h-0.6v6.7h-5.1v-18.7q1.5-0.3 3.3-0.5 1.8-0.3 3.4-0.3 4.5 0 6.8 1.6 2.2 1.5 2.2 4.8 0 2-0.9 3.4-1 1.4-2.8 2.2zm-12.3-15.6v5q1.2 0.1 2.2 0.1 1.8 0 2.7-0.6 0.8-0.6 0.8-2 0-1.4-0.8-2.1-0.9-0.6-2.7-0.6-1.1 0-2.2 0.2zm19.3 7.8v3.8h10v4h-15.2v-19.3h15v4h-9.8v3.4h8.5v4.1zm18.7 8q-1.4 0-3-0.1-1.6-0.1-3-0.3l0.1-18.5q1.4-0.4 3.3-0.6 1.9-0.2 3.7-0.2 5.7 0 8.7 2.4 2.9 2.5 2.9 7.5 0 3.4-1.4 5.6-1.4 2.1-4.2 3.2-2.8 1-7.1 1zm1.4-4.2q2.1 0 3.4-0.5 1.3-0.6 1.9-1.9 0.6-1.2 0.6-3.3 0-2.9-1.4-4.3-1.5-1.3-4.5-1.3-0.5 0-1.1 0-0.6 0.1-1 0.1v11.1q0.8 0.1 2.1 0.1zm33.8-5.9q1.6 0.3 2.6 1.4 0.9 1.1 0.9 3 0 1.9-1 3.1-1 1.3-3.2 2-2.3 0.6-6 0.6-1.3 0-3-0.1-1.7-0.1-3-0.3v-18.5q1.5-0.4 3.4-0.6 1.8-0.2 3.5-0.2 4.6 0 6.7 1.4 2.1 1.3 2.1 4.1 0 1.5-0.8 2.6-0.8 1.1-2.2 1.5zm-5.2-6q-1.2 0-2.4 0.3v3.9h2.6q1.7 0 2.4-0.5 0.7-0.5 0.7-1.6 0-0.7-0.3-1.2-0.3-0.4-1-0.6-0.7-0.3-2-0.3zm-0.1 12.5q1.6 0 2.4-0.3 0.9-0.2 1.3-0.7 0.4-0.5 0.4-1.3 0-0.8-0.4-1.3-0.3-0.5-1-0.7-0.7-0.3-1.9-0.3h-3.1v4.5q1 0.1 2.3 0.1zm22.5-15.9h5.2l-7.1 11.3v8h-5.2v-8l-7.3-11.3h5.5l4.5 7.1z">
                                        </path>
                                        <path class="a"
                                            d="m288.9 65.7h22.9c-0.3-20.1-16.9-34-41.9-34-24.8 0-42.9 13.7-42.8 34.2 0 16.6 11.7 26.1 30.8 30.7l12.2 3.1c12.3 2.9 19.1 6.4 19.2 14-0.1 8.3-7.9 13.9-20 13.9-12.4 0-21.3-5.7-22.1-17h-23c0.6 24.4 18 37 45.4 37 27.5 0 43.7-13.2 43.7-33.8 0-18.8-14.2-28.7-33.8-33.1l-10.1-2.5c-9.8-2.2-18-5.8-17.8-13.9 0-7.3 6.4-12.6 18.1-12.6 11.4 0 18.4 5.1 19.2 14zm63.4-32.4h-23.4v112.7h23.4zm18.7 112.7h23.5v-84.5h-23.5zm11.8-95.5c7 0 12.7-5.3 12.7-11.9 0-6.4-5.7-11.8-12.7-11.8-6.9 0-12.7 5.4-12.7 11.8 0 6.6 5.8 11.9 12.7 11.9zm68.6 97.1c22.5 0 36.6-13.2 37.7-32.6h-22.1c-1.4 9-7.3 14.1-15.3 14.1-11 0-18.1-9.2-18.1-25.4 0-16 7.2-25.1 18.1-25.1 8.5 0 14 5.7 15.3 14.1h22.1c-1-19.5-15.8-32.4-37.8-32.4-25.7 0-41.5 17.8-41.5 43.7 0 25.7 15.5 43.6 41.6 43.6zm52.8-1.6h23.5v-26.9l6.3-7.2 23.1 34.1h27.4l-32.9-48 31.3-36.6h-26.9l-27.1 32.1h-1.2v-60.2h-23.5zm156.1-84.6h13.2c-0.6-17-16.3-29.7-38.1-29.7-21.5 0-38.5 12.5-38.5 31.5 0 15.2 11 24.2 28.6 29.3l13.9 3.9c11.9 3.3 22.5 7.5 22.5 18.7 0 12.4-11.9 20.5-27.6 20.5-13.4 0-25.3-5.9-26.4-18.7h-14.1c1.4 18.5 16.3 31 40.5 31 26 0 40.8-14.3 40.8-32.5 0-21.2-20.1-28-31.7-31.1l-11.5-3.1c-8.3-2.2-21.8-6.6-21.8-18.7 0-10.8 9.9-18.7 24.9-18.7 13.7 0 24 6.5 25.3 17.6zm70.9 86.3c17.2 0 29.7-8.6 33.7-21.3l-12.5-3.6c-3.3 8.8-11 13.2-21.2 13.2-15.2 0-25.7-9.8-26.3-27.9h61.3v-5.5c0-31.5-18.7-42.3-36.3-42.3-22.9 0-38.1 18.1-38.1 44 0 26 15 43.4 39.4 43.4zm-26.3-50.8c0.8-13.2 10.1-24.9 25-24.9 14.1 0 23.1 10.5 23.1 24.9zm94.2-63.7h-13v112.8h13zm36.8 0h-13v112.7h13z">
                                        </path>
                                        <path class="a"
                                            d="m848.5 8.1h3.8v9.9h2.9v-9.9h3.8v-2.5h-10.5zm11.9-2.4v12.4h3v-7.7h0.1l2.9 7.6h1.9l3-7.5h0.1v7.6h2.9v-12.4h-3.7l-3.2 7.7h-0.1l-3.2-7.7z">
                                        </path>
                                    </svg>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <!-- fim grid aqui obs-->


            </ng-template>
        </swiper>



        <p-sidebar [(visible)]="showAllShortucutLinks" (onHide)="onClose()" (onShow)="adjustOverlayZIndex()" 
            class="ss-g-custom-sidebar-width ss-l-pSidebarChat ss-l-sideBarBottom z-9 p-0" position="bottom"
            [style]="{ position: 'absolute', width: isMobile ? '100%' : '50%', height: '65%', left: isMobile ? '0' : '50%', transform: isMobile ? 'none' : 'translateX(-50%)' }">
            <ng-template pTemplate="header">
                <div style="display: flex; justify-content: space-between; align-items: center;">
                    <span class="text-xl font-bold">Links</span>
                </div>
            </ng-template>
            <!-- Container Flex para alinhar os links lado a lado -->
            <div class="ss-g-custom-container cursor-pointer">
                <div *ngFor="let link of getFlattenedShortcuts(realActiveIndex); trackBy: trackByFn" class="ss-g-links2"
                    (click)="handleLinkClickShortcut(link); $event.stopPropagation();">
                    <img [src]="link.click_url" class="border-circle ss-g-maxheight-links-adds" loading="lazy" />
                    <div class="ss-g-maxWidthName-links-adds">{{ link.name }}</div>
                </div>
            </div>
        </p-sidebar>
        <p-sidebar [(visible)]="chatAutorization" (onHide)="onClose()" (onShow)="moveOverlayToComponent()"
            class="ss-g-custom-sidebar-width ss-l-pSidebarChat ss-l-sideBarBottom z-1 p-0 ss-g-sidebarChat"
            position="bottom"
            [style]="{ position: 'absolute', width: isMobile ? '100%' : '50%', height: '45%', left: isMobile ? '0' : '50%', transform: isMobile ? 'none' : 'translateX(-50%)' }">

            <div class="p-field mb-2 text-center">
                <i class="bi bi-wechat text-6xl mb-3"></i>
                <label for="buttonText" class="ss-g-globalClassLabel">Para conversar pelo chat preencha as informações
                    abaixo:</label>
                <input class="mt-2 w-full mb-4" type="text" pInputText maxlength="40" [(ngModel)]="username"
                    placeholder="Nome" />

                <div class="mb-4 flex align-items-center justify-content-center">
                    <input type="checkbox" [(ngModel)]="acceptedTerms" class="mr-2" />
                    <label for="terms" class="m-0">Li e concordo com os <a href="#" target="_blank">termos e
                            condições.</a></label>
                </div>
                <button pButton pRipple label="Participar do Chat" class="w-full" [disabled]="!canActivateButton()"
                    (click)="autorizeChat()"></button>
            </div>
        </p-sidebar>
        <p-sidebar [(visible)]="screenChat" (onHide)="onClose()" (onShow)="moveOverlayToComponent()"
            class="ss-g-custom-sidebar-width ss-l-pSidebarChat ss-l-sideBarBottom z-1 p-0" position="bottom"
            [style]="{ position: 'absolute', width: isMobile ? '100%' : '50%', height: '65%', left: isMobile ? '0' : '50%', transform: isMobile ? 'none' : 'translateX(-50%)' }">
            <ng-template pTemplate="header">
                <div style="display: flex; justify-content: space-between; align-items: center;">
                    <span class="text-xl font-bold">Comentários</span>
                </div>
            </ng-template>
            <div *ngIf="screenChat" class="ss-l-div-chat">
                <!-- <app-chat-app #chatBoxComponent [admin]="false" [showSidebar]="sidebarChat" [chatId]="chatId"
                    [showTopChat]="false"></app-chat-app> -->
            </div>
        </p-sidebar>

        <!-- <div #lottiePlayerRef class="ss-l-lottiePlayer flex flex-column" *ngIf="showLottiePlayer">
            <lottie-player [src]="srcLottie" autoplay loop></lottie-player>
        </div> -->
        <div class="ss-g-muteImg flex flex-column" *ngIf="videoMuteService.getMuteState() && shouMuteImg && showMute"
            (click)="toggleMute(); $event.stopPropagation()">
            <i class="bi bi-volume-mute-fill text-white"></i>
            <div class="font-bold pl-4 pr-4 text-center text-white">Toque na tela para tirar o mute </div>
        </div>

        <div class="custom-navigation hidden md:flex" *ngIf="InicialSlicks.length">
            <button type="button" pbutton pripple class="p-element p-ripple p-button-icon-only p-button p-component"
                aria-label="Move Up" pTooltip="Anterior" tooltipPosition="left"
                (click)="customSwiperInstance.slidePrev()">
                <i class="pi pi-arrow-up"></i>
            </button>
            <button type="button" pbutton pripple class="p-element p-ripple p-button-icon-only p-button p-component"
                aria-label="Move Down" pTooltip="Próximo" tooltipPosition="left"
                (click)="customSwiperInstance.slideNext()">
                <i class="pi pi-arrow-down"></i>
            </button>
        </div>
    </div>

</div>

<p-dialog [baseZIndex]="1200" header="Confirmação" [(visible)]="displayDialog" [modal]="true" [closable]="false"
    [resizable]="false">
    <p style="word-wrap: break-word; overflow-wrap: break-word; white-space: normal;">Você será redirecionado para uma
        página externa - {{ tempUrl }}. Deseja continuar?</p>
    <p-footer>
        <button type="button" pButton pRipple label="Sim"
            (click)="confirmRedirect(); $event.stopPropagation()"></button>
        <button type="button" pButton pRipple label="Não" (click)="cancelRedirect(); $event.stopPropagation()"></button>
    </p-footer>
</p-dialog>
<p-dialog [baseZIndex]="1200" header="Confirmação" [(visible)]="displayDialogWhats" [modal]="true" [closable]="false"
    [resizable]="false">
    <p style="word-wrap: break-word; overflow-wrap: break-word; white-space: normal;">Você será redirecionado para uma
        página externa - {{ tempUrl }}. Deseja continuar?</p>
    <p-footer>
        <button type="button" pButton pRipple label="Sim"
            (click)="confirmRedirectWhats(); $event.stopPropagation()"></button>
        <button type="button" pButton pRipple label="Não" (click)="cancelRedirect(); $event.stopPropagation()"></button>
    </p-footer>
</p-dialog>
<p-dialog [baseZIndex]="1200" header="Confirmação" [(visible)]="displayDialogCustom" [modal]="true" [closable]="false"
    [resizable]="false">
    <p style="word-wrap: break-word; overflow-wrap: break-word; white-space: normal;">Você será redirecionado para uma
        página externa - {{ tempUrl }}. Deseja continuar?</p>
    <p-footer>
        <button type="button" pButton pRipple label="Sim"
            (click)="confirmRedirectCustom(); $event.stopPropagation()"></button>
        <button type="button" pButton pRipple label="Não" (click)="cancelRedirect(); $event.stopPropagation()"></button>
    </p-footer>
</p-dialog>


<p-toast position="top-center" key="confirm" (onClose)="onClose()" [baseZIndex]="5000" [closable]="false">
    <ng-template let-message pTemplate="headless" let-closeFn="closeFn">
        <section class="flex p-3 gap-3 w-full bg-black-alpha-90 shadow-2" style="border-radius: 10px">
            <i class="pi pi-cloud-upload text-primary-500 text-2xl"></i>
            <div class="flex flex-column gap-3 w-full">
                <p class="m-0 font-semibold text-base text-white">
                    Carregando novos vídeos...
                </p>
                <p-progressBar [value]="progress" [showValue]="false" [style]="{ height: '4px' }" />
            </div>
        </section>
    </ng-template>
</p-toast>