import { Component, Input, OnChanges, ChangeDetectionStrategy, SimpleChanges, ElementRef, ViewChild, Renderer2, HostListener, OnInit, Output, EventEmitter, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewportService } from 'src/app/services/viewport.service';
import { SwiperModule, SwiperComponent } from 'swiper/angular';
import { VideoModalComponent } from '../video-modal/video-modal.component';
import { ButtonModule } from 'primeng/button';
import { Item } from 'src/app/models/liveshop';
interface VideoItem {
  uid: string;
  id: number;
  src: string;   // URL do vídeo
  poster: string; // Poster do vídeo
  thumbnail?: string;
  title?: string;
  isLoading?: boolean;
}
@Component({
  selector: 'app-stories',
  standalone: true,
  templateUrl: './stories.component.html',
  styleUrls: ['./stories.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, SwiperModule, VideoModalComponent, ButtonModule]
})
export class StoriesComponent  implements OnInit, OnChanges, AfterViewInit {
  @Input() arraySlicksFull: Item[] = [];
  @Input() videoUrls: VideoItem[] = [];
  @Input() posterUrls: string[] = [];
  @Input() height: number | undefined;
  @Input() templateType: string | undefined;
  @Input() loadingComplete!: EventEmitter<void>;
  @Input() containerDiv?: ElementRef<HTMLDivElement>;
  @Input() pageToVideos: number = 0;
  @Input() widget_id?: number | undefined;
  @Input() totalAssets: number = 0;
  @Input() store_id?: number | undefined = 163;
  @Input() template_id?: number | undefined;
  @Input() aspectRatio?: any;
  @Output() loadMoreVideos = new EventEmitter<void>();
  @Output() openSidebar = new EventEmitter<void>();
  @ViewChild(SwiperComponent) swiperComponent!: SwiperComponent;

  @Output() modalOpened = new EventEmitter<VideoItem>();

  isModalOpen = false;
  selectedVideoUrls: VideoItem[] = [];
  slidesPerView: number = 1;
  containerClass: string = 'col-12';

  swiperKey: number = 0;  // Chave para forçar a recriação do Swiper

  constructor(private cdr: ChangeDetectorRef, private viewportService: ViewportService, private renderer: Renderer2) { }

  ngOnInit(): void {
    this.updateSlidesPerView(window.innerWidth);

    // Assinando o evento `loadingComplete` para remover o item temporário assim que o carregamento é concluído
    if (this.loadingComplete) {
      this.loadingComplete.subscribe(() => {
        this.removeLoadingIndicator();
      });
    }

    if (this.containerDiv) {
      this.updateSlidesPerView(this.containerDiv.nativeElement.offsetWidth);
    }
  }
  itemClass = 'col-12-item';
  itemWidth: string = '200px';
  ngAfterViewInit(): void {
    if (this.containerDiv && this.containerDiv.nativeElement) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          const newWidth = entry.contentRect.width;
          this.itemClass = newWidth < 1024 ? 'col-4-item' : 'col-12-item';
          this.itemWidth = newWidth < 1024 ? `${newWidth}px` : '200px';
          this.updateSlidesPerView(newWidth); // Certifique-se de que este método é chamado aqui
          this.cdr.detectChanges();
        }
      });
      resizeObserver.observe(this.containerDiv.nativeElement);
    }

    this.viewportService.setTemplateType(this.templateType);
  }

  openModal(video: VideoItem) {
    console.log('cliclou em openModal: ', video);
    this.selectedVideoUrls = [video];
    this.isModalOpen = true;
    this.cdr.detectChanges();
    this.modalOpened.emit(video);
  }

  // trackByFn(index: number, item: string): any {
  //   return index; // ou item se os valores forem únicos
  // }
  trackByFn(index: number, item: VideoItem): any {
    return item.uid; // Podemos usar `item.uid` ou qualquer propriedade única para identificar o item
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['aspectRatio']) {
      console.log('aspectRatio foi atualizada carousel:', changes['aspectRatio'].currentValue);
    }
    if (changes['store_id']) {
      console.log('store_id foi atualizada carousel:', changes['store_id'].currentValue);
    }
    if (changes['template_id']) {
      console.log('template_id foi atualizada carousel:', changes['template_id'].currentValue);
    }
    if (changes['totalAssets']) {
      console.log('totalAssets foi atualizada carousel:', changes['totalAssets'].currentValue);
    }
    if (changes['widget_id']) {
      console.log('widget_id foi atualizada carousel:', changes['widget_id'].currentValue);
    }
    if (changes['arraySlicksFull']) {
      console.log('arraySlicksFull foi atualizada componente carousel:', changes['arraySlicksFull'].currentValue);
    }
    if (changes['videoUrls']) {
      // Verifica se o `videoUrls` foi atualizado com novos vídeos
      this.removeLoadingIndicator(); // Remove o spinner de carregamento se houver vídeos novos
      console.log('videoUrls atualizado com novos vídeos carousel:', this.videoUrls);
    }
    if (changes['loadingComplete'] && this.loadingComplete) {
      console.log('loadingComplete atualizado com novos vídeos carousel:', this.loadingComplete);
      // Assina o evento `loadingComplete` para remover o indicador de carregamento
      this.removeLoadingIndicator();
    }
    if (changes['pageToVideos']) {
      console.log('pageToVideos foi atualizada pageToVideos carousel:', changes['pageToVideos'].currentValue);
    }
  }

  // Função para emitir o evento quando o botão for clicado
  onOpenSidebar(): void {
    this.openSidebar.emit();
  }
  spaceBetween: number = 20;
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    if (this.containerDiv && this.containerDiv.nativeElement) {
      const newWidth = this.containerDiv.nativeElement.offsetWidth; // Usa a largura do containerDiv
      this.updateSlidesPerView(newWidth); // Passa a largura do containerDiv
    }
  }
  updateSlidesPerView(containerWidth: number): void {
    this.spaceBetween = 10;
    this.cdr.detectChanges(); // Atualiza o layout

    // Aguarda o Swiper atualizar o layout antes de definir a largura do slide
    const swiperRef = this.swiperComponent?.swiperRef;

    // Verifique se o swiperRef e slides existem e possuem elementos
    if (swiperRef?.slides && swiperRef.slides.length > 0) {
      const firstSlide = swiperRef.slides[0] as HTMLElement;
      const slideWidth = firstSlide.offsetWidth;

      // Define a largura do slide na variável CSS
      const swiperElement = swiperRef.el;
      if (swiperElement) {
        swiperElement.style.setProperty('--slide-width', `${slideWidth}px`);
      }
    }
    this.cdr.detectChanges();
  }







  // Método para verificar se estamos no penúltimo slide e carregar mais vídeos
  onSwiperActiveIndexChange(): void {
    const slides = document.querySelectorAll('.swiper-slide');
    const activeIndex = this.swiperComponent?.swiperRef?.activeIndex ?? -1;

    // Calcule o número atual de slides visíveis
    const swiperWidth = this.swiperComponent?.swiperRef?.width ?? 1; // largura da área de visualização do Swiper
    const slideWidth = slides[0]?.clientWidth ?? swiperWidth; // largura de um slide (ou largura da área se não houver slides)

    const currentSlidesPerView = Math.floor(swiperWidth / slideWidth); // número estimado de slides visíveis

    // Ajustar o cálculo para disparar o evento de carregar mais vídeos, considerando slides visíveis atuais
    if (activeIndex >= slides.length - currentSlidesPerView - 1 && this.totalAssets > slides.length) {
      this.addLoadingIndicator();
      this.loadMoreVideos.emit();
      this.isModalOpen = false;
    }
  }

  isLoading: boolean = false;
  // Método para adicionar o item temporário com o spinner
  addLoadingIndicator(): void {
    if (!this.isLoading) {
      this.isLoading = true;
      this.videoUrls = [...this.videoUrls, { uid: 'loading', id: -1, src: '', poster: '', isLoading: true }];
      this.cdr.detectChanges();
    }
  }
  // Método para remover o item temporário com o spinner
  removeLoadingIndicator(): void {
    this.isLoading = false;
    this.isModalOpen = false;
    this.videoUrls = this.videoUrls.filter(video => !video.isLoading);
    this.cdr.detectChanges();
  }
  addNewVideos(newVideos: VideoItem[]): void {
    console.log('chamou add addNewVideos')
    if (newVideos.length > 0) {
      // Adiciona os novos vídeos ao array atual
      this.videoUrls = [...this.videoUrls, ...newVideos];
      this.resetModalState();
      // Forçar a detecção de mudanças para que o Swiper reconheça o novo conteúdo
      this.cdr.detectChanges();

      // Atualizar a chave do swiper para forçar a recriação do componente (se necessário)
      this.swiperKey++;
    }
  }
  resetModalState() {
    console.log('resetou estado de modal')
    this.selectedVideoUrls = [];
    this.isModalOpen = false;
    this.cdr.detectChanges();
  }
  isDragging = false;
  // Método que dispara ao iniciar o toque
  onTouchStart() {
    this.isDragging = false;
  }

  // Método que dispara ao mover o toque (arrastar)
  onTouchMove() {
    this.isDragging = true; // Define como arrastando
  }

  // Método que dispara ao soltar o toque
  onTouchEnd(video: VideoItem) {
    if (!this.isDragging) {

      this.openModal(video); // Dispara o modal apenas se não houve arraste
      this.cdr.detectChanges();
    }
    // Reseta o estado de arraste
    this.isDragging = false;
  }
}

